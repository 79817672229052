import { Box, Typography, TypographyProps, useTheme } from '@mui/material';
import Text from 'src/components/Text';
import { EmojiAvatar } from '../EmojiAvatar';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

export interface BuyerParticipationProps
  extends Omit<TypographyProps, 'color'> {
  participates: boolean;
}
export const BuyerParticipation = ({
  participates,
  ...typographyProps
}: BuyerParticipationProps) => {
  const theme = useTheme();
  return (
    <Box display={'flex'}>
      <EmojiAvatar
        variant="square"
        color={
          participates ? theme.colors.success.main : theme.colors.error.main
        }
      >
        {participates ? <CheckTwoToneIcon /> : <CancelTwoToneIcon />}
      </EmojiAvatar>
      <Typography {...typographyProps}>
        <Text color={participates ? 'success' : 'error'}>
          {participates ? 'Will participate' : 'Will NOT participate'}
        </Text>
      </Typography>
    </Box>
  );
};

export default BuyerParticipation;
