import { Middleware } from 'redux';
import {
  connected,
  disconnected,
  markInitialSequenceExecuted
} from 'src/features/loginSlice';
import { reset as resetUser } from 'src/features/userSlice';
import { reset as resetAverageStats } from 'src/features/averageStatsSlice';
import { reset as resetReviews } from 'src/features/reviewsSlice';
import { reset as resetOffers } from 'src/features/offerSlice';
import { reset as resetUpdateResponse } from 'src/features/updateResponseSlice';

const loginMiddleware: Middleware = (storeAPI) => {
  return (next) => (action) => {
    switch (action.type) {
      case 'login/connect':
        storeAPI.dispatch(connected());
        break;
      case 'login/disconnect':
        fetch(`${process.env.REACT_APP_API_URL}/logout`, {
          credentials: 'include'
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            storeAPI.dispatch(disconnected());
            storeAPI.dispatch(resetUser());
            storeAPI.dispatch(resetAverageStats());
            storeAPI.dispatch(resetReviews());
            storeAPI.dispatch(resetOffers());
            storeAPI.dispatch(resetUpdateResponse());
            storeAPI.dispatch({ type: 'socket/disconnect' });
          })
          .then(() => {
            document.cookie =
              'session' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          })
          .catch((error) => {
            console.error('Failed to fetch logout', error);
            storeAPI.dispatch({ type: 'socket/disconnect' });
          });
        break;
      case 'login/check':
        fetch(`${process.env.REACT_APP_API_URL}/check-authentication`, {
          credentials: 'include'
        })
          .then((response) => {
            if (!response.ok && response.status !== 401) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            if (response.status === 401) {
              storeAPI.dispatch({ type: 'login/disconnect' });
              return;
            }
            return response.json();
          })
          .then((data) => {
            if (data && data.status === 'Successful') {
              storeAPI.dispatch(connected());
              storeAPI.dispatch({ type: 'socket/connect', token: data.token });
            } else {
              storeAPI.dispatch({ type: 'login/disconnect' });
            }
          })
          .catch((error) => {
            console.error('Failed to fetch login', error);
          });
        break;
      case 'login/firstSequenceExecuted':
        storeAPI.dispatch(markInitialSequenceExecuted());
        break;
      default:
        break;
    }

    return next(action);
  };
};

export default loginMiddleware;
