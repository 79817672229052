import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Tooltip,
  Typography,
  TypographyProps,
  useTheme
} from '@mui/material';
import { DiscordDetails, getBadgeColor, getUser } from 'src/models/user';
import React, { useState } from 'react';
import Creditibility from '../Credibility';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { ProfilePopUp } from '../../Profile';
import { Rank } from 'src/models/ranks';
import { getUserMainMedal, getUserMedals } from 'src/models/medals';
import Text from 'src/components/Text';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';

export interface PosterAvatarProps extends TypographyProps {
  CreditibilityIndex: number;
  PosterID: string;
  posterDiscordDetails?: DiscordDetails;
  PosterReviewString: string;
  rank?: Rank;
  medals?: string[];
  mainMedal?: string;
}
export const PosterAvatar = ({
  CreditibilityIndex,
  PosterID,
  posterDiscordDetails,
  PosterReviewString,
  rank,
  medals,
  mainMedal,
  ...typographyProps
}: PosterAvatarProps) => {
  const theme = useTheme();
  const [openProfile, setOpenProfile] = useState<DiscordDetails>(undefined);
  const user = getUser();
  const discordDetails =
    posterDiscordDetails ||
    Object.entries(user?.CachedDiscordDetails || {}).find(
      ([key, value]) => key === PosterID
    )?.[1];
  const posterOnlineStatus = useSelector(
    (state: RootState) => state.user?.onlineStatuses?.[PosterID]
  );
  const badgeColor = getBadgeColor(posterOnlineStatus);
  const isSm = theme.breakpoints.down('sm');
  const medal = getUserMainMedal(medals, mainMedal);
  const userMedals = getUserMedals(medals);
  return (
    <React.Fragment>
      <Tooltip
        title={
          <Box>
            <Typography variant="h3">
              {posterDiscordDetails?.Premium > 0 &&
              posterDiscordDetails?.CustomName
                ? posterDiscordDetails?.CustomName
                : discordDetails?.Username}
            </Typography>
            {user?.Notes?.[PosterID] && (
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                <Text color="info">Your Note: {user?.Notes?.[PosterID]}</Text>
              </Typography>
            )}
            {rank && (
              <Box display={'flex'} alignItems={'center'} mb={0.5}>
                <Avatar
                  src={rank.Image}
                  alt="Badge"
                  sx={{ width: 25, height: 25, mr: 0.5 }}
                />
                <Typography color={rank.Color} variant="subtitle2">
                  {rank.Name}
                </Typography>
              </Box>
            )}
            {userMedals?.map((medal) => (
              <Box
                display={'flex'}
                alignItems={'center'}
                mb={0.5}
                key={medal?.Short}
              >
                <Avatar
                  src={medal?.Image}
                  alt="Badge"
                  sx={{ width: 25, height: 25, mr: 0.5 }}
                />
                <Tooltip
                  title={medal?.Description}
                  disableInteractive
                  placement="top-start"
                >
                  <Typography variant="h5" color={medal?.Color}>
                    {medal?.Name}
                  </Typography>
                </Tooltip>
              </Box>
            ))}
            <Creditibility
              index={CreditibilityIndex}
              reviewString={PosterReviewString}
              discordDetails={posterDiscordDetails}
              {...typographyProps}
            />
          </Box>
        }
        disableInteractive
        placement="top-start"
      >
        <IconButton
          onClick={() => setOpenProfile(discordDetails)}
          sx={{ mt: -1.15, mb: isSm ? 0.5 : -1.45, mx: -1 }}
        >
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            badgeContent={''}
            color={badgeColor}
          >
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              badgeContent={
                medal ? (
                  <Avatar
                    src={medal?.Image}
                    alt="Badge"
                    sx={{ width: 35, height: 35, mr: 1 }}
                  />
                ) : null
              }
            >
              {user?.Notes?.[PosterID] && (
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  badgeContent={
                    <StickyNote2Icon
                      sx={{ width: 20, height: 20, ml: 1, mt: 0.25 }}
                      color="warning"
                    />
                  }
                />
              )}
              <Avatar
                alt={`${discordDetails?.Username} avatar`}
                src={
                  posterDiscordDetails?.Premium > 0 &&
                  posterDiscordDetails?.CustomAvatar
                    ? posterDiscordDetails?.CustomAvatar
                    : discordDetails?.AvatarURL
                }
                sx={{
                  width: theme.spacing(6),
                  height: theme.spacing(6),
                  mr: 1
                }}
              />
            </Badge>
          </Badge>
        </IconButton>
      </Tooltip>
      <ProfilePopUp
        id={PosterID}
        discordDetails={openProfile}
        onClose={() => setOpenProfile(undefined)}
      />
    </React.Fragment>
  );
};

export default PosterAvatar;
