import { Avatar, Box, Typography, TypographyProps } from '@mui/material';
import Text from 'src/components/Text';

export interface PaymentRealmProps extends Omit<TypographyProps, 'color'> {
  PaymentRealms: string[];
  PaymentFactions: ('Horde' | 'Alliance')[];
}
export const PaymentRealm = ({
  PaymentRealms,
  PaymentFactions,
  ...typographyProps
}: PaymentRealmProps) => {
  const Color =
    PaymentFactions?.length === 2
      ? 'primary'
      : PaymentFactions?.includes('Horde')
      ? 'error'
      : 'info';
  return (
    <Box display={'inline-flex'}>
      {PaymentFactions?.length < 2 &&
        (PaymentFactions?.includes('Horde') ? (
          <Avatar
            src={'/static/images/WoW/faction/Horde.png'}
            sx={{ height: 20, width: 20, mr: 0.5 }}
          />
        ) : (
          <Avatar
            src={'/static/images/WoW/faction/Alliance.png'}
            sx={{ height: 20, width: 20, mr: 0.5 }}
          />
        ))}
      <Typography {...typographyProps}>
        <Text color={Color}>{PaymentRealms.join(', ')}</Text>
      </Typography>
    </Box>
  );
};

export default PaymentRealm;
