import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { PremiumInfo } from 'src/models/premium';

const persistConfig = {
  key: 'premiumInfo',
  storage: storage
};

interface premiumInfoProps {
  tiers: PremiumInfo[];
  appeals: PremiumInfo;
  donations: PremiumInfo;
}
const initialState: premiumInfoProps = {
  tiers: [],
  appeals: {
    Name: 'Premium Appeals',
    PaymentOptions: [],
    Description:
      'Have your appeal reviewed by an admin in 24 hours\nFairness guaranteed!',
    Prices: []
  },
  donations: {
    Name: 'Donation',
    PaymentOptions: [],
    Description: 'Support WoWLFG with a donation\nNo perks, just love!',
    Prices: []
  }
};

export const premiumInfoSlice = createSlice({
  name: 'premiumInfo',
  initialState,
  reducers: {
    reset: (state) => {
      for (const key in state) {
        if (!(key in initialState)) {
          delete state[key];
        }
      }
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    updateAll: (state, action: PayloadAction<premiumInfoProps>) => {
      for (const key in action.payload) {
        if (Object.prototype.hasOwnProperty.call(action.payload, key)) {
          state[key] = action.payload[key];
        }
      }
    }
  }
});

export const { reset, updateAll } = premiumInfoSlice.actions;
const persistedReducer = persistReducer(
  persistConfig,
  premiumInfoSlice.reducer
);
export default persistedReducer;
