import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

const FullPageOverlay = () => (
  <Box
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(128, 128, 128, 0.5)', // Grey with some transparency
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000 // Ensure this is higher than other elements
    }}
  >
    <Typography
      variant="h4"
      component="div"
      style={{
        color: '#FFF', // White text
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Slightly darker background for the text
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' // Adding a subtle shadow
      }}
    >
      Drop to upload the picture
    </Typography>
  </Box>
);

const PictureDragDropOverlay = ({ onFilesDropped }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setShowOverlay(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!e.relatedTarget || e.relatedTarget.nodeName === 'HTML') {
        setShowOverlay(false);
      }
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const files = Array.from(e.dataTransfer.files).filter((file: File) =>
        file.type.startsWith('image/')
      );
      onFilesDropped(files);
      setShowOverlay(false);
    };

    const handlePaste = async (e) => {
      const clipboardItems = e.clipboardData.items;
      let files = [];
      for (const item of clipboardItems) {
        if (item.type.startsWith('image/')) {
          const file = item.getAsFile();
          files.push(file);
        }
      }
      onFilesDropped(files);
    };

    window.addEventListener('paste', handlePaste);
    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('paste', handlePaste);
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
    };
  }, [onFilesDropped]);

  return showOverlay ? <FullPageOverlay /> : null;
};

export default PictureDragDropOverlay;
