import { useContext } from 'react';
import { Box, Stack, Tooltip, IconButton, styled } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import HeaderUserbox from './Userbox';
import HeaderMenu from './Menu';
import SearchBar from './UserSearch';
import { FriendlistContext } from 'src/contexts/FriendlistContext';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import HeaderWrapper from 'src/layouts/Styles/Header/Header';
import RegionSelect from './RegionSelect';

const Wrapper = styled(HeaderWrapper)(
  ({ theme }) => `
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            right: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { friendlistToggle, toggleFriendlist } = useContext(FriendlistContext);

  return (
    <Wrapper position="fixed">
      <Box display="flex" alignItems="center" justifyContent={'space-between'}>
        <Stack direction="row" alignItems="center">
          <HeaderMenu />
          <SearchBar />
        </Stack>
        <Box display="flex" alignItems="center">
          <RegionSelect />
          <HeaderUserbox />
          <Box
            component="span"
            sx={{
              ml: 2,
              display: { lg: 'none', xs: 'inline-block' }
            }}
          >
            <Tooltip arrow title="Toggle Socials">
              <IconButton color="primary" onClick={toggleFriendlist}>
                {!friendlistToggle ? (
                  <PeopleIcon fontSize="small" />
                ) : (
                  <PeopleOutlinedIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            component="span"
            sx={{
              ml: 2,
              display: { lg: 'none', xs: 'inline-block' }
            }}
          >
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={toggleSidebar}>
                {!sidebarToggle ? (
                  <MenuTwoToneIcon fontSize="small" />
                ) : (
                  <CloseTwoToneIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
}

export default Header;
