// src/middleware/socketMiddleware.ts
import { Middleware } from 'redux';
import {
  acknowledgeMessage,
  connected,
  disconnected,
  recieveMessage,
  joinRoom,
  leaveRoom
} from 'src/features/socketSlice';
import {
  update as updateUser,
  mongodbUpdate as mongodbUpdateUser,
  newMessage,
  AddReader as AddMessageUser,
  updateOnlineStatuses
} from 'src/features/userSlice';
import {
  reset as resetAverageStats,
  update as updateAverageStats
} from 'src/features/averageStatsSlice';
import {
  update as announcementsUpdate,
  mongodbUpdate as announcementsmongodbUpdate
} from 'src/features/announcementsSlice';
import {
  updateTimeOffset,
  update as clientUpdate,
  mongodbUpdate as clientmongodbUpdate
} from 'src/features/clientSlice';
import { updateAll as updatePremiumInfo } from 'src/features/premiumInfoSlice';
import { update as updateLeaderboards } from 'src/features/leaderboardsSlice';
import {
  update as updateReviews,
  addReview,
  removeReview
} from 'src/features/reviewsSlice';
import {
  update as updateAppeals,
  addAppeal,
  removeAppeal
} from 'src/features/appealsSlice';
import {
  update as updateStatistics,
  mongodbUpdate as mongodbUpdateStatistics,
  reset as resetStatistics
} from 'src/features/statisticsSlice';
import {
  reset as resetActiveOffers,
  updateAllActiveOffers,
  updateSingleActiveOffer,
  updateAllPostedOffers,
  updateSinglePostedOffer,
  removeSingleSignup,
  setInvite,
  removeinvite,
  remove as removeOneActiveOffer,
  setZoomedOffer,
  updateSingleSignup,
  signupFilters,
  offers as offersState
} from 'src/features/offerSlice';
import io from 'socket.io-client';
import {
  OfferNotificationSettings,
  OfferObj,
  checkRequirments,
  isUserEligible
} from 'src/models/offer';
import { sendNotification } from 'src/components/Utility/Notifications';
import { User } from 'src/models/user';
import { PostedOffer, SignUpNotificationSettings } from 'src/models/signup';
import { notificationsProps } from 'src/features/notificationsSlice';

let socket = null;
let isReconnecting = false;
let lastSendMessageTimes = {}; // Object to track the last send times for different action types

const RATE_LIMIT_INTERVAL = 5000; // 5 seconds in milliseconds
const USER_RATE_LIMIT_INTERVAL = 1000; // 1 second in milliseconds
const LOW_RATE_LIMIT = 100; // 0.1 seconds in milliseconds
let messageQueue = [];
let roomJoinQueue = [];
let roomLeaveQueue = [];

const socketMiddleware: Middleware = (storeAPI) => {
  const connectSocketIO = (token) => {
    // Check if a socket connection is already established or if a connection attempt is in progress.
    if ((socket && socket.connected) || isReconnecting) {
      console.log('Socket already connected or reconnecting');
      return;
    }
    // Set the connection flag to prevent further connection attempts.
    isReconnecting = true;
    socket = process.env.REACT_APP_API_URL.includes('https')
      ? io(`${process.env.REACT_APP_URL}`, {
          path: '/api/socket.io',
          secure: true,
          query: { token: token },
          reconnection: true,
          reconnectionDelay: 2000, // Initial delay before reconnecting (2 seconds)
          reconnectionDelayMax: 30000, // Maximum delay between reconnection attempts (30 seconds)
          randomizationFactor: 0.5, // Add some randomness to the reconnection delay
          transports: ['websocket']
        })
      : io(`${process.env.REACT_APP_API_URL}`, {
          query: { token: token },
          reconnection: true,
          reconnectionDelay: 2000, // Initial delay before reconnecting (2 seconds)
          reconnectionDelayMax: 30000, // Maximum delay between reconnection attempts (30 seconds)
          randomizationFactor: 0.5, // Add some randomness to the reconnection delay
          transports: ['websocket']
        });

    console.log('Connecting to the server');

    socket.on('connect', () => {
      console.log('Connected to the server');
      storeAPI.dispatch(connected());
      for (const room of storeAPI.getState().socket.rooms) {
        storeAPI.dispatch(joinRoom(room));
        socket.emit('enter_room', room);
      }
      while (roomJoinQueue.length > 0) {
        const room = roomJoinQueue.shift(); // Take the first message from the queue
        storeAPI.dispatch(joinRoom(room));
        socket.emit('enter_room', room);
      }
      while (roomLeaveQueue.length > 0) {
        const room = roomLeaveQueue.shift(); // Take the first message from the queue
        storeAPI.dispatch(leaveRoom(room));
        socket.emit('leave_room', room);
      }
      while (messageQueue.length > 0) {
        const message = messageQueue.shift(); // Take the first message from the queue
        socket.emit('message', message);
      }
      isReconnecting = false;
    });

    socket.on('connect_error', (error) => {
      console.error('Connection error:', error);
      storeAPI.dispatch(disconnected());
      isReconnecting = false;
    });

    socket.on('error', (error) => {
      console.error('Socket error:', error);
      storeAPI.dispatch(disconnected());
      isReconnecting = false;
    });

    socket.on('disconnect', (reason) => {
      console.error('Socket disconnected:', reason);
      storeAPI.dispatch(disconnected());
    });

    window.addEventListener('unload', () => {
      if (socket && socket.connected) {
        storeAPI.dispatch(disconnected());
        socket.disconnect();
      }
    });

    socket.io.on('reconnect', () => {
      console.log('Reconnected to the server');
      if (!storeAPI.getState().socket.connected) {
        storeAPI.dispatch(connected());
      }
      isReconnecting = false;
      for (const room of storeAPI.getState().socket.rooms) {
        storeAPI.dispatch(joinRoom(room));
        socket.emit('enter_room', room);
      }
      while (roomJoinQueue.length > 0) {
        const room = roomJoinQueue.shift(); // Take the first message from the queue
        storeAPI.dispatch(joinRoom(room));
        socket.emit('enter_room', room);
      }
      while (roomLeaveQueue.length > 0) {
        const room = roomLeaveQueue.shift(); // Take the first message from the queue
        storeAPI.dispatch(leaveRoom(room));
        socket.emit('leave_room', room);
      }
      while (messageQueue.length > 0) {
        const message = messageQueue.shift(); // Take the first message from the queue
        socket.emit('message', message);
      }
    });

    socket.io.on('reconnect_failed', () => {
      console.error('Reconnection failed');
      isReconnecting = false;
      storeAPI.dispatch(disconnected()); // Ensure state reflects disconnection
    });

    socket.on('enter_room', (data) => {
      storeAPI.dispatch(joinRoom(data));
      socket.emit('enter_room', data);
    });
    socket.on('leave_room', (data) => {
      storeAPI.dispatch(leaveRoom(data));
      socket.emit('leave_room', data);
    });
    socket.on('message', (data) => {
      const user: User = storeAPI.getState().user.user;
      const notifications: notificationsProps =
        storeAPI.getState().notifications;
      const timeOffset: number = storeAPI.getState().client.timeOffset;
      if (user?._id === undefined || data.exclude?.includes(user?._id)) {
        return;
      }
      switch (data.type) {
        case 'redirect':
          try {
            const trustedDomains = [
              process.env.REACT_APP_URL,
              'checkout.stripe.com',
              'billing.stripe.com',
              'discord.com',
              'commerce.coinbase.com'
            ];
            const url = new URL(data.payload.url);
            if (url === undefined) throw new Error('Invalid URL');
            if (url.protocol !== 'https:') throw new Error('Invalid Protocol');
            if (!trustedDomains.includes(url.hostname))
              throw new Error('Invalid Domain');
            switch (data.action) {
              case 'newWindow':
                const newWindow = window.open(url, '_blank');
                if (newWindow) newWindow.focus();
                break;
              case 'sameWindow':
                window.location.href = url.href;
                break;
              default:
                break;
            }
          } catch (e) {
            console.error(e);
          }
          break;
        case 'user':
          switch (data.action) {
            case 'update':
              storeAPI.dispatch(updateUser(data.payload));
              break;
            case 'mongodbUpdate':
              storeAPI.dispatch(mongodbUpdateUser(data.payload));
              break;
            case 'newMessage':
              storeAPI.dispatch(newMessage(data.payload));
              if (
                notifications?.messages?.[data.payload?.type] &&
                (notifications.messages.MutedIDs === undefined ||
                  !notifications.messages.MutedIDs.includes(data.payload.id)) &&
                data.payload?.content?.Sender &&
                data.payload?.content?.Sender !== user._id &&
                data.payload.content?.Content
              ) {
                const sender =
                  user.CachedDiscordDetails?.[data.payload?.content?.Sender];
                sendNotification(
                  sender?.Username || 'New Message',
                  {
                    body: data.payload?.content.Content || ''
                  },
                  notifications?.messages?.sound
                    ? '/static/sound/messages/new_message.wav'
                    : undefined,
                  notifications?.messages?.soundVolume,
                  notifications?.messages?.windowsNotif,
                  sender?.AvatarURL
                );
              }
              break;
            case 'AddReader':
              storeAPI.dispatch(AddMessageUser(data.payload));
              break;
            case 'updateOnlineStatuses':
              storeAPI.dispatch(updateOnlineStatuses(data.payload));
              break;
            default:
              break;
          }
          break;
        case 'averageStats':
          switch (data.action) {
            case 'update':
              storeAPI.dispatch(updateAverageStats(data.payload));
              break;
            default:
              break;
          }
          break;
        case 'announcements':
          switch (data.action) {
            case 'update':
              storeAPI.dispatch(announcementsUpdate(data.payload));
              break;
            case 'mongodbUpdate':
              storeAPI.dispatch(announcementsmongodbUpdate(data.payload));
              break;
            default:
              break;
          }
          break;
        case 'client':
          switch (data.action) {
            case 'upateTime':
              storeAPI.dispatch(updateTimeOffset(data.payload));
              break;
            case 'update':
              storeAPI.dispatch(clientUpdate(data.payload));
              break;
            case 'mongodbUpdate':
              storeAPI.dispatch(clientmongodbUpdate(data.payload));
              break;
            default:
              break;
          }
          break;
        case 'leaderboards':
          switch (data.action) {
            case 'update':
              storeAPI.dispatch(
                updateLeaderboards({
                  data: data.payload,
                  timeOffset: timeOffset
                })
              );
              break;
            default:
              break;
          }
          break;
        case 'premiumInfo':
          switch (data.action) {
            case 'updateAll':
              storeAPI.dispatch(updatePremiumInfo(data.payload));
              break;
            default:
              break;
          }
          break;
        case 'statistics':
          switch (data.action) {
            case 'update':
              storeAPI.dispatch(updateStatistics(data.payload));
              break;
            case 'mongodbUpdate':
              storeAPI.dispatch(mongodbUpdateStatistics(data.payload));
              break;
            case 'reset':
              storeAPI.dispatch(resetStatistics());
              break;
            default:
              break;
          }
          break;
        case 'offers':
          const offers: offersState = storeAPI.getState().offer;
          switch (data.action) {
            case 'posted':
              break;
            case 'updateAllActiveOffers':
              storeAPI.dispatch(updateAllActiveOffers(data.payload));
              break;
            case 'updateOneActiveOffer':
              storeAPI.dispatch(updateSingleActiveOffer(data.payload));
              const {
                newOffer,
                sound,
                soundVolume,
                windowsNotif,
                filteredOnly,
                eligibleOnly,
                friendOnly
              } = notifications?.offers;
              if (
                !Object.keys(offers?.activeOffers).includes(data.payload._id) &&
                newOffer &&
                user
              ) {
                const offerObj = new OfferObj(data.payload);
                if (
                  (user.Premium?.Tier > 0 && !filteredOnly) ||
                  (checkRequirments(offerObj?.offer, offers?.filters) &&
                    (!eligibleOnly || isUserEligible(user, offerObj?.offer)) &&
                    (!friendOnly ||
                      user?.Friends?.includes(offerObj?.offer?.PosterID)))
                ) {
                  sendNotification(
                    'New Offer',
                    {
                      body: `${offerObj.Details()}`
                    },
                    sound ? '/static/sound/posts/new_post.wav' : undefined,
                    soundVolume,
                    windowsNotif
                  );
                }
              }
              break;
            case 'updateAllPostedOffers':
              storeAPI.dispatch(updateAllPostedOffers(data.payload));
              break;
            case 'updateOnePostedOffer':
              storeAPI.dispatch(updateSinglePostedOffer(data.payload));
              break;
            case 'updateSingleSignup':
              storeAPI.dispatch(updateSingleSignup(data.payload));
              const { _id, signup, type } = data.payload;
              if (type === 'Boosters') break;
              const targetOffer = offers?.postedOffers?.[_id];
              if (!targetOffer) break;
              const targetList = targetOffer[type];
              if (!targetList) break;
              const index = targetList.findIndex((s) => s._id === signup._id);
              if (index === -1) {
                const signUpNotifications = notifications?.signUps;
                const notificationIndex = Object.keys(
                  notifications?.signUps ?? {}
                ).findIndex((key) => key === _id);
                const notificationSettings: SignUpNotificationSettings =
                  notificationIndex !== -1
                    ? signUpNotifications[_id]
                    : storeAPI.getState().notifications?.defaultSignUp;
                if (notificationSettings?.enable) {
                  const filters = offers?.posterfilters?.[_id];
                  const postedOfferHandler = new PostedOffer(
                    offers?.activeOffers,
                    [signup],
                    targetOffer?.Boosters,
                    user
                  );
                  if (
                    (user.Premium?.Tier > 0 &&
                      !notificationSettings?.filteredOnly) ||
                    !filters ||
                    true
                    // postedOfferHandler.getSignUps(
                    //   filters?.Classes ?? [],
                    //   filters?.ArmorTypes ?? [],
                    //   filters?.Roles ?? [],
                    //   filters?.Keys ?? [],
                    //   filters?.KHOnly ??
                    //     notificationSettings?.keyholderOnly ??
                    //     false,
                    //   filters?.faction,
                    //   filters?.SortFactor ?? '',
                    //   filters?.MinSortFactos ?? {},
                    //   filters?.FriendsOnly ??
                    //     notificationSettings?.friendsOnly ??
                    //     false,
                    //   filters?.Search ?? ''
                    // ).length > 0
                  ) {
                    const offerHandler = new OfferObj(data.payload);
                    sendNotification(
                      'New Sign Up',
                      {
                        body: signup?.MainIO
                          ? `${
                              signup?.MainIO
                            }IO signed up for ${offerHandler.Details()}`
                          : `New Signup for ${offerHandler.Details()}`
                      },
                      notificationSettings?.sound
                        ? '/static/sound/signups/new_signup.wav'
                        : undefined,
                      notificationSettings?.soundVolume,
                      notificationSettings?.windowsNotif
                    );
                  }
                }
              }
              break;
            case 'removeSingleSignup':
              storeAPI.dispatch(removeSingleSignup(data.payload));
              break;
            case 'deleteOneActiveOffer':
              storeAPI.dispatch(removeOneActiveOffer(data.payload));
              break;
            case 'offerInvite':
              storeAPI.dispatch(setInvite(data.payload));
              if (notifications?.invites?.invite) {
                sendNotification(
                  'You are invited',
                  {
                    body: `You are invited`
                  },
                  notifications?.invites?.sound
                    ? '/static/sound/posts/invite.mp3'
                    : undefined,
                  notifications?.invites?.soundVolume,
                  notifications?.invites?.windowsNotif
                );
              }
              break;
            case 'removeInvite':
              storeAPI.dispatch(removeinvite(data.payload));
              break;
            case 'zoomedOffer':
              storeAPI.dispatch(setZoomedOffer(data.payload));
              break;
            default:
              break;
          }
          break;
        case 'reviews':
          switch (data.action) {
            case 'update':
              storeAPI.dispatch(updateReviews(data.payload));
              break;
            case 'appeal':
              break;
            case 'add':
              storeAPI.dispatch(addReview(data.payload));
              if (
                user.Premium?.Tier > 0 &&
                notifications?.reviews?.[data.payload.Type]
              )
                sendNotification(
                  'New Review',
                  {
                    body: `${data.payload.Score}/10 for ${data.payload.Service}`
                  },
                  notifications?.reviews?.sound
                    ? '/static/sound/reviews/new_review.wav'
                    : undefined,
                  notifications?.reviews?.soundVolume,
                  notifications?.reviews?.windowsNotif
                );
              break;
            case 'remove':
              storeAPI.dispatch(removeReview(data.payload));
              break;
            default:
              break;
          }
          break;
        case 'appeals':
          switch (data.action) {
            case 'update':
              storeAPI.dispatch(updateAppeals(data.payload));
              break;
            case 'add':
              storeAPI.dispatch(addAppeal(data.payload));
              break;
            case 'remove':
              storeAPI.dispatch(removeAppeal(data.payload));
              break;
            default:
              break;
          }
          break;
        case 'popup':
          if (data.action === 'popup')
            storeAPI.dispatch(recieveMessage(data.payload));
          break;
        default:
          break;
      }
      if (data.message !== undefined) {
        storeAPI.dispatch(recieveMessage(data.message));
      }
    });
  };

  return (next) => (action) => {
    switch (action.type) {
      case 'socket/connect':
        connectSocketIO(action.token); // Use the token here
        break;
      case 'socket/disconnect':
        if (socket) {
          socket.disconnect();
        }
        break;
      case 'socket/rooms':
        const join = action.payload['join'];
        if (socket && socket.connected) {
          if (join) {
            storeAPI.dispatch(joinRoom(action.payload['room']));
            socket.emit('enter_room', action.payload['room']);
          } else if (!join) {
            storeAPI.dispatch(leaveRoom(action.payload['room']));
            socket.emit('leave_room', action.payload['room']);
          }
        } else {
          if (join) {
            roomJoinQueue.push(action.payload['room']);
          } else {
            roomLeaveQueue.push(action.payload['room']);
          }
        }
        break;
      case 'socket/Message/send':
        const currentTime = Date.now();
        const messageType = action.payload['type'];

        // Check if the action type has a last send time recorded
        if (!lastSendMessageTimes.hasOwnProperty(messageType)) {
          lastSendMessageTimes[messageType] = 0;
        }

        // Check if the action is allowed based on the rate limit interval for its type
        if (
          messageType in lastSendMessageTimes &&
          currentTime - lastSendMessageTimes[messageType] >=
            (action.payload['low_rate_limit']
              ? LOW_RATE_LIMIT
              : action.payload['silent']
              ? RATE_LIMIT_INTERVAL
              : USER_RATE_LIMIT_INTERVAL)
        ) {
          // Allow the action // Update the last dispatch time for this type
          lastSendMessageTimes[messageType] = currentTime;
          if (socket && socket.connected) {
            socket.emit('message', action.payload);
          } else {
            // Queue the message since socket isn't connected
            messageQueue.push(action.payload);
          }
        } else {
          // Rate limit the action (you can handle this as needed, e.g., log, ignore, or notify the user)
          console.warn(
            `Rate-limited: Only one send message action of type '${messageType}' is allowed every 5 seconds.`
          );
        }
        break;
      case 'socket/Message/acknowledge':
        storeAPI.dispatch(acknowledgeMessage());
        break;
      case 'socket/activity/update':
        if (socket && socket.connected) {
          socket.emit('activity');
        }
        break;
      default:
        break;
    }

    return next(action);
  };
};

export default socketMiddleware;
