import { Difficulties } from '../difficulties';
import { RaidBosses } from '../raidbosses';
import { Difficulty, SubCategory } from './fields';

export const PvPSubCategories: SubCategory[] = [
  {
    Title: '2v2',
    ParentCategory: 'PvP',
    Tags: ['Hourly', 'PerMatch', 'Rating Goal', 'CR Farm', 'Single Win']
  },
  {
    Title: '3v3',
    ParentCategory: 'PvP',
    Tags: ['Hourly', 'PerMatch', 'Rating Goal', 'CR Farm', 'Single Win']
  },
  {
    Title: 'RBG',
    ParentCategory: 'PvP',
    Tags: ['Hourly', 'PerMatch', 'Rating Goal', 'CR Farm', 'Single Win']
  },
  {
    Title: 'Coaching',
    ParentCategory: 'PvP',
    Tags: ['Hourly', 'PerMatch', 'Rating Goal', 'CR Farm', 'Single Win']
  }
];
