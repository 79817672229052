import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { averageStats } from 'src/models/averageStats';

const persistConfig = {
  key: 'averageStats',
  storage: storage
};
const initialState: {
  Data: averageStats;
  LastUpdate: number;
} = { Data: {}, LastUpdate: 0 };

export const averageStatsSlice = createSlice({
  name: 'averageStats',
  initialState,
  reducers: {
    reset: (state) => {
      for (const key in state) {
        if (!(key in initialState)) {
          delete state[key];
        }
      }
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    update: (state, action: PayloadAction<{ averageStat }>) => {
      state.Data = { ...action.payload };
      state.LastUpdate = Date.now();
    }
  }
});

export const { reset, update } = averageStatsSlice.actions;
const persistedReducer = persistReducer(
  persistConfig,
  averageStatsSlice.reducer
);
export default persistedReducer;
