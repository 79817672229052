import { useEffect, useState } from 'react';
import { Button, Box, Switch, FormControlLabel } from '@mui/material';
import { AppDispatch } from 'src/app/store';
import { useDispatch } from 'react-redux';
import { User } from 'src/models/user';
import { formatDistanceToNow } from 'date-fns';
import Label from 'src/components/Label';
import CooldownButton from 'src/components/Utility/CooldownButton';
export interface AutoSignupStatusProps {
  user: User;
  type: 'solo' | 'team';
}

const AutoSignupStatus = ({ user, type }: AutoSignupStatusProps) => {
  const isPremium = (user?.Premium?.Tier || 0) >= 2;
  const [isExpired, setIsExpired] = useState(true);
  const [expiryTime, setExpiryTime] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const team = type === 'team';
  const localStorageKey = team
    ? 'lastTeamAutoSignupUpdate'
    : 'lastAutoSignupUpdate';
  const socketMessageType = team
    ? 'team_auto_signup_status'
    : 'auto_signup_status';
  const lastAutoSignupUpdate = localStorage.getItem(localStorageKey);
  const AutoSignupStatus = team
    ? user?.CurrentGroup?.AutoSignupStatus
    : user?.AutoSignupStatus;
  useEffect(() => {
    if (
      lastAutoSignupUpdate &&
      new Date().getTime() - parseInt(lastAutoSignupUpdate) < 30 * 60 * 1000
    ) {
      return;
    }
    dispatch({
      type: 'socket/Message/send',
      payload: { type: socketMessageType, silent: true }
    });
    localStorage.setItem(localStorageKey, new Date().getTime().toString());
  }, []);

  useEffect(() => {
    const expiryTimeStamp = AutoSignupStatus?.EndTimeStamp;
    if (!expiryTimeStamp) {
      return;
    }

    const expiryDate = new Date(expiryTimeStamp * 1000 - 999);
    const currentDate = new Date();

    setIsExpired(currentDate > expiryDate);
    setExpiryTime(formatDistanceToNow(expiryDate, { addSuffix: false }));

    // Optionally, set up an interval to keep this updated
    const intervalId = setInterval(() => {
      setExpiryTime(formatDistanceToNow(expiryDate, { addSuffix: false }));
    }, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [AutoSignupStatus]);

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: socketMessageType,
        data: { Enabled: checked }
      }
    });
  };

  const onRefreshClick = () => {
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: socketMessageType,
        data: { Enabled: true }
      }
    });
  };

  const onAutoAcceptClick = () => {
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: socketMessageType,
        data: { Enabled: true, AutoAccept: !AutoSignupStatus?.AutoAccept }
      }
    });
  };

  return (
    <Box
      display="flex"
      flexDirection={team ? 'column' : 'row'}
      alignItems="center"
      ml={-2}
      mr={-2}
      flexWrap={'wrap'}
      justifyContent={'center'}
    >
      {!isExpired && (
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <CooldownButton
            cooldownSeconds={10}
            color="success"
            size="small"
            variant="outlined"
            onClick={onRefreshClick}
            sx={{ height: '29px', mb: 0.1 }}
          >
            Refresh
          </CooldownButton>
          {!team && (
            <CooldownButton
              cooldownSeconds={10}
              color={AutoSignupStatus?.AutoAccept ? 'error' : 'success'}
              size="small"
              variant="outlined"
              onClick={onAutoAcceptClick}
              sx={{ height: '29px', mt: 0.1 }}
            >
              {AutoSignupStatus?.AutoAccept ? 'Disable' : 'Enable'} Auto Accept
            </CooldownButton>
          )}
        </Box>
      )}
      <FormControlLabel
        control={
          <Switch
            name="Auto Signup"
            checked={!isExpired}
            onChange={onSwitchChange}
            disabled={!isPremium}
          />
        }
        label={
          <Label color={isExpired ? 'error' : 'success'}>
            <b>
              {isExpired
                ? `${team ? 'Team' : 'Solo'} AutoSignup is Off`
                : `${expiryTime}`}
            </b>
          </Label>
        }
        labelPlacement="bottom"
      />
    </Box>
  );
};

export default AutoSignupStatus;
