import { Difficulties } from '../difficulties';
import { OfferTypeNames } from '../offer';

export interface SubCategory {
  Title: string;
  Description?: string;
  Emoji?: string;
  ParentCategory: OfferTypeNames;
  Tags: string[];
}

export function Difficulty(
  difficulty: string,
  Parent: OfferTypeNames,
  Tags: string[] = []
): SubCategory {
  const targetDifficulty = Difficulties.find((d) => d.Name === difficulty);
  return {
    Title: `${targetDifficulty ? targetDifficulty.Name : difficulty} ${Parent}`,
    ParentCategory: Parent,
    Tags: Tags,
    Emoji: targetDifficulty ? targetDifficulty.Emoji : null
  };
}
