import { useState } from 'react';
import { getUser } from 'src/models/user';
import React from 'react';
import { ProfilePopUp } from 'src/components/WoW/Profile';
import UserSearch from 'src/components/Utility/UserSearch';

function SearchBar() {
  const CachedDiscordDetails = getUser()?.CachedDiscordDetails || {};
  const [openProfile, setOpenProfile] = useState<string>(undefined);

  const handleUserClick = (chosenID: string) => {
    setOpenProfile(chosenID);
  };

  return (
    <React.Fragment>
      <UserSearch onSelectUser={handleUserClick} />
      <ProfilePopUp
        id={openProfile}
        discordDetails={
          openProfile
            ? Object.entries(CachedDiscordDetails).find(
                ([id, user]) => id === openProfile
              )?.[1]
            : undefined
        }
        onClose={() => setOpenProfile(undefined)}
      />
    </React.Fragment>
  );
}

export default SearchBar;
