export interface Difficulty {
  Name: string;
  Emoji: string;
  Image: string;
  Short: string;
}

export const Difficulties: Difficulty[] = [
  {
    Name: 'Normal',
    Emoji: '/static/images/WoW/Difficulties/Normal.png',
    Image: '/static/images/WoW/Difficulties/Normal.png',
    Short: 'Normal'
  },
  {
    Name: 'Heroic',
    Emoji: '/static/images/WoW/Difficulties/Heroic.png',
    Image: '/static/images/WoW/Difficulties/Heroic.png',
    Short: 'Heroic'
  },
  {
    Name: 'Mythic',
    Emoji: '/static/images/WoW/Difficulties/Mythic.png',
    Image: '/static/images/WoW/Difficulties/Mythic.png',
    Short: 'Mythic'
  }
];
