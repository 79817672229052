import { Middleware } from 'redux';
import {
  update,
  updateSignup,
  updateMutelist
} from '../features/notificationsSlice';

const notificationsMiddleware: Middleware = (storeAPI) => {
  return (next) => async (action) => {
    switch (action.type) {
      case 'notifications/general':
        storeAPI.dispatch(update({ general: action.payload }));
        break;
      case 'notifications/offers':
        storeAPI.dispatch(update({ offers: action.payload }));
        break;
      case 'notifications/invites':
        storeAPI.dispatch(update({ invites: action.payload }));
        break;
      case 'notifications/signUps':
        storeAPI.dispatch(updateSignup(action.payload));
        break;
      case 'notifications/defaultSignUp':
        storeAPI.dispatch(update({ defaultSignUp: action.payload }));
        break;
      case 'notifications/reviews':
        storeAPI.dispatch(update({ reviews: action.payload }));
        break;
      case 'notifications/messages':
        storeAPI.dispatch(update({ messages: action.payload }));
        break;
      case 'notifications/mute':
        storeAPI.dispatch(updateMutelist(action.payload));
        break;
      default:
        break;
    }
    return next(action);
  };
};

export default notificationsMiddleware;
