import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';

type ReviewTypes =
  | 'Booster'
  | 'Poster'
  | 'GoldSwap'
  | 'BoostRequester'
  | 'BoostRequestProvider';

export type ReviewNotificationSettings = {
  [key in ReviewTypes]?: boolean;
} & {
  sound: boolean;
  soundVolume: number;
  windowsNotif: boolean;
};

export interface ReviewItem {
  Type?: ReviewTypes;
  ReceiverID?: string;
  ReviewerID?: string;
  Score?: number;
  Review?: string;
  Service: string;
  ServiceID?: string;
  ServiceType?: string;
  ServiceTimeStamp?: number;
  Disabled?: boolean;
}

export interface AppealVote {
  VoterID: string;
  Vote: boolean;
}

export interface AppealItem {
  Review: ReviewItem;
  AppealTimeStamp: number;
  OtherScores: number[];
  Note?: string;
  ImageURL?: string;
  Votes: AppealVote[];
  Premium: boolean;
  Status: 'Pending' | 'Rejected' | 'Accepted';
  RecieverStats: { [key: string]: any };
  _id: string;
}

export function getReview() {
  return {
    Type: 'Booster',
    ReceiverID: '410635076842422293',
    ReviewerID: '410176059712208897',
    Score: 10,
    Review: 'best healer i ever seen keep going bro',
    Service: '+11x4 US Tichondrius',
    ServiceID: '63eae20127bdcc9a34d07450',
    ServiceType: 'MythicPlus',
    ServiceTimeStamp: 1689373482.187115,
    Disabled: false
  } as ReviewItem;
}

export function getReviews() {
  const reviews = useSelector((state: RootState) => state.reviews);
  if (reviews) {
    return reviews;
  }
  return {
    Booster: {
      Reviews: [
        getReview(),
        getReview(),
        getReview(),
        getReview(),
        getReview(),
        getReview(),
        getReview()
      ]
    } as DBReviews
  };
}
export interface ReviewPaginator {
  total: number;
  reviews: ReviewItem[];
  current: number;
}

interface DBReviews {
  Reviews: ReviewItem[];
  Scores?: number[];
}

export type AllReviews = {
  [key in ReviewTypes]?: DBReviews;
};

export function ReviewsString(reviews: number[]): string {
  if (reviews.length > 1 && reviews[1])
    return `${reviews[1]} Reviews: ${reviews[0].toFixed(2)}/10`;
  return 'No Reviews';
}

export function FetchReview(
  Type: 'Booster' | 'Poster',
  ReviewsObj: ReviewPaginator,
  setReviews
): void {
  fetch(`${process.env.REACT_APP_API_URL}/review`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      start: ReviewsObj.reviews.length,
      type: Type
    })
  })
    .then((res) => res.json())
    .then((data) => {
      const UpdatedReviews = ReviewsObj.reviews.concat(data.review);
      setReviews({
        total: data.count,
        Reviews: UpdatedReviews,
        current: ReviewsObj.current + 1
      });
    });
}

export interface ReviewFilter {
  ServiceType?: string;
  maxScore?: number;
  maxDate?: number;
  minDate?: number;
}

export function FilterReviews(
  reviews: ReviewItem[],
  { ServiceType, maxScore, maxDate, minDate }: ReviewFilter
) {
  return reviews.filter(
    (review) =>
      (!ServiceType || review.ServiceType === ServiceType) &&
      (!maxScore || review.Score <= maxScore) &&
      (!maxDate || review.ServiceTimeStamp <= maxDate / 1000) &&
      (!minDate || review.ServiceTimeStamp >= minDate / 1000)
  );
}
