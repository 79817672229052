import React, { useState, useEffect, ReactNode, FC } from 'react';
import { Tooltip, Typography, TypographyProps } from '@mui/material';

interface ClickToCopyProps extends TypographyProps {
  textToCopy: string;
  children: ReactNode;
}

const ClickToCopy: FC<ClickToCopyProps> = ({
  textToCopy,
  children,
  ...typographyProps
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleTextClick = (text: string) => {
    navigator.clipboard.writeText(text);
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000);
  };

  return (
    <Tooltip title="Copied" open={showTooltip}>
      <Typography
        {...typographyProps}
        onClick={() => handleTextClick(textToCopy)}
        style={{ cursor: 'pointer' }}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};

export default ClickToCopy;
