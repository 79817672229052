import { SubCategory } from './fields';

function DelveTier(tier: number): SubCategory {
  return {
    Title: `Tier ${tier}`,
    ParentCategory: 'Leveling',
    Tags: ['Bountiful Options', 'Specific Delve Options']
  };
}
export const DelveSubCategories: SubCategory[] = [
  ...Array.from({ length: 11 }, (_, i) => DelveTier(i + 1))
];
