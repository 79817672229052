import { Update } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { ActiveOfferTypes, OfferTypeNames, SortCriteria } from './offer';

export interface MinimumOfferRequirements {
  CutPerKey?: number;
  MinimumKeyLevel?: number;
  MaximumKeyLevel?: number;
  Keys?: string[];
  PaymentRealms?: {
    US?: string[];
    EU?: string[];
  };
  MinimumIO?: number;
  AcceptArmorStack?: boolean;
  MaximumKeysQuantity?: number;
  MinimumKeysQuantity?: number;
  PosterTrustLevel?: number;
  MinPosterKarma?: number;
  KarmaGain?: number;
  TimingStatus?: boolean;
  MarketAverage?: boolean;
  NoExtremelyLowCut?: boolean;
  Unsaved?: boolean;
  BuyerParticipation?: boolean;
  OfferTypes: OfferTypeNames[];
  LFGOrLFB?: ('LFG' | 'LFB')[];
  sortFactor?: SortCriteria;
  eligibleOnly?: boolean;
  Note?: string;
}

export interface AutoSignupStatus {
  UpdateTimeStamp?: number;
  EndTimeStamp?: number;
  AutoAccept?: boolean;
  Note?: string;
}

export const defaultMinimumOfferRequirements: MinimumOfferRequirements = {
  CutPerKey: 0,
  MinimumKeyLevel: 0,
  MaximumKeyLevel: 30,
  MinimumIO: 0,
  MinimumKeysQuantity: 0,
  MaximumKeysQuantity: 10,
  PosterTrustLevel: 0,
  Keys: [],
  AcceptArmorStack: true,
  MinPosterKarma: null,
  KarmaGain: 0,
  TimingStatus: true,
  MarketAverage: false,
  NoExtremelyLowCut: false,
  Unsaved: true,
  BuyerParticipation: false,
  OfferTypes: ActiveOfferTypes,
  LFGOrLFB: ['LFB'],
  eligibleOnly: false
};

export function getMinimumOfferRequirements(): MinimumOfferRequirements {
  const user = useSelector((state: RootState) => state.user.user);
  if (user.AutoSignupSettings) {
    return user.AutoSignupSettings;
  }
  return {
    CutPerKey: 0,
    MinimumKeyLevel: 0,
    MaximumKeyLevel: 0,
    Keys: [],
    PaymentRealms: {
      US: [],
      EU: []
    },
    MinimumIO: 0,
    AcceptArmorStack: true,
    MaximumKeysQuantity: 0,
    MinimumKeysQuantity: 0,
    PosterTrustLevel: 0,
    TimingStatus: true,
    MarketAverage: false,
    Unsaved: true,
    BuyerParticipation: false,
    OfferTypes: ['MythicPlus', 'MegaDungeon'],
    LFGOrLFB: ['LFB']
  };
}
