import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'login',
  storage: storage
};
const initialState = {
  loggedIn: false,
  hasInitialSequenceExecuted: false
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    connected: (state) => {
      state.loggedIn = true;
    },
    disconnected: (state) => {
      state.loggedIn = false;
      state.hasInitialSequenceExecuted = false;
    },
    markInitialSequenceExecuted: (state) => {
      state.hasInitialSequenceExecuted = true;
    }
  }
});

export const { connected, disconnected, markInitialSequenceExecuted } =
  loginSlice.actions;
const persistedReducer = persistReducer(persistConfig, loginSlice.reducer);
export default persistedReducer;
