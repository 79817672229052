import { Box, Grid, Typography, TypographyProps } from '@mui/material';
import { EmojiAvatar } from '../EmojiAvatar';
import { Classes } from 'src/models/wowclasses';

const roleCounts = {
  Tank: 6,
  Healer: 6,
  DPS: 13
};

export interface EligibleClassesProps extends Omit<TypographyProps, 'color'> {
  eligibleClassesByRole: { [key: string]: string[] };
  totalSpots: { [key: string]: number };
}

export const EligibleClasses = ({
  eligibleClassesByRole,
  totalSpots,
  ...typographyProps
}: EligibleClassesProps) => {
  return (
    <Grid container spacing={0.5}>
      {Object.keys(eligibleClassesByRole).map((role) => {
        if (totalSpots[role] === 0) return null;
        return (
          <Grid item xs={12} key={role} display={'inline-flex'}>
            <Box display="flex" flexWrap="wrap">
              <EmojiAvatar src={`/static/images/WoW/Roles/${role}.png`} />
              <Typography {...typographyProps}>
                {role}
                {role !== 'DPS' ? 's: ' : ': '}
              </Typography>
              {eligibleClassesByRole[role].length === roleCounts[role] ? (
                <Typography {...typographyProps}>Any</Typography>
              ) : (
                eligibleClassesByRole[role].map((roleClass, index) => {
                  const charClass = Classes.find(
                    (wowclass) => wowclass.Name === roleClass
                  );
                  return <EmojiAvatar src={charClass.Emoji} key={index} />;
                })
              )}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default EligibleClasses;
