import { useState } from 'react';
import {
  TextField,
  Grid,
  CardHeader,
  Tab,
  Box,
  Tabs,
  Divider,
  Card,
  styled,
  Button,
  IconButton,
  Avatar,
  Dialog,
  Switch,
  FormControlLabel,
  InputAdornment
} from '@mui/material';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { PricelistItem } from 'src/models/pricelist';
import PicturesUpload from 'src/components/Utility/PicturesUpload';
import { FormControl } from '@mui/base';

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
  `
);

interface AdditionalInfoProps {
  Item: PricelistItem;
  SetItem: (item: PricelistItem) => void;
  setHasError: (hasError: boolean) => void;
}

const AdditionalInfo = ({
  Item,
  SetItem,
  setHasError
}: AdditionalInfoProps) => {
  const generateUniqueId = () => {
    return new Date().getTime();
  };

  const [currentTab, setCurrentTab] = useState('options');
  const [openUploadDialog, setOpenUploadDialog] = React.useState(false);

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };

  const options = Item?.Options || [];

  const setOptions = (options) => {
    SetItem({
      ...Item,
      Options: options
    });
    setHasError(
      options.some((option) => {
        return (
          !option.Title ||
          option.Choices.some(
            (choice) => !choice.Title || (choice.Price && choice.Price < 0)
          )
        );
      })
    );
  };

  const setPicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    SetItem({
      ...Item,
      Image: e.target.value
    });
  };

  const handleOptionTitleChange = (optionID, value) => {
    const updatedOptions = options.map((option) =>
      option.id === optionID ? { ...option, Title: value } : option
    );
    setOptions(updatedOptions);
  };

  const handleChoiceChange = (optionID, choiceID, field, value) => {
    const updatedOptions = options.map((option) =>
      option.id === optionID
        ? {
            ...option,
            Choices: option.Choices.map((choice) =>
              choice.id === choiceID ? { ...choice, [field]: value } : choice
            )
          }
        : option
    );
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setOptions([
      ...options,
      {
        Title: '',
        Choices: [{ Title: '', id: generateUniqueId() }],
        id: generateUniqueId()
      }
    ]);
  };

  const handleRemoveOption = (idToRemove) => {
    const updatedOptions = options.filter((option) => option.id !== idToRemove);
    setOptions([...updatedOptions]);
  };

  const handleAddChoice = (optionID) => {
    const updatedOptions = options.map((option) =>
      option.id === optionID
        ? {
            ...option,
            Choices: [
              ...(option.Choices ?? []),
              { Title: '', id: generateUniqueId() }
            ]
          }
        : option
    );
    setOptions(updatedOptions);
  };

  const handleRemoveChoice = (optionID, choiceID) => {
    const updatedOptions = options.map((option) =>
      option.id === optionID
        ? {
            ...option,
            Choices: option.Choices.filter((choice) => choice.id !== choiceID)
          }
        : option
    );
    setOptions(updatedOptions);
  };

  return (
    <Card>
      <CardHeader title={'Additional Informations'} />
      <Divider />
      <TabsContainerWrapper>
        <Tabs
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Options" value="options" />
          <Tab label="Album" value="album" />
        </Tabs>
      </TabsContainerWrapper>
      <Divider />
      <Box p={3}>
        {currentTab === 'options' && (
          <>
            {options &&
              options.map((option, optionIndex) => (
                <Box key={option.id} mb={3}>
                  <Grid container spacing={3} alignItems="center" mt={1}>
                    <Grid
                      item
                      xs={12}
                      md={0.5}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveOption(option.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={`Option ${optionIndex + 1} Title`}
                        value={option.Title}
                        onChange={(e) =>
                          handleOptionTitleChange(option.id, e.target.value)
                        }
                        required
                        error={!option.Title}
                      />
                    </Grid>
                    <Grid item xs={12} md={5.5} sx={{ display: 'flex' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={option.Required}
                            onChange={(e) =>
                              setOptions(
                                options.map((o) =>
                                  o.id === option.id
                                    ? { ...o, Required: e.target.checked }
                                    : o
                                )
                              )
                            }
                          />
                        }
                        label={option.Required ? 'Required' : 'Optional'}
                        labelPlacement="bottom"
                      />
                    </Grid>
                    {option.Choices &&
                      option.Choices.map((choice, choiceIndex) => (
                        <React.Fragment key={choice.id}>
                          <Grid
                            item
                            xs={12}
                            md={0.5}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <IconButton
                              color="error"
                              onClick={() =>
                                handleRemoveChoice(option.id, choice.id)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label={`Choice ${choiceIndex + 1} Title`}
                              value={choice.Title}
                              onChange={(e) =>
                                handleChoiceChange(
                                  option.id,
                                  choice.id,
                                  'Title',
                                  e.target.value
                                )
                              }
                              error={!choice.Title}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} md={5.5}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              type="number"
                              label={`Choice ${choiceIndex + 1} Price`}
                              value={choice.Price || 0}
                              onChange={(e) =>
                                handleChoiceChange(
                                  option.id,
                                  choice.id,
                                  'Price',
                                  e.target.value && Number(e.target.value) > 0
                                    ? e.target.value
                                    : 0
                                )
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    K
                                    <Avatar
                                      src="/static/images/WoW/Emojis/Gold.png"
                                      sx={{ width: 20, height: 20, ml: 0.5 }}
                                    />
                                  </InputAdornment>
                                ),
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {Item?.BasePrice} +{' '}
                                  </InputAdornment>
                                )
                              }}
                              error={choice.Price && choice.Price < 0}
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: { xs: 'center', md: 'left' }
                      }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => handleAddChoice(option.id)}
                      >
                        Add Choice
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={12} justifyContent="flex-end">
                      <Divider />
                    </Grid>
                  </Grid>
                </Box>
              ))}
            <Box display="flex" justifyContent="left" mt={2}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleAddOption}
              >
                Add Option
              </Button>
            </Box>
          </>
        )}
        {currentTab === 'album' && (
          <Box p={1} display="flex" flexDirection="column" alignItems="center">
            <Avatar
              sx={{
                width: 200,
                height: 200,
                mt: 2
              }}
              src={Item?.Image}
            />
            <Box mt={2} minWidth={300}>
              <TextField
                fullWidth
                variant="outlined"
                type="string"
                label={`Image URL`}
                value={Item.Image}
                onChange={setPicture}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default AdditionalInfo;
