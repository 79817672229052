import { OfferTypeNames } from '../offer';
import { DelveDetailsFields, DelveRequirementsFields } from './delve';
import { DetailsField } from './fields';
import { LevelingDetailsFields, LevelingRequirementsFields } from './leveling';
import {
  MegaDungeonDetailsFields,
  MegaDungeonRequirementsFields
} from './megadungeon';
import {
  MiscellaneousDetailsFields,
  MiscellaneousRequirementsFields
} from './miscellaneous';
import {
  MythicPlusDetailsFields,
  MythicPlusRequirementsFields
} from './mythicplus';
import { PvPDetailsFields, PvPRequirementsFields } from './pvp';
import { RaidDetailsFields, RaidRequirementsFields } from './raid';

export function FindDetailsFields(
  OfferType: OfferTypeNames
): [DetailsField[], DetailsField[]] {
  if (!OfferType) return [[], []];
  switch (OfferType) {
    case 'MythicPlus':
      return [[...MythicPlusDetailsFields], [...MythicPlusRequirementsFields]];
    case 'MegaDungeon':
      return [
        [...MegaDungeonDetailsFields],
        [...MegaDungeonRequirementsFields]
      ];
    case 'Leveling':
      return [[...LevelingDetailsFields], [...LevelingRequirementsFields]];
    case 'Raid':
      return [[...RaidDetailsFields], [...RaidRequirementsFields]];
    case 'PvP':
      return [[...PvPDetailsFields], [...PvPRequirementsFields]];
    case 'Miscellaneous':
      return [
        [...MiscellaneousDetailsFields],
        [...MiscellaneousRequirementsFields]
      ];
    case 'Delve':
      return [[...DelveDetailsFields], [...DelveRequirementsFields]];
  }
}
