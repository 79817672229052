import { Grid, Typography, TypographyProps } from '@mui/material';
import { EmojiAvatar } from '../EmojiAvatar';
import { Dungeons } from 'src/models/dungeons';

export interface KeyListProps extends Omit<TypographyProps, 'color'> {
  allkeys?: string[];
  remainingKeys?: string[];
  foundKeys?: string[];
  buyerKey?: string;
}

export const KeyList = ({
  allkeys = [],
  remainingKeys = [],
  foundKeys = [],
  buyerKey,
  ...typographyProps
}: KeyListProps) => {
  const buyerKeyObject = buyerKey
    ? Dungeons.find((dungeon) => dungeon.Name === buyerKey)
    : null;
  return allkeys?.length === 0 && foundKeys?.length === 0 ? (
    <Typography {...typographyProps}>Accepts Any Keys</Typography>
  ) : (
    <Grid container spacing={0.5}>
      {remainingKeys?.length === 0 &&
        foundKeys?.length === 0 &&
        !buyerKeyObject && <b>Accepts Any Keys</b>}
      {buyerKeyObject && (
        <Grid item xs={12} display={'inline-flex'}>
          <EmojiAvatar src={buyerKeyObject?.Emoji} />
          <Typography
            {...typographyProps}
          >{`${buyerKeyObject?.Name} (Buyer)`}</Typography>
        </Grid>
      )}
      {foundKeys?.map((keyName, index) => {
        const key = Dungeons.find((dungeon) => dungeon.Name === keyName);
        return (
          <Grid item xs={12} key={index} display={'inline-flex'}>
            <EmojiAvatar src={key?.Emoji} />
            <Typography
              {...typographyProps}
            >{`${key?.Name} (Found)`}</Typography>
          </Grid>
        );
      })}
      {remainingKeys?.map((keyName, index) => {
        const key = Dungeons.find((dungeon) => dungeon.Name === keyName);
        return (
          <Grid item xs={12} key={index} display={'inline-flex'}>
            <EmojiAvatar src={key?.Emoji} />
            <Typography {...typographyProps}>{key?.Name}</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default KeyList;
