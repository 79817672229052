import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';

export const formatDiscordTimestamp = (timestampStr: string): string => {
  const matches = timestampStr.match(/<t:(\d+):(\w)>/);
  if (!matches) return timestampStr;

  const timestamp = parseInt(matches[1], 10);
  const formatType = matches[2];
  const date = new Date(timestamp * 1000); // Convert to milliseconds

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    weekday: 'long'
  };

  switch (formatType) {
    case 't':
      return date.toLocaleString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    case 'T':
      return date.toLocaleString(undefined, options);
    case 'd':
      return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    case 'D':
      return date.toLocaleDateString(undefined, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    case 'f':
    case 'F':
      return date.toLocaleString(undefined, options);
    case 'R':
      const diff = (new Date().getTime() - date.getTime()) / 1000;
      const absDiff = Math.abs(diff);
      if (absDiff < 60) {
        return diff < 0
          ? `in ${Math.round(absDiff)} seconds`
          : `${Math.round(absDiff)} seconds ago`;
      }
      if (absDiff < 3600) {
        return diff < 0
          ? `in ${Math.round(absDiff / 60)} minutes`
          : `${Math.round(absDiff / 60)} minutes ago`;
      }
      if (absDiff < 86400) {
        return diff < 0
          ? `in ${Math.round(absDiff / 3600)} hours`
          : `${Math.round(absDiff / 3600)} hours ago`;
      }
      return diff < 0
        ? `in ${Math.round(absDiff / 86400)} days`
        : `${Math.round(absDiff / 86400)} days ago`;
    default:
      return timestampStr;
  }
};

interface DiscordTimestampProps {
  timestampStr: string;
}

const DiscordTimestamp: React.FC<DiscordTimestampProps> = ({
  timestampStr
}) => {
  const [formattedTime, setFormattedTime] = useState(() =>
    formatDiscordTimestamp(timestampStr)
  );

  useEffect(() => {
    if (timestampStr.includes(':R')) {
      const getIntervalDuration = () => {
        const diff =
          (new Date().getTime() -
            new Date(
              parseInt(timestampStr.match(/<t:(\d+):(\w)>/)?.[1] || '0') * 1000
            ).getTime()) /
          1000;
        if (Math.abs(diff) < 60) return 1000; // update every second
        if (Math.abs(diff) < 3600) return 60 * 1000; // update every minute
        if (Math.abs(diff) < 86400) return 3600 * 1000; // update every hour
        return 86400 * 1000; // update every day
      };

      const interval = setInterval(() => {
        const newFormattedTime = formatDiscordTimestamp(timestampStr);
        if (newFormattedTime !== formattedTime) {
          setFormattedTime(newFormattedTime);
        }
      }, getIntervalDuration());

      return () => clearInterval(interval);
    }
  }, [timestampStr, formattedTime]);

  return (
    <Box
      sx={{
        display: 'inline-block',
        backgroundColor: 'rgba(128, 128, 128, 0.1)', // subtle gray'ish background
        borderRadius: '4px', // optional: rounded corners
        padding: '2px 4px' // optional: some padding around the text
      }}
    >
      {formattedTime}
    </Box>
  );
};

export default DiscordTimestamp;
