import { AppBar, alpha, lighten, styled } from '@mui/material';
export const HeaderWrapper = styled(AppBar)(
  ({ theme }) => `
          height: ${theme.header.height};
          color: ${theme.header.textColor};
          padding: ${theme.spacing(0, 2)};
          justify-content: center;
          right: 0;
          z-index: ${theme.zIndex.drawer - 2};
          background-color: ${alpha(theme.header.background, 0.95)};
          backdrop-filter: blur(3px);
          position: fixed;
          width: 100%;
          box-shadow:
          ${
            theme.palette.mode === 'dark'
              ? `0 1px 0 ${alpha(
                  lighten(theme.colors.primary.main, 0.7),
                  0.15
                )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
              : `0px 2px 8px -3px ${alpha(
                  theme.colors.alpha.black[100],
                  0.2
                )}, 0px 5px 22px -4px ${alpha(
                  theme.colors.alpha.black[100],
                  0.1
                )}`
          }
  `
);

export default HeaderWrapper;
