export interface BlackListItem {
  DiscordID: number;
  Reason?: string;
  AppealMessage?: string;
}

export function getBlacklist() {
  return {
    DiscordID: 168273596064595968,
    Reason: '',
    AppealMessage: ''
  } as BlackListItem;
}
