import { Middleware } from 'redux';
import { update, acknowledge } from 'src/features/updateResponseSlice';

export async function fetchWrapper(
  url,
  options,
  action,
  storeAPI,
  successMessage,
  failureMessage
) {
  try {
    const response = await fetch(url, options);
    const data = await response.json();
    if (data['status'] === 'Successful') {
      if (action.verbose) {
        storeAPI.dispatch(
          update({
            success: true,
            message: successMessage
          })
        );
      }
      return data;
    } else {
      if (data['errors'])
        throw new Error(data['message'] + '\n' + data['errors'].join('\n'));
      throw new Error(data['message']);
    }
  } catch (error) {
    handleError(error, action, storeAPI, failureMessage);
  }
}

function handleError(error, action, storeAPI, customMessage) {
  console.error(customMessage || 'Error:', error.message);
  if (action.verbose) {
    storeAPI.dispatch(
      update({
        success: false,
        message: customMessage || 'Error occurred.'
      })
    );
  }
}

const updateResponseMiddleware: Middleware = (storeAPI) => {
  return (next) => async (action) => {
    switch (action.type) {
      case 'response/acknowledge':
        storeAPI.dispatch(acknowledge());
        break;
      default:
        break;
    }

    return next(action);
  };
};

export default updateResponseMiddleware;
