import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { DetailsField } from './fields';
import StarIcon from '@mui/icons-material/Star';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import BalanceIcon from '@mui/icons-material/Balance';
import NorthIcon from '@mui/icons-material/North';
import { RaidBosses } from '../raidbosses';
import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { Difficulties } from '../difficulties';

export const RaidDetailsFields: DetailsField[] = [
  {
    key: 'Difficulty',
    textFunction: (difficulty) => {
      const difficultyItem = Difficulties.find(
        ({ Name }) => Name === difficulty
      );
      return (
        <Box display={'flex'} alignItems="center">
          <Avatar
            src={difficultyItem?.Emoji}
            alt={difficultyItem?.Name}
            sx={{
              width: 20,
              height: 20,
              mr: 0.5
            }}
          />
          <Typography variant="h6">{difficultyItem.Short}</Typography>
        </Box>
      );
    },
    textFunctionArgs: ['Difficulty']
  },
  {
    key: 'Bosses',
    textFunction: (bosses) => (
      <React.Fragment>
        <br />
        {bosses.includes('Full Clear') ? (
          <Box display={'flex'} alignItems="center">
            <Avatar
              src={RaidBosses.find(({ Name }) => Name === 'Full Clear')?.Emoji}
              alt={RaidBosses.find(({ Name }) => Name === 'Full Clear')?.Name}
              sx={{
                width: 20,
                height: 20,
                mr: 0.5
              }}
            />
            <Typography variant="h6">
              {RaidBosses.find(({ Name }) => Name === 'Full Clear').Short}
            </Typography>
          </Box>
        ) : (
          bosses.map((boss) => {
            const bossItem = RaidBosses.find(({ Name }) => Name === boss);
            return (
              <Box key={boss} display={'flex'} alignItems="center">
                <Avatar
                  src={bossItem?.Emoji}
                  alt={bossItem?.Name}
                  sx={{
                    width: 20,
                    height: 20,
                    mr: 0.5
                  }}
                />
                <Typography variant="h6">{bossItem.Short}</Typography>
              </Box>
            );
          })
        )}
      </React.Fragment>
    ),
    textFunctionArgs: ['Bosses'],
    prepend: 'Bosses: '
  },
  {
    key: 'ScheduledStartTime',
    textFunction: (time) => {
      const date = new Date(time * 1000);
      return (
        <React.Fragment>
          <Box display={'flex'} alignItems="center">
            <Typography variant="h6">
              {date.toLocaleTimeString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                hour12: true,
                minute: 'numeric'
              })}{' '}
              Local
            </Typography>
          </Box>
          <Box display={'flex'} alignItems="center">
            <Typography variant="h6">
              {/* Eastern Time excluding seconds */}
              {new Date(time * 1000).toLocaleTimeString('en-US', {
                timeZone: 'America/New_York',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                hour12: true,
                minute: 'numeric'
              })}{' '}
              EST
            </Typography>
          </Box>
        </React.Fragment>
      );
    },
    textFunctionArgs: ['ScheduledStartTime']
  }
];

export const RaidRequirementsFields: DetailsField[] = [
  {
    key: 'Unsaved',
    emoji: <CheckroomIcon />,
    emojiColor: 'primary.main',
    textFunction: (unsaved) => (unsaved ? 'Unsaved' : 'Saved'),
    textFunctionArgs: ['Unsaved']
  },
  {
    key: 'MinimumProgress',
    emoji: <NorthIcon />,
    emojiColor: 'success.main',
    append: `/${RaidBosses.length - 1}M Progress`
  },
  {
    key: 'MinimumParse',
    emoji: '/static/images/WoW/Logo/warcraftlogs.png',
    prepend: '%',
    append: ' Heroic Best'
  },
  {
    key: 'MinimumMedianParse',
    emoji: '/static/images/WoW/Logo/warcraftlogs.png',
    prepend: '%',
    append: ' Heroic Median'
  },
  {
    key: 'MinimumIlvl',
    emoji: '/static/images/WoW/Logo/wow.png',
    append: ' ilvl Every Slot'
  },
  {
    key: 'MinimumRating',
    emoji: <StarIcon />,
    emojiColor: 'warning.main',
    append: '/10 Rating'
  },
  {
    key: 'MinimumKarma',
    emoji: <BalanceIcon />,
    emojiColor: 'info.main',
    append: ' Karma'
  }
];
