import { Box, Grid, Typography, TypographyProps } from '@mui/material';
import { EmojiAvatar } from '../EmojiAvatar';
import { Classes, WoWRoles } from 'src/models/wowclasses';
import { getRealm } from 'src/models/realms';
import { Character } from 'src/models/character';
import { SignedupChar } from 'src/models/signup';

export interface SignedupCharactersProps
  extends Omit<TypographyProps, 'color'> {
  signedUpCharacters: SignedupChar[];
  userCharacters: Character[];
}
export const SignedupCharacters = ({
  signedUpCharacters,
  userCharacters,
  ...typographyProps
}: SignedupCharactersProps) => {
  return (
    <Box display={'flex'}>
      {signedUpCharacters?.length > 0 ? (
        <Grid container spacing={0.5}>
          {signedUpCharacters?.map((character, index) => {
            const userChar = userCharacters.find(
              (char) => char._id === character._id
            );
            if (!userChar) return null;
            const charClass = Classes.find(
              (wowclass) => wowclass.Name === userChar.Class
            );
            const signedUpRoles = WoWRoles.filter((role) =>
              character.Roles.includes(role.Name)
            );
            return (
              <Grid
                item
                sm={12}
                key={index}
                display={'inline-flex'}
                alignItems={'center'}
              >
                <EmojiAvatar src={charClass?.Emoji} />
                {signedUpRoles.map((role) => (
                  <EmojiAvatar key={role.Name} src={role.Emoji} />
                ))}
                <Typography {...typographyProps} color={charClass.Color}>
                  {userChar.Name}-{getRealm(userChar.Server, userChar.Realm)}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        'No SignedUp Chars'
      )}
    </Box>
  );
};

export default SignedupCharacters;
