import { Difficulty, SubCategory } from './fields';

function KeyLevel(level: number): SubCategory {
  return {
    Title: `M+ ${level}`,
    ParentCategory: 'MythicPlus',
    Tags: [
      'Tank/Healer Carry Options',
      'Loot Trade Options',
      'Specific Dungeon Options'
    ]
  };
}
export const MythicPlusSubCategories: SubCategory[] = [
  Difficulty('Normal', 'MythicPlus', [
    'Tank/Healer Carry Options',
    'Loot Trade Options'
  ]),
  Difficulty('Heroic', 'MythicPlus', [
    'Tank/Healer Carry Options',
    'Loot Trade Options'
  ]),
  {
    Title: 'Keystone Master',
    ParentCategory: 'MythicPlus',
    Tags: ['Tank/Healer Carry Options', 'Loot Trade Options']
  },
  {
    Title: 'Team Rental',
    ParentCategory: 'MythicPlus',
    Tags: [
      'Tank/Healer Carry Options',
      'Loot Trade Options',
      'Specific Dungeon Options'
    ]
  },
  {
    Title: 'Coaching',
    ParentCategory: 'MythicPlus',
    Tags: ['Specific Dungeon Options', 'Tank/Healer Carry Options']
  },
  ...Array.from({ length: 26 }, (_, i) => KeyLevel(i + 0))
];
