import { Middleware } from 'redux';
import { update, DeleteMessages } from 'src/features/userSlice';
import { fetchWrapper } from 'src/middlewares/updateResponseMiddleware';
import { User } from 'src/models/user';

const userMiddleware: Middleware = (storeAPI) => {
  return (next) => async (action) => {
    switch (action.type) {
      case 'user/fetch':
        try {
          const data = await fetchWrapper(
            `${process.env.REACT_APP_API_URL}/member`,
            {
              credentials: 'include',
              method: 'GET',
              headers: { 'Content-Type': 'application/json' }
            },
            action,
            storeAPI,
            'Fetched user data',
            'Failed to fetch user data'
          );
          storeAPI.dispatch(update(data.data));
        } catch (error) {
          console.error('Failed to fetch user', error);
        }
        break;
      case 'user/cleanups':
        const oldTrigger = (Date.now() - 1000 * 60 * 60 * 24 * 7) / 1000;
        const user: User = storeAPI.getState().user.user;
        const OldDirects = Object.entries(user?.Messages?.Direct ?? [])?.filter(
          ([, value]) =>
            value.every((message) => message.TimeStamp < oldTrigger)
        );
        for (const [key, value] of OldDirects) {
          storeAPI.dispatch(DeleteMessages({ type: 'Direct', id: key }));
        }
        const OldGroups = Object.entries(user?.Messages?.Group ?? [])?.filter(
          ([, value]) =>
            value.every((message) => message.TimeStamp < oldTrigger)
        );
        for (const [key, value] of OldGroups) {
          storeAPI.dispatch(DeleteMessages({ type: 'Group', id: key }));
        }
        break;
      default:
        break;
    }

    return next(action);
  };
};

export default userMiddleware;
