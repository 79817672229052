import { Difficulty, SubCategory } from './fields';

function LevelRange(start: number, end: number): SubCategory {
  return {
    Title: `${start} - ${end}`,
    ParentCategory: 'Leveling',
    Tags: ['Dungeon Boost', 'Mob Farming', 'Solo-Buyer']
  };
}
export const LevelingSubCategories: SubCategory[] = [
  LevelRange(10, 80),
  LevelRange(10, 70),
  LevelRange(60, 70),
  LevelRange(70, 80)
];
