import { useState, useEffect, useRef } from 'react';
import { throttle } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/app/store';

type StatusChangeCallback = (status: string) => void;

const useUserActivity = (onStatusChange?: StatusChangeCallback) => {
  const [status, setStatus] = useState('active'); // active, idle, inactive
  const statusRef = useRef(status);
  const dispatch = useDispatch();
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const { loggedIn } = useSelector((state: RootState) => state.login);

  useEffect(() => {
    statusRef.current = status;
  }, [status]);

  useEffect(() => {
    let idleTimer: NodeJS.Timeout;
    let inactiveTimer: NodeJS.Timeout;

    const resetTimers = () => {
      clearTimeout(idleTimer);
      clearTimeout(inactiveTimer);
      setStatusAndNotify('active');

      idleTimer = setTimeout(() => setStatusAndNotify('idle'), 5 * 60 * 1000);
      inactiveTimer = setTimeout(
        () => setStatusAndNotify('inactive'),
        30 * 60 * 1000
      );
    };

    const setStatusAndNotify = (newStatus: string) => {
      setStatus(newStatus);
      if (onStatusChange) {
        onStatusChange(newStatus);
      }
    };

    const throttledResetTimers = throttle(resetTimers, 1000);

    window.addEventListener('mousemove', throttledResetTimers, {
      passive: true
    });
    window.addEventListener('keydown', throttledResetTimers, { passive: true });
    window.addEventListener('scroll', throttledResetTimers, { passive: true });

    resetTimers();

    return () => {
      window.removeEventListener('mousemove', throttledResetTimers);
      window.removeEventListener('keydown', throttledResetTimers);
      window.removeEventListener('scroll', throttledResetTimers);
      throttledResetTimers.cancel();
      clearTimeout(idleTimer);
      clearTimeout(inactiveTimer);
    };
  }, [onStatusChange]);

  useEffect(() => {
    const statusReportInterval = setInterval(() => {
      if (statusRef.current === 'active') {
        if (isConnected && loggedIn) {
          dispatch({
            type: 'socket/activity/update'
          });
        }
        if (!isConnected) {
          dispatch({ type: 'login/check' });
        }
      }
      if (statusRef.current === 'inactive' && isConnected) {
        dispatch({
          type: 'socket/disconnect'
        });
      }
    }, 60000);

    return () => {
      clearInterval(statusReportInterval);
    };
  }, [dispatch, isConnected, loggedIn]);

  return status;
};

export default useUserActivity;
