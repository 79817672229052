import produce from 'immer';
import set from 'lodash/set';
import unset from 'lodash/unset';
import get from 'lodash/get';

type MongoOperation = {
  $set?: { [key: string]: any };
  $unset?: { [key: string]: any };
  $inc?: { [key: string]: number };
  $push?: { [key: string]: any };
  $pull?: { [key: string]: any };
  $addToSet?: { [key: string]: any };
};

export const mongoUpdate = produce((draft: any, update: MongoOperation) => {
  if (update.$set) {
    for (const [key, value] of Object.entries(update.$set)) {
      set(draft, key, value);
    }
  }

  if (update.$unset) {
    for (const key of Object.keys(update.$unset)) {
      unset(draft, key);
    }
  }

  if (update.$inc) {
    for (const [key, value] of Object.entries(update.$inc)) {
      const existingValue = get(draft, key, 0);
      set(draft, key, existingValue + value);
    }
  }

  if (update.$push) {
    for (const [key, value] of Object.entries(update.$push)) {
      const arr = get(draft, key, []);
      arr.push(value);
      set(draft, key, arr);
    }
  }

  if (update.$pull) {
    for (const [key, value] of Object.entries(update.$pull)) {
      const arr = get(draft, key, []);
      const filteredArr = arr.filter((item) => {
        for (const [k, v] of Object.entries(value)) {
          if (get(item, k) === v) {
            return false;
          }
          return true;
        }
      });
      set(draft, key, filteredArr);
    }
  }

  if (update.$addToSet) {
    for (const [key, addToSetValue] of Object.entries(update.$addToSet)) {
      const arr = get(draft, key, []);
      // Check if the value has $each modifier
      if (addToSetValue.$each) {
        addToSetValue.$each.forEach((value) => {
          if (!arr.includes(value)) {
            arr.push(value);
          }
        });
      } else {
        // Handle the case without $each, the original logic
        if (!arr.includes(addToSetValue)) {
          arr.push(addToSetValue);
        }
      }
      set(draft, key, arr);
    }
  }
});

interface QueryCondition {
  $or?: QueryCondition[];
  $and?: QueryCondition[];
  [key: string]: any; // This allows for other key-value pairs
}

interface ComparisonOperators {
  $eq?: any;
  $ne?: any;
  $gt?: any;
  $lt?: any;
  // Add other comparison operators here
}

function isComparisonOperator(value: any): value is ComparisonOperators {
  return value && typeof value === 'object';
}

function matchCondition(document: any, condition: QueryCondition): boolean {
  if (condition.$or) {
    return condition.$or.some((cond) => matchCondition(document, cond));
  }

  if (condition.$and) {
    return condition.$and.every((cond) => matchCondition(document, cond));
  }

  for (const [key, value] of Object.entries(condition)) {
    if (isComparisonOperator(value)) {
      if (value.$eq !== undefined && document[key] !== value.$eq) {
        return false;
      }
      if (value.$ne !== undefined && document[key] === value.$ne) {
        return false;
      }
      if (value.$gt !== undefined && document[key] <= value.$gt) {
        return false;
      }
      if (value.$lt !== undefined && document[key] >= value.$lt) {
        return false;
      }
      // Add other operators like $gte, $lte, etc. here
    } else {
      // Direct equality
      if (document[key] !== value) {
        return false;
      }
    }
  }

  return true;
}

export function updateMany(
  collection: any[],
  query: QueryCondition | undefined,
  update: MongoOperation
): any[] {
  if (query === undefined || Object.keys(query).length === 0) {
    return collection.map((document) => mongoUpdate(document, update));
  }
  return collection.map((document) => {
    if (matchCondition(document, query)) {
      return mongoUpdate(document, update);
    } else {
      return document;
    }
  });
}
