import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Box
} from '@mui/material';

const ConfirmButton = ({
  onClick,
  children,
  confirmText = 'Are you sure?',
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleClose();
    onClick();
  };

  return (
    <>
      <Button {...rest} onClick={handleOpen}>
        {children}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box textAlign="center">
            <Typography variant="h3" color="primary">
              {confirmText}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <DialogContentText>
              This action is
              <Typography component="span" color="error" variant="h4">
                {' irreversible.'}
              </Typography>
              Are you sure you want to
              <Typography component="span" color="error" variant="h4">
                {' proceed?'}
              </Typography>
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="center" width="100%">
            <Button
              variant="contained"
              onClick={handleClose}
              color="error"
              sx={{ mr: 0.5 }}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirm}
              color="success"
              sx={{ ml: 0.5 }}
            >
              Yes
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmButton;
