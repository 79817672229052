import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/app/store';

export interface averageStatField {
  value: number;
  checks: { [key: string]: any };
}

export interface averageStats {
  [key: string]: averageStatField[];
}

function checkItem(element, state) {
  for (const check in element.checks) {
    if (state[check] !== undefined) {
      if (element.checks[check] !== state[check]) {
        return false;
      }
    }
  }
  return true;
}

export function findRelevantValue(key, state, stats: averageStats) {
  if (stats[key] !== undefined) {
    const relevantField = stats[key].find((element) => {
      return checkItem(element, state);
    });
    if (relevantField !== undefined) {
      if ('Quantity' in state) {
        return (
          relevantField.value *
          (1 -
            (state['Quantity'] > 1
              ? Math.min(state['Quantity'] * 0.025, 0.1)
              : 0))
        );
      }
      return relevantField.value;
    }
  }
  return 0;
}

export function UpdateFromData(
  state,
  setState,
  stats: averageStats,
  specificKey?: string
): void {
  let changes = {};
  if (specificKey) {
    const change = findRelevantValue(specificKey, state, stats);
    if (change !== 0 || specificKey === 'TotalPayment') {
      changes[specificKey] = change;
    }
  } else {
    for (const key in stats) {
      const change = findRelevantValue(key, state, stats);
      if (change !== 0 || key === 'TotalPayment') {
        changes[key] = change;
      }
    }
  }

  if (
    state['TotalPayment'] &&
    (state['Quantity'] || state['tanks'] || state['healers'] || state['dps'])
  ) {
    const totalSpots =
      (state['tanks'] || 0) + (state['healers'] || 0) + (state['dps'] || 0);
    changes['TotalPayment'] =
      (changes['TotalPayment'] * state['Quantity'] * totalSpots) / 4;
  }
  setState({ ...state, ...changes });
}
