import { useState } from 'react';
import {
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { MenuList } from '@material-ui/core';

function RegionSelect() {
  const dispatch = useDispatch();
  const region = useSelector((state: RootState) => state.client.region);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRegionChange = (region: string) => {
    dispatch({
      type: 'client/region',
      payload: region
    });
    handleClose();
  };
  return (
    <Box>
      <Tooltip arrow title="Select Region">
        <IconButton color="primary" onClick={handleClick}>
          <Avatar src={`/static/images/WoW/Flags/${region}.svg`} />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuList dense>
          <MenuItem onClick={() => handleRegionChange('EU')}>
            <Avatar src="/static/images/WoW/Flags/EU.svg" sx={{ mr: 1 }} />
            Switch to EU
          </MenuItem>
          <MenuItem onClick={() => handleRegionChange('US')}>
            <Avatar src="/static/images/WoW/Flags/US.svg" sx={{ mr: 1 }} />
            Switch to US
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}

export default RegionSelect;
