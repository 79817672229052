import {
  Avatar,
  Box,
  Typography,
  TypographyProps,
  useTheme
} from '@mui/material';
import Text from 'src/components/Text';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import React from 'react';
import { WoWArmorType } from 'src/models/wowarmors';
import { WoWTierToken } from 'src/models/wowtiertokens';
import PriceItem from '../PriceItem';
import { PricelistItem } from 'src/models/pricelist';

export interface BuyerPriceProps extends Omit<TypographyProps, 'color'> {
  items: PricelistItem[];
  currentBuyers: Record<string, number>;
  Detailed?: boolean;
}
export const BuyerPrice = ({
  items,
  currentBuyers,
  Detailed,
  ...typographyProps
}: BuyerPriceProps) => {
  const theme = useTheme();
  const cheapestItem = items.reduce((prev, current) =>
    prev.BasePrice < current.BasePrice ? prev : current
  );
  const mostExpensiveItem = items.reduce((prev, current) =>
    prev.BasePrice > current.BasePrice ? prev : current
  );
  if (!items || items.length === 0) return null;
  return (
    <React.Fragment>
      {Detailed ? (
        items.map((item, i) => (
          <PriceItem
            key={i}
            Item={item}
            currentBuyers={currentBuyers[item.Title] ?? 0}
            Detailed={Detailed}
            TextColor={
              currentBuyers[item.Title] === item.MaximumBuyers
                ? 'error'
                : 'success'
            }
            {...typographyProps}
          />
        ))
      ) : items.length > 1 ? (
        <Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'left'}>
            <PriceItem
              Item={cheapestItem}
              currentBuyers={currentBuyers[cheapestItem.Title] ?? 0}
              Detailed={Detailed}
              TextColor={
                currentBuyers[cheapestItem.Title] === cheapestItem.MaximumBuyers
                  ? 'error'
                  : 'success'
              }
              {...typographyProps}
            />
            <Typography variant="body1" {...typographyProps} sx={{ mx: 1 }}>
              {'to'}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'left'}>
            <PriceItem
              Item={mostExpensiveItem}
              currentBuyers={currentBuyers[mostExpensiveItem.Title] ?? 0}
              Detailed={Detailed}
              TextColor={
                currentBuyers[mostExpensiveItem.Title] ===
                mostExpensiveItem.MaximumBuyers
                  ? 'error'
                  : 'success'
              }
              {...typographyProps}
            />
          </Box>
        </Box>
      ) : (
        <PriceItem
          Item={items[0]}
          currentBuyers={currentBuyers[items[0].Title] ?? 0}
          Detailed={Detailed}
          TextColor={
            currentBuyers[items[0].Title] === items[0].MaximumBuyers
              ? 'error'
              : 'success'
          }
          {...typographyProps}
        />
      )}
    </React.Fragment>
  );
};

export default BuyerPrice;
