import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { mongoUpdate } from './mongoUpdate';

const persistConfig = {
  key: 'client',
  storage: storage
};

interface clientProps {
  timeOffset: number;
  region: 'US' | 'EU';
}
const initialState: clientProps = {
  timeOffset: 0,
  region: 'US'
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    reset: (state) => {
      for (const key in state) {
        if (!(key in initialState)) {
          delete state[key];
        }
      }
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    updateTimeOffset: (state, action: PayloadAction<number>) => {
      const nowTimestamp = Date.now();
      const serverTimestamp = action.payload * 1000;
      const newOffset = nowTimestamp - serverTimestamp;
      if (Math.abs(newOffset - state.timeOffset) > 5000) {
        state.timeOffset = newOffset;
      }
    },
    update: (state, action: PayloadAction<clientProps>) => {
      for (const key in action.payload) {
        if (Object.prototype.hasOwnProperty.call(action.payload, key)) {
          state[key] = action.payload[key];
        }
      }
    },
    mongodbUpdate: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state = mongoUpdate(state, action.payload);
    },
    regionChange: (state, action: PayloadAction<'US' | 'EU'>) => {
      state.region = action.payload;
    }
  }
});

export const { reset, updateTimeOffset, update, mongodbUpdate, regionChange } =
  clientSlice.actions;
const persistedReducer = persistReducer(persistConfig, clientSlice.reducer);
export default persistedReducer;
