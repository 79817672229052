export interface RaidBoss {
  Name: string;
  Emoji: string;
  Image: string;
  Short: string;
}

export const RaidBosses: RaidBoss[] = [
  {
    Name: 'Ulgrax the Devourer',
    Emoji: '/static/images/WoW/Raids/TWW1/Ulgrax the Devourer.png',
    Image: '/static/images/WoW/Raids/TWW1/Ulgrax the Devourer.png',
    Short: 'Ulgrax'
  },
  {
    Name: 'The Bloodbound Horror',
    Emoji: '/static/images/WoW/Raids/TWW1/The Bloodbound Horror.png',
    Image: '/static/images/WoW/Raids/TWW1/The Bloodbound Horror.png',
    Short: 'Bloodbound'
  },
  {
    Name: 'Sikran, Captain of the Sureki',
    Emoji: '/static/images/WoW/Raids/TWW1/Sikran.png',
    Image: '/static/images/WoW/Raids/TWW1/Sikran.png',
    Short: 'Sikran'
  },
  {
    Name: "Rasha'nan",
    Emoji: "/static/images/WoW/Raids/TWW1/Rasha'nan.png",
    Image: "/static/images/WoW/Raids/TWW1/Rasha'nan.png",
    Short: "Rasha'nan"
  },
  {
    Name: "Broodtwister Ovi'nax",
    Emoji: "/static/images/WoW/Raids/TWW1/Broodtwister Ovi'nax.png",
    Image: "/static/images/WoW/Raids/TWW1/Broodtwister Ovi'nax.png",
    Short: 'Broodtwister'
  },
  {
    Name: "Nexus-Princess Ky'veza",
    Emoji: "/static/images/WoW/Raids/TWW1/Nexus-Princess Ky'veza.png",
    Image: "/static/images/WoW/Raids/TWW1/Nexus-Princess Ky'veza.png",
    Short: 'Princess'
  },
  {
    Name: 'The Silken Court',
    Emoji: '/static/images/WoW/Raids/TWW1/The Silken Court.png',
    Image: '/static/images/WoW/Raids/TWW1/The Silken Court.png',
    Short: 'Silken Court'
  },
  {
    Name: 'Queen Ansurek',
    Emoji: '/static/images/WoW/Raids/TWW1/Queen Ansurek.png',
    Image: '/static/images/WoW/Raids/TWW1/Queen Ansurek.png',
    Short: 'Queen'
  },
  {
    Name: 'Full Clear',
    Emoji: '/static/images/WoW/Raids/TWW1/Queen Ansurek.png',
    Image: '/static/images/WoW/Raids/TWW1/Queen Ansurek.png',
    Short: 'Full Clear'
  }
];
