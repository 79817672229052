import { Box, Typography, TypographyProps, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import React from 'react';
import { EmojiAvatar } from '../EmojiAvatar';

function MarketAverageColor(index: number, theme) {
  switch (index) {
    case 0:
      return theme.colors.error.main;
    case 1:
      return theme.colors.info.main;
    case 2:
      return theme.colors.success.main;
  }
}

function MarketAverageEmoji(index: number) {
  switch (index) {
    case 0:
      return <CloseIcon />;
    case 1:
      return <CheckCircleIcon />;
    case 2:
      return <RocketLaunchIcon />;
  }
}

function MarketAverageText(index: number) {
  switch (index) {
    case 0:
      return 'Below Market Average';
    case 1:
      return 'Market Average';
    case 2:
      return 'Above Market Average';
  }
}

function MarketAverage(index: number, theme) {
  return (
    <Box display={'flex'}>
      <EmojiAvatar
        variant="square"
        color={MarketAverageColor(index, theme)}
        sx={{ marginRight: 0.5 }}
      >
        {MarketAverageEmoji(index)}
      </EmojiAvatar>
      <Typography
        variant="body2"
        gutterBottom
        color={MarketAverageColor(index, theme)}
      >
        {MarketAverageText(index)}
      </Typography>
    </Box>
  );
}

export interface CutProps extends Omit<TypographyProps, 'color'> {
  MarketAverageIndex: number;
  CutPerBoosterString: string;
  KeyBonusPayment?: number;
  GroupLeaderBonus?: number;
  CoordinatorBonusString?: string;
  Detailed?: boolean;
  RoleBonusPayment?: { [key: string]: number };
}

export const Cut = ({
  MarketAverageIndex,
  CutPerBoosterString,
  KeyBonusPayment,
  GroupLeaderBonus,
  CoordinatorBonusString,
  Detailed,
  RoleBonusPayment,
  ...typographyProps
}: CutProps) => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Box display={'inline-flex'} alignItems="center">
        <EmojiAvatar src="/static/images/WoW/Emojis/Gold.png" />
        <Box>
          <Typography
            variant="body2"
            color={
              Detailed
                ? undefined
                : MarketAverageColor(MarketAverageIndex, theme)
            }
            {...typographyProps}
          >
            {CutPerBoosterString}
            {Detailed && KeyBonusPayment ? ` (+${KeyBonusPayment}K Key)` : ''}
            {Detailed && RoleBonusPayment
              ? Object.keys(RoleBonusPayment).map((role) => {
                  return ` (+${RoleBonusPayment[role]}K ${role})`;
                })
              : ''}
            {GroupLeaderBonus ? ` (+${GroupLeaderBonus}K Leader)` : ''}
          </Typography>
          {!Detailed &&
            (KeyBonusPayment ||
              (RoleBonusPayment &&
                Object.values(RoleBonusPayment).some((bonus) => bonus !== 0)) ||
              CoordinatorBonusString) && (
              <Typography
                variant="body1"
                fontSize={12}
                color={
                  Detailed
                    ? undefined
                    : MarketAverageColor(MarketAverageIndex, theme)
                }
                {...typographyProps}
              >
                {KeyBonusPayment ? ` (+${KeyBonusPayment}K Key)` : ''}
                {RoleBonusPayment
                  ? Object.keys(RoleBonusPayment).map((role) => {
                      return ` (+${RoleBonusPayment[role]}K ${role})`;
                    })
                  : ''}
              </Typography>
            )}
        </Box>
      </Box>
      {Detailed && MarketAverage(MarketAverageIndex, theme)}
      {Detailed && CoordinatorBonusString && (
        <Box display={'flex'} alignItems="center">
          <ManageAccountsIcon color="warning" sx={{ mr: 0.25 }} />
          <Typography variant="body2" gutterBottom>
            Poster Bonus: {CoordinatorBonusString}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

export default Cut;
