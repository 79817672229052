import { useState, useEffect, useCallback } from 'react';

import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

import { Grid } from '@mui/material';
import useRefMounted from 'src/hooks/useRefMounted';
import PageHeader from './PageHeader';
import Results from './Results';
import { getMockShopItem } from 'src/models/shopitem';
import { getUser } from 'src/models/user';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function ManagementProducts() {
  const user = getUser();

  return (
    <>
      <Helmet>
        <title>Products - Management</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Results items={user?.PriceList} />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default ManagementProducts;
