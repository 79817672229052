import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  MenuItem,
  Select,
  Typography,
  TypographyProps,
  Button,
  Popover
} from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import TuneIcon from '@mui/icons-material/Tune';
import Text from 'src/components/Text';
import { EmojiAvatar } from '../EmojiAvatar';
import { PricelistItem } from 'src/models/pricelist';

export interface PriceItemProps extends Omit<TypographyProps, 'color'> {
  Item: PricelistItem;
  currentBuyers: number;
  Detailed?: boolean;
  TextColor?:
    | 'warning'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'black';
}

export const PriceItem = ({
  Item,
  currentBuyers,
  Detailed,
  TextColor,
  ...typographyProps
}: PriceItemProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [currentOptionIndex, setCurrentOptionIndex] = React.useState<
    number | null
  >(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setAnchorEl(event.currentTarget);
    setCurrentOptionIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentOptionIndex(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Box display={'flex'} alignItems={'center'} justifyContent={'left'}>
        <EmojiAvatar
          src="/static/images/WoW/Emojis/Gold.png"
          sx={{ mx: 0.25 }}
        />
        <Typography variant="body1" {...typographyProps}>
          <Text color="black">
            {Item.BasePrice}K
            {Item.DepositPercentage
              ? ` (${Item.DepositPercentage}% Deposit)`
              : ''}
          </Text>
        </Typography>
      </Box>
      {Detailed && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'left'}>
          <EventSeatIcon
            color={
              Item.MaximumBuyers && currentBuyers === Item.MaximumBuyers
                ? 'error'
                : 'success'
            }
            sx={{ mr: 0.25 }}
          />

          <Typography variant="body1" {...typographyProps}>
            {Item.MaximumBuyers ? (
              <Text color="black">
                {currentBuyers}/{Item.MaximumBuyers} Buyer Spots
              </Text>
            ) : (
              <Text color="black">Unlimited Buyer Spots</Text>
            )}
          </Typography>
        </Box>
      )}
      {Detailed && (
        <Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'left'}>
            <TuneIcon color={'info'} sx={{ mr: 0.25 }} />
            <Typography variant="body1" {...typographyProps}>
              <Text color="black">Options:</Text>
            </Typography>
          </Box>
          {Item.Options.map((option, index) => (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'left'}
              key={option.id}
            >
              <Button
                color="info"
                variant="text"
                onClick={(event) => handleClick(event, index)}
                size="small"
              >
                {option.Title}
              </Button>
              <Popover
                open={open && currentOptionIndex === index}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <Box sx={{ p: 2 }}>
                  {option.Choices.map((choice) => (
                    <Box key={choice.id}>
                      {choice.Title} (+{choice.Price}K)
                    </Box>
                  ))}
                </Box>
              </Popover>
            </Box>
          ))}
          <Box display={'flex'} alignItems={'center'} justifyContent={'left'}>
            <LocalOfferIcon color={'info'} sx={{ mr: 0.25 }} />
            <Typography variant="body1" {...typographyProps}>
              <Text color="black">{Item.Tags.join(', ')}</Text>
            </Typography>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default PriceItem;
