import React, { Component } from 'react';

class ErrorBoundary extends Component {
  state = { hasError: false, errorInfo: null };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service here
    this.setState({ errorInfo });
    console.error('Caught an error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI here
      return (
        <h1>
          Something went wrong! Refresh the page.
          <br />
          If this is recurring delete website cookies.
        </h1>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
