import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { FriendlistContext } from 'src/contexts/FriendlistContext';

import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  lighten,
  darken,
  Typography
} from '@mui/material';
import Text from 'src/components/Text';
import Group from 'src/components/WoW/Group';
import Friendlist from 'src/components/WoW/Friendlist';
import Blacklist from 'src/components/WoW/Blacklist';
import RecentChats from 'src/components/WoW/RecentChats';

const FriendlistWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: ${theme.zIndex.drawer - 1};
        height: 100%;
        padding-bottom: 68px;
`
);

function FriendlistSidebar() {
  const { friendlistToggle: sidebarToggle, toggleFriendlist } =
    useContext(FriendlistContext);
  const closeFriendlist = () => toggleFriendlist();
  const theme = useTheme();

  const sideBarComponent = (drawer: boolean) => {
    return (
      <FriendlistWrapper
        sx={
          drawer
            ? {
                background:
                  theme.palette.mode === 'dark'
                    ? theme.colors.alpha.white[100]
                    : darken(theme.colors.alpha.black[100], 0.5)
              }
            : {
                display: {
                  xs: 'none',
                  lg: 'inline-block'
                },
                position: 'fixed',
                right: 0,
                top: 0,
                background:
                  theme.palette.mode === 'dark'
                    ? alpha(lighten(theme.header.background, 0.1), 0.5)
                    : darken(theme.colors.alpha.black[100], 0.5),
                boxShadow:
                  theme.palette.mode === 'dark'
                    ? theme.sidebar.boxShadow
                    : 'none'
              }
        }
      >
        <Scrollbar>
          <Box mt={3}>
            <Box
              ml={3}
              sx={{
                width: 52
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                <Text color="black">Social</Text>
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              mt: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10]
            }}
          />
          <Box m={1}>
            <Group />
          </Box>
          <Box m={1}>
            <Friendlist />
          </Box>
          <Box m={1}>
            <Blacklist />
          </Box>
          <Box m={1}>
            <RecentChats />
          </Box>
        </Scrollbar>
      </FriendlistWrapper>
    );
  };

  return (
    <>
      {sideBarComponent(false)}
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
        open={sidebarToggle}
        onClose={closeFriendlist}
        variant="temporary"
        elevation={9}
      >
        {sideBarComponent(true)}
      </Drawer>
    </>
  );
}

export default FriendlistSidebar;
