import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Drawer,
  Grid,
  useTheme,
  IconButton,
  styled,
  Button
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import PageHeader from './PageHeader';
import Sidebar from './Sidebar';
import AdditionalInfo from './AdditionalInfo';
import GeneralSection from './GeneralSection';
import { PricelistItem } from 'src/models/pricelist';
import { useDispatch } from 'react-redux';
import { getUser } from 'src/models/user';
import { useLocation } from 'react-router-dom';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface ManagementProductCreateProps {}

function ManagementProductCreate({}: ManagementProductCreateProps) {
  const query = useQuery();
  const ItemID = query.get('id');
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = getUser();
  const Item = user.PriceList?.find((item) => ItemID === item._id);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [newItem, setNewItem] = useState<PricelistItem>(
    Item ??
      ({
        Title: '',
        BasePrice: 0,
        DepositPercentage: 0,
        MaximumBuyers: 0,
        Category: undefined,
        Options: [],
        Users: []
      } as PricelistItem)
  );
  const [hasError, setHasError] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sidebarContent = (
    <Scrollbar>
      <Sidebar />
    </Scrollbar>
  );

  const onCreate = () => {
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'edit_pricelist_item',
        data: {
          ...newItem,
          _id: undefined
        }
      }
    });
  };

  const onEdit = () => {
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'edit_pricelist_item',
        data: {
          ...newItem,
          _id: Item?._id
        }
      }
    });
  };

  const onDelete = () => {
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'delete_pricelist_items',
        data: {
          ItemIDs: [Item?._id]
        }
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Create Product - Commerce Management</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Box mb={3} display="flex">
        <MainContentWrapper>
          <Grid
            sx={{
              px: 4
            }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item xs={12}>
              <GeneralSection
                Item={newItem}
                SetItem={setNewItem}
                othersHaveError={hasError}
                onCreate={onCreate}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </Grid>
            <Grid item xs={12}>
              <AdditionalInfo
                Item={newItem}
                SetItem={setNewItem}
                setHasError={setHasError}
              />
            </Grid>
          </Grid>
        </MainContentWrapper>
        {/* <Box
          component="span"
          sx={{
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <DrawerWrapperMobile
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'left' : 'right'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
          >
            {sidebarContent}
          </DrawerWrapperMobile>
        </Box>
        <Box
          component="span"
          sx={{
            display: { xs: 'none', lg: 'inline-block' }
          }}
        >
          <DrawerWrapper
            variant="permanent"
            anchor={theme.direction === 'rtl' ? 'left' : 'right'}
            open
          >
            {sidebarContent}
          </DrawerWrapper>
        </Box> */}
      </Box>
    </>
  );
}

export default ManagementProductCreate;
