import {
  Box,
  Avatar,
  Typography,
  Card,
  styled,
  Divider,
  Dialog,
  IconButton,
  Badge
} from '@mui/material';

import {
  formatDistance,
  format,
  subDays,
  subHours,
  subMinutes
} from 'date-fns';
import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import { DiscordDetails, getBadgeColor, getUser } from 'src/models/user';
import { Message, SampleGroupMessage } from 'src/models/message';
import { renderMarkdownContent } from 'src/components/Utility/RenderMarkdown';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfilePopUp } from 'src/components/WoW/Profile';
import { RootState } from 'src/app/store';

const DividerWrapper = styled(Divider)(
  ({ theme }) => `
      .MuiDivider-wrapper {
        border-radius: ${theme.general.borderRadiusSm};
        text-transform: none;
        background: ${theme.palette.background.default};
        font-size: ${theme.typography.pxToRem(13)};
        color: ${theme.colors.alpha.black[50]};
      }
`
);

const CardWrapperPrimary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.primary.light};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(0.5)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-right-radius: ${theme.general.borderRadius};
      max-width: 900px;
      display: inline-flex;
      word-wrap: break-word;
      overflow-wrap: break-word;
      word-break: break-all;
      white-space: normal;
      overflow: hidden;
`
);

const CardWrapperSecondary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      color: ${theme.colors.alpha.black[100]};
      padding: ${theme.spacing(0.5)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-left-radius: ${theme.general.borderRadius};
      max-width: 900px;
      display: inline-flex;
      word-wrap: break-word;
      overflow-wrap: break-word;
      word-break: break-all;
      white-space: normal;
      overflow: hidden;
`
);

interface ChatContentProps {
  Messages: Message[];
}

function ChatContent({ Messages }: ChatContentProps) {
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const handleOpen = (imageUrl) => {
    setCurrentImage(imageUrl);
    setOpen(true);
  };
  const dispatch = useDispatch();
  const user = getUser();
  const uniqueSenders = new Set(Messages?.map((message) => message.Sender));
  const uniqueSendersArray = Array.from(uniqueSenders);
  const sendersDiscordDetails: { [key: string]: DiscordDetails } =
    Object.fromEntries(
      uniqueSendersArray.map((sender) => [
        sender,
        user?.CachedDiscordDetails?.[sender]
      ])
    );
  const onlineStatuses = useSelector(
    (state: RootState) => state.user?.onlineStatuses
  );
  const sendersOnlineStatus: {
    [key: string]: 'success' | 'secondary' | 'warning';
  } = Object.fromEntries(
    uniqueSendersArray.map((sender) => [
      sender,
      getBadgeColor(onlineStatuses?.[sender])
    ])
  );

  const [openProfile, setOpenProfile] = useState<string>(undefined);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let discordIDs = [];
    for (const [key, value] of Object.entries(sendersDiscordDetails)) {
      if (
        value === undefined ||
        value.LastUpdate < new Date().getTime() / 1000 - 86400
      ) {
        discordIDs.push(key);
      }
    }
    if (discordIDs.length === 0) {
      return;
    }
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'get_bulk_discord_details',
        data: {
          DiscordIDs: discordIDs
        },
        silent: true
      }
    });
  }, [uniqueSenders]);

  let previousDate = null;

  return (
    <Box>
      {Messages?.map((message) => {
        if (
          !message ||
          (!message.Content && !message.PictureURL) ||
          (message?.EligibleReaders !== undefined &&
            message?.EligibleReaders?.length > 0 &&
            !message?.EligibleReaders?.includes(user._id))
        ) {
          return null;
        }
        const currentDate = format(
          new Date(message.TimeStamp * 1000),
          'MMMM dd yyyy'
        );
        const showDivider = currentDate !== previousDate;
        const selfMessage = message.Sender === user._id;
        const MessageCard = selfMessage
          ? CardWrapperPrimary
          : CardWrapperSecondary;
        previousDate = currentDate; // Update the tracking variable

        return (
          <Box pb={1} pl={3} pr={3} key={message._id}>
            {showDivider && (
              <DividerWrapper sx={{ mb: 3, mt: 3 }}>
                {currentDate}
              </DividerWrapper>
            )}
            <Typography
              variant="subtitle1"
              sx={{
                pt: 1,
                textAlign: selfMessage ? 'end' : 'start'
              }}
            >
              {sendersDiscordDetails?.[message.Sender]?.Username}
            </Typography>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent={selfMessage ? 'flex-end' : 'flex-start'}
            >
              {!selfMessage && (
                <IconButton
                  onClick={() => setOpenProfile(message.Sender)}
                  sx={{ my: -0.9, mx: -0.9 }}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    badgeContent={''}
                    color={sendersOnlineStatus?.[message.Sender]}
                  >
                    <Avatar
                      variant="rounded"
                      sx={{
                        width: 50,
                        height: 50
                      }}
                      alt={sendersDiscordDetails?.[message.Sender]?.Username}
                      src={sendersDiscordDetails?.[message.Sender]?.AvatarURL}
                    />
                  </Badge>
                </IconButton>
              )}
              <Box
                display="flex"
                alignItems={selfMessage ? 'flex-end' : 'flex-start'}
                flexDirection="column"
                justifyContent="flex-start"
                ml={selfMessage ? 0 : 2}
                mr={selfMessage ? 2 : 0}
              >
                {message.Content && (
                  <MessageCard>
                    {/* Render Message Content */}
                    <Typography>
                      {renderMarkdownContent(message.Content)}
                    </Typography>
                  </MessageCard>
                )}
                {message.PictureURL && (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      mt: message.Content ? 1 : 0
                    }}
                  >
                    <img
                      src={message.PictureURL}
                      alt="Message Attachment"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '150px',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleOpen(message.PictureURL)}
                    />
                  </Box>
                )}
                <Typography
                  variant="subtitle1"
                  sx={{
                    pt: 1,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <ScheduleTwoToneIcon
                    sx={{
                      mr: 0.5
                    }}
                    fontSize="small"
                  />
                  {formatDistance(
                    new Date(message.TimeStamp * 1000),
                    new Date(),
                    {
                      addSuffix: true
                    }
                  )}
                </Typography>
              </Box>
              {selfMessage && (
                <IconButton
                  onClick={() => setOpenProfile(message.Sender)}
                  sx={{ my: -0.9, mx: -0.9 }}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    badgeContent={''}
                    color={sendersOnlineStatus?.[message.Sender]}
                  >
                    <Avatar
                      variant="rounded"
                      sx={{
                        width: 50,
                        height: 50
                      }}
                      alt={user.DiscordDetails.Username}
                      src={user.DiscordDetails.AvatarURL}
                    />
                  </Badge>
                </IconButton>
              )}
            </Box>
          </Box>
        );
      })}
      <Dialog open={open} onClose={handleClose}>
        <img
          src={currentImage}
          alt="Enlarged Message Attachment"
          style={{ width: '100%', height: 'auto' }}
        />
      </Dialog>
      <ProfilePopUp
        id={openProfile}
        discordDetails={
          openProfile
            ? Object.entries(user?.CachedDiscordDetails).find(
                ([id, user]) => id === openProfile
              )?.[1]
            : undefined
        }
        onClose={() => setOpenProfile(undefined)}
      />
    </Box>
  );
}

export default ChatContent;
