import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './app/store';
import StackedAnnouncements from './components/Announcements';
import React from 'react';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const login = useSelector((state: RootState) => state.login);
  const location = useLocation();

  if (!login.loggedIn) {
    // Redirect them to the /login page, but save the current location they were trying to go to.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <React.Fragment>
      <StackedAnnouncements />
      {children}
    </React.Fragment>
  );
};

export default ProtectedRoute;
