export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      if (typeof fileReader.result === 'string') {
        let base64Result = fileReader.result;

        // Ensure the base64 part of the data URL is correctly padded
        const base64Data = base64Result.split(',')[1];
        const paddingLength = 4 - (base64Data.length % 4);
        if (paddingLength < 4) {
          base64Result += '='.repeat(paddingLength);
        }

        resolve(base64Result);
      } else {
        reject(new Error('FileReader result is not a string.'));
      }
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
