import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Button,
  DialogActions,
  Box // Import Box from Material-UI
} from '@mui/material';
import { RootState } from './app/store';
import { useState, useEffect } from 'react';
import CooldownButton from './components/Utility/CooldownButton';

const ReconnectingOverlay = () => {
  const dispatch = useDispatch();
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const { loggedIn } = useSelector((state: RootState) => state.login);
  const [countdown, setCountdown] = useState(20); // 20 seconds countdown

  useEffect(() => {
    if (!loggedIn || isConnected) {
      return;
    }

    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    const timer = setTimeout(() => {
      clearInterval(interval);
    }, 20000);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [loggedIn, isConnected]);

  if (!loggedIn || isConnected) return null;

  return (
    <Dialog open={true}>
      <DialogTitle>Reconnecting</DialogTitle>
      <DialogContent>
        <p>We are trying to reconnect. Please wait...</p>
        <Paper sx={{ p: 2 }}>
          You are disconnected from the server. If you have more than 3 wowlfg
          tabs, close the other tabs and refresh this page. If you do not have
          more than 3 wowlfg tabs and this issue continues, clear your cookies
          and website data and refresh this page.
        </Paper>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          {countdown > 0 && <p>Reconnect manually in {countdown} seconds...</p>}
          {countdown <= 0 && (
            <Box>
              <CooldownButton
                cooldownSeconds={20}
                onClick={() => dispatch({ type: 'login/check' })}
                variant="contained"
                sx={{ mr: 1 }}
              >
                Reconnect Manually
              </CooldownButton>
            </Box>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ReconnectingOverlay;
