import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllReviews, ReviewItem } from 'src/models/review';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'reviews',
  storage: storage
  // whitelist: ['isConnected'], // If you only want to persist specific parts of the state
};
const initialState: AllReviews = {};

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    reset: (state) => {
      for (const key in state) {
        if (!(key in initialState)) {
          delete state[key];
        }
      }
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    update: (state, action: PayloadAction<AllReviews>) => {
      for (const key in action.payload) {
        if (Object.prototype.hasOwnProperty.call(action.payload, key)) {
          state[key] = action.payload[key];
        }
      }
    },
    addReview: (state, action: PayloadAction<ReviewItem>) => {
      const index = state?.[action.payload.Type]?.Reviews.findIndex(
        (review) =>
          review.ServiceID === action.payload.ServiceID &&
          review.ReviewerID === action.payload.ReviewerID
      );
      if (index !== undefined && index > -1) {
        state[action.payload.Type].Reviews[index] = action.payload;
      } else {
        state[action.payload.Type].Reviews.unshift(action.payload);
      }
    },
    removeReview: (state, action: PayloadAction<ReviewItem>) => {
      const index = state?.[action.payload.Type]?.Reviews.findIndex(
        (review) =>
          review.ServiceID === action.payload.ServiceID &&
          review.ReviewerID === action.payload.ReviewerID
      );
      if (index !== undefined && index > -1) {
        state[action.payload.Type].Reviews.splice(index, 1);
      }
    }
  }
});

export const { reset, update, addReview, removeReview } = reviewsSlice.actions;
const persistedReducer = persistReducer(persistConfig, reviewsSlice.reducer);
export default persistedReducer;
