export const askForNotificationPermission = async () => {
  const permission = await Notification.requestPermission();
  if (permission !== 'granted') {
    console.error('Notification permission not granted');
  }
};

export const sendNotification = (
  title: string,
  options?: NotificationOptions,
  sound?: string,
  soundVolume?: number,
  windowsNotif?: boolean,
  icon?: string
) => {
  if (!('Notification' in window)) {
    console.error('This browser does not support notifications.');
  } else if (windowsNotif && Notification.permission === 'granted') {
    new Notification(title, {
      ...options,
      icon: icon || '/static/images/logo/icon-192x192.png'
    });
  }
  if (sound) {
    const audio = new Audio(sound);
    audio.volume = soundVolume === undefined ? 1 : soundVolume;
    audio.play();
  }
};

export const checkNotificationPermission = async (name: string) => {
  if (name == 'windowsNotif') {
    if (!('Notification' in window)) {
      alert('This browser does not support notifications.');
      return false;
    }
    if (!(Notification.permission === 'granted')) {
      await askForNotificationPermission();
      alert(
        'You need to allow notifications to use this feature. To enable it in chrome go to site settings (between url and refresh) and allow notifications.'
      );
      return false;
    }
  }
  return true;
};
