import React, { useEffect, useRef, useState } from 'react';
import {
  Snackbar,
  Alert,
  IconButton,
  Slide,
  useTheme,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { renderMarkdownContent } from '../Utility/RenderMarkdown';
import { announcement } from 'src/models/announcement';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/app/store';

const StackedAnnouncements = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const announcements = useSelector(
    (state: RootState) => state.announcements.announcements
  );
  const openSnacks = Object.values(announcements).filter(
    (announcement) => !announcement.read
  );
  const [offsets, setOffsets] = useState<number[]>([]);
  const snackRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleClose = (_id: string) => {
    dispatch({ type: 'announcements/markRead', payload: _id });
  };

  useEffect(() => {
    const headerHeight = parseInt(theme.header.height, 10);
    const newOffsets = snackRefs.current.map(
      (ref, index) =>
        snackRefs.current
          .slice(0, index)
          .reduce((acc, curr) => acc + (curr?.clientHeight || 0) + 8, 0) +
        headerHeight +
        8
    );
    setOffsets(newOffsets);
  }, [openSnacks.length]);

  return (
    <>
      {openSnacks.map((snack, index) => (
        <Snackbar
          key={snack._id}
          open={true}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          style={{ top: offsets[index] }}
          sx={{
            '& .MuiIconButton-root': {
              color: theme.palette.getContrastText(theme.palette.primary.main)
            }
          }}
        >
          <Alert
            ref={(el) => (snackRefs.current[index] = el)}
            icon={false}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => handleClose(snack._id)}
              >
                <CloseIcon />
              </IconButton>
            }
            sx={{
              display: 'flex',
              alignItems: 'center',
              bgcolor: theme.palette.primary.dark,
              color: theme.palette.getContrastText(theme.palette.primary.dark),
              boxShadow: 1,
              borderRadius: 2
            }}
          >
            {snack.title && (
              <Typography variant="h3" textAlign={'center'} color={'inherit'}>
                {snack.title}
              </Typography>
            )}
            <Typography variant="body1" textAlign={'center'} fontSize={16}>
              {snack.description}
            </Typography>
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};

export default StackedAnnouncements;
