import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { mongoUpdate } from './mongoUpdate';
import {
  WeekdaysStatistics,
  LevelsStatistics,
  RoleClassStatistics,
  RealmsStatistics
} from 'src/models/statistics';

const persistConfig = {
  key: 'statistics',
  storage: storage
};

interface statisticsProps {
  Weekdays: WeekdaysStatistics;
  Levels: LevelsStatistics;
  RoleClass: RoleClassStatistics;
  Realms: RealmsStatistics;
}
const initialState: statisticsProps = {
  Weekdays: {
    Weekdays: {
      Runs: {},
      Offers: {}
    },
    LastUpdated: 0
  },
  Levels: {
    Levels: {
      Runs: {},
      Offers: {}
    },
    LastUpdated: 0
  },
  RoleClass: {
    Tank: {
      Runs: {},
      Offers: {}
    },
    Healer: {
      Runs: {},
      Offers: {}
    },
    DPS: {
      Runs: {},
      Offers: {}
    },
    LastUpdated: 0
  },
  Realms: {
    Realms: {
      Runs: {},
      Offers: {}
    },
    LastUpdated: 0
  }
};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    reset: (state) => {
      for (const key in state) {
        if (!(key in initialState)) {
          delete state[key];
        }
      }
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    update: (
      state,
      action: PayloadAction<{
        Weekdays?: WeekdaysStatistics;
        Levels?: LevelsStatistics;
        RoleClass?: RoleClassStatistics;
        Realms?: RealmsStatistics;
      }>
    ) => {
      for (const key in action.payload) {
        if (Object.prototype.hasOwnProperty.call(action.payload, key)) {
          state[key] = action.payload[key];
        }
      }
    },
    mongodbUpdate: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state = mongoUpdate(state, action.payload);
    }
  }
});

export const { reset, update, mongodbUpdate } = statisticsSlice.actions;
const persistedReducer = persistReducer(persistConfig, statisticsSlice.reducer);
export default persistedReducer;
