import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer, createTransform, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Message } from 'src/components/Utility/DiscordEmbed';

// Create a transformation for the socket reducer
const socketTransform = createTransform(
  // Transform state going into storage (on its way to being persisted)
  (inboundState: SocketState) => {
    const { isConnected, ...otherProps } = inboundState;
    return otherProps; // Persist everything except isConnected
  },
  // Transform state coming out of storage (on its way to being rehydrated)
  (outboundState: Omit<SocketState, 'isConnected'>) => {
    return {
      ...outboundState,
      isConnected: false // Default values for session-specific data
    };
  },
  {
    whitelist: ['socket'] // Apply the transformation only to the socket reducer
  }
);

const migrations = {
  1: (state) => {
    return {
      ...state,
      socket: {
        ...state.socket,
        isConnected: false
      }
    };
  }
};

const persistConfig = {
  key: 'socket',
  storage: storage,
  version: 1,
  migrate: createMigrate(migrations, {
    debug: process.env.NODE_ENV === 'development'
  }),
  transforms: [socketTransform]
};

interface SocketState {
  isConnected: boolean;
  popMessage?: Message;
  popUpShowned?: boolean;
  rooms?: string[];
}

const initialState: SocketState = {
  isConnected: false,
  popUpShowned: true,
  rooms: []
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    connected: (state) => {
      state.isConnected = true;
    },
    disconnected: (state) => {
      state.isConnected = false;
    },
    recieveMessage: (state, action: PayloadAction<Message>) => {
      state.popMessage = action.payload;
      state.popUpShowned = false;
    },
    acknowledgeMessage: (state) => {
      state.popMessage = undefined;
      state.popUpShowned = true;
    },
    joinRoom: (state, action: PayloadAction<string>) => {
      if (!state.rooms?.includes(action.payload)) {
        state.rooms?.push(action.payload);
      }
    },
    leaveRoom: (state, action: PayloadAction<string>) => {
      state.rooms = state.rooms?.filter((room) => room !== action.payload);
    },
    resetRooms: (state) => {
      state.rooms = [];
    }
  }
});

export const {
  connected,
  disconnected,
  recieveMessage,
  acknowledgeMessage,
  joinRoom,
  leaveRoom,
  resetRooms
} = socketSlice.actions;

const persistedReducer = persistReducer(persistConfig, socketSlice.reducer);

export default persistedReducer;
