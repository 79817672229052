import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography, TypographyProps, useTheme } from '@mui/material';
import Text from 'src/components/Text';
import { EmojiAvatar } from '../EmojiAvatar';
import React from 'react';
import { DiscordDetails } from 'src/models/user';

function CreditibilityString(
  index: number,
  typographyProps: TypographyProps,
  theme
) {
  switch (index) {
    case 0:
      return (
        <Box display={'flex'}>
          <EmojiAvatar variant="square" color={theme.colors.error.main}>
            <PriorityHighIcon />
          </EmojiAvatar>
          <Typography {...typographyProps}>
            <Text color="error">High Risk</Text>
          </Typography>
        </Box>
      );
    case 1:
      return (
        <Box display={'flex'}>
          <EmojiAvatar variant="square" color={theme.colors.info.main}>
            <WarningIcon />
          </EmojiAvatar>
          <Typography {...typographyProps}>
            <Text color="info">Medium Risk</Text>
          </Typography>
        </Box>
      );
    case 2:
      return (
        <Box display={'flex'}>
          <EmojiAvatar variant="square" color={theme.colors.success.main}>
            <CheckCircleIcon />
          </EmojiAvatar>
          <Typography {...typographyProps}>
            <Text color="success">Low Risk</Text>
          </Typography>
        </Box>
      );
  }
}

export interface CreditibilityProps extends TypographyProps {
  index: number;
  reviewString: string;
  discordDetails: DiscordDetails;
}

export const Creditibility = ({
  index,
  reviewString,
  discordDetails,
  ...typographyProps
}: CreditibilityProps) => {
  const theme = useTheme();
  const karma = discordDetails?.Karma || 0;
  return (
    <React.Fragment>
      <Typography {...typographyProps}>{reviewString}</Typography>
      <Typography {...typographyProps}>
        <Text color={karma > 100 ? 'success' : karma < 0 ? 'error' : 'warning'}>
          {karma > 0 && '+'}
          {karma} Karma
        </Text>
      </Typography>
      {CreditibilityString(index, typographyProps, theme)}
    </React.Fragment>
  );
};

export default Creditibility;
