import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  ListItem,
  Typography,
  List,
  ListItemAvatar,
  ListItemText,
  Paper,
  ListItemButton,
  Collapse
} from '@mui/material';
import { getUser } from 'src/models/user';
import React from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Text from 'src/components/Text';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { useDispatch } from 'react-redux';
import AutoSignupStatus from '../AutoSignupStatus';

const Group = () => {
  const user = getUser();
  const dispatch = useDispatch();
  const [chosenMember, setChosenMember] = useState<string>(undefined);
  const [open, setOpen] = useState<boolean>(true);

  const handleKick = (id: string) => {
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'group_kick',
        data: { DiscordID: id }
      }
    });
  };

  const handleLeave = () => {
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'group_leave'
      }
    });
  };

  const handlePromote = (id: string) => {
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'group_promote',
        data: { DiscordID: id }
      }
    });
  };

  return (
    <Paper>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography
          textAlign={'center'}
          variant="h4"
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          Current Team
        </Typography>
        <Button onClick={() => setOpen(!open)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
      </Box>
      <Collapse in={open}>
        <AutoSignupStatus user={user} type="team" />
        <List dense sx={{ mx: -1 }}>
          {user?.CurrentGroup?.Members?.map((member) => (
            <React.Fragment key={member}>
              <ListItemButton
                onClick={() => {
                  if (chosenMember === member) {
                    setChosenMember(undefined);
                  } else setChosenMember(member);
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    src={user?.CachedDiscordDetails?.[member]?.AvatarURL}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant="h5">
                    {user?.CachedDiscordDetails?.[member]?.Username}
                  </Typography>
                </ListItemText>
                {chosenMember === member &&
                (member === user?._id ||
                  user?.CurrentGroup.Lead === user?._id) ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItemButton>
              <Collapse
                in={
                  chosenMember === member &&
                  (member === user?._id ||
                    user?.CurrentGroup.Lead === user?._id)
                }
              >
                {member === user?._id ? (
                  <List>
                    <ListItemButton onClick={() => handleLeave()}>
                      <ListItemText>
                        <Typography
                          variant="h5"
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <ExitToAppIcon color="error" sx={{ mr: 0.5 }} />
                          <Text color="error">Leave</Text>
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </List>
                ) : (
                  <List>
                    <ListItemButton onClick={() => handleKick(member)}>
                      <ListItemText>
                        <Typography
                          variant="h5"
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <NoAccountsIcon color="error" sx={{ mr: 0.5 }} />
                          <Text color="error">Kick</Text>
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => handlePromote(member)}>
                      <ListItemText>
                        <Typography
                          variant="h5"
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <StarPurple500Icon color="info" sx={{ mr: 0.5 }} />
                          <Text color="info">Promote</Text>
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </List>
                )}
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </Collapse>
    </Paper>
  );
};

export default Group;
