import { Middleware } from 'redux';

const averageStatsMiddleware: Middleware = (storeAPI) => {
  return (next) => async (action) => {
    switch (action.type) {
      default:
        break;
    }

    return next(action);
  };
};

export default averageStatsMiddleware;
