import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import { DetailsField } from './fields';
import StarIcon from '@mui/icons-material/Star';
import BalanceIcon from '@mui/icons-material/Balance';

export const MegaDungeonDetailsFields: DetailsField[] = [
  {
    key: 'HardMode',
    emoji: <CrisisAlertIcon />,
    emojiColor: 'success.dark',
    textFunction: (hardmode) => (hardmode ? 'Hard Mode' : 'Normal Mode'),
    textFunctionArgs: ['HardMode']
  },
  {
    key: 'Unsaved',
    emoji: <CheckroomIcon />,
    emojiColor: 'primary.main',
    textFunction: (unsaved) => (unsaved ? 'Unsaved' : 'Saved'),
    textFunctionArgs: ['Unsaved']
  }
];

export const MegaDungeonRequirementsFields: DetailsField[] = [
  {
    key: 'MinimumIO',
    emoji: '/static/images/WoW/Logo/Rio.png',
    append: ' IO'
  },
  {
    key: 'MinimumIlvl',
    emoji: '/static/images/WoW/Logo/wow.png',
    append: ' ilvl Every Slot'
  },
  {
    key: 'MinimumRating',
    emoji: <StarIcon />,
    emojiColor: 'warning.main',
    append: '/10 Rating'
  },
  {
    key: 'MinimumKarma',
    emoji: <BalanceIcon />,
    emojiColor: 'info.main',
    append: ' Karma'
  }
];
