import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Card,
  Paper
} from '@mui/material';
import { AppDispatch, RootState } from 'src/app/store';
import { useDispatch, useSelector } from 'react-redux';
import { renderMarkdownContent } from './RenderMarkdown';

interface EmbedField {
  name: string;
  value: string;
  inline: boolean;
}

interface EmbedFooter {
  text: string;
  icon_url?: string;
}

interface Embed {
  title?: string;
  description?: string;
  color?: number;
  fields?: EmbedField[];
  footer?: EmbedFooter;
}

export interface Message {
  type: 'popup';
  content?: string;
  embeds?: Embed[]; // Adjust this to be an array of Embeds
}

const SocketPopup = () => {
  const [open, setOpen] = useState(true);
  const dispatch: AppDispatch = useDispatch();
  const { popMessage, popUpShowned } = useSelector(
    (state: RootState) => state.socket
  );

  useEffect(() => {
    if (popUpShowned || !popMessage) {
      return;
    }
    if (!popUpShowned && popMessage) {
      setOpen(true);
    }
  }, [popMessage]);

  const handleClose = () => {
    dispatch({ type: 'socket/Message/acknowledge' });
    setOpen(false);
  };

  if (popUpShowned) {
    return null;
  }

  const numberToHexColor = (color: number) => {
    return `#${color.toString(16).padStart(6, '0')}`;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{ whiteSpace: 'pre-line' }}
    >
      <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
        {popMessage.content && (
          <Box>
            <DialogContent>
              {renderMarkdownContent(popMessage.content)}
            </DialogContent>
          </Box>
        )}

        {popMessage.embeds &&
          popMessage.embeds.map((embed, embedIdx) => (
            <Card
              key={embedIdx}
              sx={{
                borderLeftColor: embed.color
                  ? numberToHexColor(embed.color)
                  : 'transparent',
                borderLeftWidth: '4px',
                borderLeftStyle: 'solid',
                borderTopWidth: '0',
                borderBottomWidth: '0',
                borderRightWidth: '0',
                borderRadius: '4px',
                padding: 1,
                margin: 1
              }}
            >
              {embed.title && (
                <DialogTitle>{renderMarkdownContent(embed.title)}</DialogTitle>
              )}
              <DialogContent>
                {embed.description && (
                  <Typography>
                    {renderMarkdownContent(embed.description)}
                  </Typography>
                )}
                {embed.fields && (
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                  >
                    {(() => {
                      const { allRows, currentRow } = embed.fields.reduce(
                        (acc, field, fieldIdx) => {
                          if (field.inline) {
                            acc.currentRow.push(
                              <Box
                                key={fieldIdx}
                                sx={{
                                  flex: '1 1 calc(33.33% - 16px)',
                                  minWidth: 0
                                }}
                              >
                                <Typography>
                                  <strong>
                                    {renderMarkdownContent(field.name)}
                                  </strong>
                                  {renderMarkdownContent(field.value)}
                                </Typography>
                              </Box>
                            );
                            if (acc.currentRow.length === 3) {
                              acc.allRows.push(
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                  {acc.currentRow}
                                </Box>
                              ); // Use gap instead of margin for spacing between items
                              acc.currentRow = [];
                            }
                          } else {
                            if (acc.currentRow.length) {
                              acc.allRows.push(
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                  {acc.currentRow}
                                </Box>
                              );
                              acc.currentRow = [];
                            }
                            acc.allRows.push(
                              <Typography key={fieldIdx} sx={{ width: '100%' }}>
                                <strong>
                                  {renderMarkdownContent(field.name)}
                                </strong>
                                {renderMarkdownContent(field.value)}
                              </Typography>
                            );
                          }
                          return acc;
                        },
                        { allRows: [], currentRow: [] }
                      );

                      if (currentRow && currentRow.length) {
                        allRows.push(
                          <Box sx={{ display: 'flex', gap: 2 }} key="leftovers">
                            {currentRow}
                          </Box>
                        );
                      }

                      return allRows;
                    })()}
                  </Box>
                )}
              </DialogContent>
            </Card>
          ))}
      </Box>
    </Dialog>
  );
};

export default SocketPopup;
