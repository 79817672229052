export interface PremiumGift {
  Tier: number;
  Duration: number;
  Unit: 'Days' | 'Weeks' | 'Months' | 'Years';
  SendingTime?: number;
  GifterNote?: string;
  ClaimerID?: number;
}

export interface PremiumStatus {
  StripeID?: string;
  Tier?: number;
  Expires?: number;
  NextCheck?: number;
  Forced?: boolean;
  DiscordSubbed?: boolean;
  PremiumAppeals?: number[];
  Currency?: string;
  Gifts?: { [key: string]: PremiumGift };
  TrialUsed?: boolean;
}

export interface PremiumPerk {
  Name: string;
  Description: string;
  Icon?: string;
}

export interface PremiumPrice {
  Currency: string;
  Price: number;
  Name: string;
  Metadata: { [key: string]: string | number };
}

type PaymentOption = 'Stripe' | 'PayPal' | 'Both';

export interface PremiumInfo {
  Name: string;
  Description: string;
  PaymentOptions: PaymentOption[];
  IsSubscription?: boolean;
  Prices: PremiumPrice[];
  Perks?: PremiumPerk[];
  Giftable?: boolean;
}

export function getPremium() {
  return {
    StripeID: 'cus_NSRPlKsIJL6lUY',
    Tier: 3,
    Expires: 1694198336,
    NextCheck: 0,
    Forced: false,
    PremiumAppeals: [3, 1691265503],
    Currency: 'usd',
    Gifts: {},
    TrialUsed: true
  } as PremiumStatus;
}
