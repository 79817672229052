import { FC, useState, createContext } from 'react';
type FriendlistContext = {
  friendlistToggle: any;
  toggleFriendlist: () => void;
  closeFriendlist: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FriendlistContext = createContext<FriendlistContext>(
  {} as FriendlistContext
);

export const FriendlistProvider: FC = ({ children }) => {
  const [friendlistToggle, setFriendlistToggle] = useState(false);
  const toggleFriendlist = () => {
    setFriendlistToggle(!friendlistToggle);
  };
  const closeFriendlist = () => {
    setFriendlistToggle(false);
  };

  return (
    <FriendlistContext.Provider
      value={{
        friendlistToggle: friendlistToggle,
        toggleFriendlist,
        closeFriendlist
      }}
    >
      {children}
    </FriendlistContext.Provider>
  );
};
