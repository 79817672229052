import {
  InputAdornment,
  TextField,
  List,
  ListItem,
  ListItemText,
  Paper,
  Box,
  Dialog,
  ListItemAvatar,
  Avatar,
  ListItemButton
} from '@mui/material';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import { getUser } from 'src/models/user';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import { ProfilePopUp } from 'src/components/WoW/Profile';

function debounce(func, delay) {
  let debounceTimer;
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}

function useClickOutside(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [ref, callback]);
}

function UserSearch({ onSelectUser }) {
  const dispatch = useDispatch();
  const [input, setInput] = useState('');
  const lastRequestValue = useRef('');
  const listRef = useRef(null);
  const containerRef = useRef(null);
  const resultsCache = useRef([]);
  const CachedDiscordDetails = getUser()?.CachedDiscordDetails || {};
  const cachedMatched = Object.values(CachedDiscordDetails)
    .filter((user) => user.Username.toLowerCase().includes(input.toLowerCase()))
    .slice(0, 25);

  const handleSearch = debounce((value) => {
    if (value !== lastRequestValue.current && value.length >= 3) {
      if (!resultsCache.current.includes(value) && cachedMatched.length < 5) {
        dispatch({
          type: 'socket/Message/send',
          payload: {
            type: 'user_auto_complete',
            data: { input: value }
          }
        });
        resultsCache.current.push(value);
      }
    }
  }, 1000);

  const handleInputChange = ({ target: { value } }) => {
    setInput(value);
    handleSearch(value);
  };

  const handleUserClick = (username: string) => {
    const [chosenID, chosenUser] = Object.entries(CachedDiscordDetails).find(
      ([_, user]) => user.Username === username
    );
    setShowList(false);
    setInput(username);
    onSelectUser(chosenID);
  };

  const [showList, setShowList] = useState(false);

  const handleListInteraction = () => {
    setShowList(true);
  };

  const handleListClose = (e) => {
    if (listRef.current && listRef.current.contains(e?.relatedTarget)) {
      return;
    }
    setShowList(false);
  };

  useClickOutside(containerRef, handleListClose);

  return (
    <Box sx={{ position: 'relative' }} ref={containerRef}>
      <TextField
        fullWidth
        onChange={handleInputChange}
        variant="filled"
        label="Search Users"
        placeholder="Search Users"
        value={input}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
        onFocus={handleListInteraction}
        onBlur={handleListClose}
      />
      {showList && input && (
        <Paper
          ref={listRef}
          style={{
            position: 'absolute',
            top: '100%',
            width: '100%',
            maxHeight: '300px',
            overflow: 'auto',
            zIndex: 1
          }}
        >
          <List>
            {cachedMatched.map((user) => (
              <ListItemButton
                key={user.Username}
                onClick={() => handleUserClick(user.Username)}
              >
                <ListItemAvatar>
                  <Avatar src={user.AvatarURL} />
                </ListItemAvatar>
                <ListItemText primary={user.Username} />
              </ListItemButton>
            ))}
          </List>
        </Paper>
      )}
    </Box>
  );
}

export default UserSearch;
