import React, { createContext, useContext, useState, useEffect } from 'react';
import useUserActivity from 'src/hooks/useUserActivity';

const UserActivityContext = createContext({ userStatus: 'active' });

export const UserActivityProvider = ({ children }) => {
  const userStatus = useUserActivity();

  return (
    <UserActivityContext.Provider value={{ userStatus }}>
      {children}
    </UserActivityContext.Provider>
  );
};

export const useUserActivityStatus = () => useContext(UserActivityContext);
