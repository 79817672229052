import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

const generateTimeSlots = () => {
  const slots = [];
  for (let i = 0; i < 24; i++) {
    slots.push(`${String(i).padStart(2, '0')}:00`);
    slots.push(`${String(i).padStart(2, '0')}:30`);
  }
  return slots;
};

const parseTime = (time: string): { hour: number; minute: number } => {
  const [hour, minute] = time.split(':').map(Number);
  return { hour, minute };
};

type TimeSlot = { hour: number; minute: number };
type GroupedByDay = { [day: string]: TimeSlot[] };

const getSelectedSlotsDescription = (
  selectedSlots: [number, number, number][]
) => {
  if (!selectedSlots || selectedSlots.length === 0) return 'Select Timeslots*';

  // Group selected slots by day
  const groupedByDay = selectedSlots
    ? selectedSlots.reduce<GroupedByDay>((acc, [day, hour, minute]) => {
        if (!acc[day]) {
          acc[day] = [];
        }
        acc[day].push({ hour, minute });
        return acc;
      }, {})
    : {};

  // Function to detect periodicity in the time slots
  const detectPeriodicity = (times: TimeSlot[]): string | null => {
    if (times.length < 4) return null;

    const intervals = times.slice(1).map((time, i) => {
      const prevTime = times[i];
      return (
        time.hour * 60 + time.minute - (prevTime.hour * 60 + prevTime.minute)
      );
    });

    const uniqueIntervals = [...new Set(intervals)];
    if (uniqueIntervals.length === 1) {
      const interval = uniqueIntervals[0];
      if (interval === 60) return 'every hour';
      if (interval === 30) return 'every half hour';
      if (interval % 60 === 0) return `every ${interval / 60} hours`;
      if (interval % 30 === 0) return `every ${(interval / 30) * 0.5} hours`;
    }

    return null;
  };

  // Generate descriptions for each day
  const descriptions = Object.entries(groupedByDay).map(([day, times]) => {
    times.sort((a, b) => a.hour - b.hour || a.minute - b.minute); // Sort times

    const periodicity = detectPeriodicity(times);

    if (periodicity) {
      const start = times[0];
      const end = times[times.length - 1];
      return `${daysOfWeek[day]}: ${String(start.hour).padStart(
        2,
        '0'
      )}:${String(start.minute).padStart(2, '0')} to ${String(
        end.hour
      ).padStart(2, '0')}:${String(end.minute).padStart(
        2,
        '0'
      )} ${periodicity}`;
    } else {
      const timeStrings = times.map(
        ({ hour, minute }) =>
          `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`
      );
      return `${daysOfWeek[day]}: ${timeStrings.join(', ')}`;
    }
  });

  return descriptions
    ? `ALL US EST\n${descriptions.join('\n')}`
    : 'No dates selected';
};

interface TimeSlotSelectorProps {
  selectedSlots: [number, number, number][];
  onSelectedSlotsChange: (selectedSlots: [number, number, number][]) => void;
}

const TimeSlotSelector = ({
  selectedSlots,
  onSelectedSlotsChange
}: TimeSlotSelectorProps) => {
  const handleSlotClick = (day: number, time: string) => {
    const { hour, minute } = parseTime(time);
    const slotIndex = selectedSlots
      ? selectedSlots.findIndex(
          ([d, h, m]) => d === day && h === hour && m === minute
        )
      : -1;

    if (slotIndex >= 0) {
      // If the slot is already selected, remove it
      onSelectedSlotsChange(
        selectedSlots.filter((_, index) => index !== slotIndex)
      );
    } else {
      // Otherwise, add it
      onSelectedSlotsChange([...(selectedSlots ?? []), [day, hour, minute]]);
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: '#2c2c3a', color: 'white' }}
      >
        <Typography
          variant="h6"
          whiteSpace={{ whiteSpace: 'pre-line' }}
          color={selectedSlots.length === 0 ? 'error' : 'inherit'}
        >
          {getSelectedSlotsDescription(selectedSlots)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: '#2c2c3a' }}>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table
            size="small"
            stickyHeader
            sx={{
              minWidth: 350,
              backgroundColor: '#1c1c2a',
              tableLayout: 'fixed'
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: '60px',
                    p: 0.5,
                    textAlign: 'center',
                    position: 'sticky',
                    left: 0,
                    zIndex: 1,
                    backgroundColor: '#2c2c3a'
                  }}
                />
                {daysOfWeek.map((day) => (
                  <TableCell
                    key={day}
                    align="center"
                    sx={{
                      p: 0.5,
                      textAlign: 'center',
                      fontSize: '0.75rem',
                      width: 'auto',
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: '#2c2c3a'
                    }}
                  >
                    <Typography color="white" variant="body2">
                      {day.slice(0, 3)}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {generateTimeSlots().map((time) => {
                const { hour, minute } = parseTime(time);
                return (
                  <TableRow key={time}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        p: 0.5,
                        textAlign: 'center',
                        fontSize: '0.75rem',
                        width: '60px',
                        position: 'sticky',
                        left: 0,
                        zIndex: 1,
                        backgroundColor: '#2c2c3a'
                      }}
                    >
                      <Typography color="white" variant="body2">
                        {time}
                      </Typography>
                    </TableCell>
                    {daysOfWeek.map((day, dayIndex) => {
                      const isSelected =
                        selectedSlots &&
                        selectedSlots.some(
                          ([d, h, m]) =>
                            d === dayIndex && h === hour && m === minute
                        );

                      return (
                        <TableCell
                          key={day + time}
                          align="center"
                          onClick={() => handleSlotClick(dayIndex, time)}
                          sx={{
                            p: 0.5,
                            height: '30px',
                            backgroundColor: isSelected ? 'green' : '#2c2c3a',
                            color: isSelected ? '#ffffff' : '#c8c8ff',
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: isSelected
                                ? '#5a5a6a'
                                : '#3a3a4a'
                            },
                            fontSize: '0.75rem',
                            width: 'auto'
                          }}
                        >
                          {isSelected ? '✓' : ''}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default TimeSlotSelector;
