import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'updateResponse',
  storage: storage
};
const initialState = {
  success: false,
  message: '',
  date: new Date(),
  showned: false
};

export const updateResponseSlice = createSlice({
  name: 'updateResponse',
  initialState,
  reducers: {
    reset: (state) => {
      for (const key in state) {
        if (!(key in initialState)) {
          delete state[key];
        }
      }
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    update: (
      state,
      action: PayloadAction<{ success: boolean; message: string }>
    ) => {
      state.success = action.payload.success;
      state.message = action.payload.message;
      state.date = new Date();
      state.showned = false;
    },
    acknowledge: (state) => {
      state.success = false;
      state.message = '';
      state.showned = true;
    }
  }
});

export const { reset, update, acknowledge } = updateResponseSlice.actions;
const persistedReducer = persistReducer(
  persistConfig,
  updateResponseSlice.reducer
);
export default persistedReducer;
