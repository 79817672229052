import { useState, SyntheticEvent } from 'react';
import {
  Box,
  IconButton,
  Tooltip,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  styled,
  useTheme,
  AvatarGroup
} from '@mui/material';
import { formatDistance, subMinutes } from 'date-fns';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import VideoCameraFrontTwoToneIcon from '@mui/icons-material/VideoCameraFrontTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ColorLensTwoToneIcon from '@mui/icons-material/ColorLensTwoTone';
import NotificationsOffTwoToneIcon from '@mui/icons-material/NotificationsOffTwoTone';
import EmojiEmotionsTwoToneIcon from '@mui/icons-material/EmojiEmotionsTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import Text from 'src/components/Text';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import { Message } from 'src/models/message';
import { DiscordDetails, getUser } from 'src/models/user';
import { ProfilePopUp } from 'src/components/WoW/Profile';

const RootWrapper = styled(Box)(
  ({ theme }) => `
        @media  {
          display: flex;
          align-items: center;
          justify-content: space-between;
      }
`
);

const ListItemIconWrapper = styled(ListItemIcon)(
  ({ theme }) => `
        min-width: 36px;
        color: ${theme.colors.primary.light};
`
);

const AccordionSummaryWrapper = styled(AccordionSummary)(
  ({ theme }) => `
        &.Mui-expanded {
          min-height: 48px;
        }

        .MuiAccordionSummary-content.Mui-expanded {
          margin: 12px 0;
        }

        .MuiSvgIcon-root {
          transition: ${theme.transitions.create(['color'])};
        }

        &.MuiButtonBase-root {

          margin-bottom: ${theme.spacing(0.5)};

          &:last-child {
            margin-bottom: 0;
          }

          &.Mui-expanded,
          &:hover {
            background: ${theme.colors.alpha.black[10]};

            .MuiSvgIcon-root {
              color: ${theme.colors.primary.main};
            }
          }
        }
`
);

interface TopBarProps {
  Messages: Message[];
  MessagesType: 'Direct' | 'Group';
  ID: string;
  ChatName?: string;
  ChatPictureURL?: string;
}

function TopBarContent({ Messages, MessagesType, ID, ChatName }: TopBarProps) {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const notifications = useSelector(
    (state: RootState) => state.notifications.messages
  );
  const mute = notifications?.MutedIDs?.includes(ID);
  const uniqueSenders = new Set(Messages?.map((message) => message.Sender));
  const uniqueSendersArray = Array.from(uniqueSenders);
  const user = getUser();
  const sendersDiscordDetails: { [key: string]: DiscordDetails } =
    Object.fromEntries(
      uniqueSendersArray.map((sender) => [
        sender,
        user?.CachedDiscordDetails?.[sender]
      ])
    );
  const [openProfile, setOpenProfile] = useState<string>(undefined);

  const handleMuteChange = () => {
    dispatch({
      type: 'notifications/mute',
      payload: { id: ID, muted: !mute }
    });
  };

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [expanded, setExpanded] = useState<string | false>('section1');

  const handleChange =
    (section: string) => (_event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? section : false);
    };

  return (
    <>
      <RootWrapper>
        <Box display="flex" alignItems="center">
          <AvatarGroup>
            {Object.entries(sendersDiscordDetails).map(([ID, sender]) => (
              <Tooltip
                key={ID}
                arrow
                title={`View profile for ${sender?.Username}`}
              >
                <Avatar
                  sx={{
                    width: 28,
                    height: 28
                  }}
                  alt={sender?.Username}
                  src={sender?.AvatarURL}
                  onClick={() => setOpenProfile(ID)}
                />
              </Tooltip>
            ))}
          </AvatarGroup>
          <Box ml={1}>
            <Typography variant="h4">
              {ChatName
                ? ChatName
                : MessagesType === 'Direct'
                ? 'Direct Chat'
                : 'Group Chat'}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex'
          }}
        >
          <Tooltip
            placement="bottom"
            title={mute ? 'Unmute this conversation' : 'Mute this conversation'}
          >
            <IconButton
              sx={{
                color: mute ? 'error.main' : 'success.main'
              }}
              onClick={() => {
                handleMuteChange();
              }}
            >
              {mute ? <NotificationsOffIcon /> : <NotificationsActiveIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </RootWrapper>
      <ProfilePopUp
        id={openProfile}
        discordDetails={
          openProfile
            ? Object.entries(user?.CachedDiscordDetails).find(
                ([id, user]) => id === openProfile
              )?.[1]
            : undefined
        }
        onClose={() => setOpenProfile(undefined)}
      />
    </>
  );
}

export default TopBarContent;
