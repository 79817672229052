import { Box, Typography, TypographyProps } from '@mui/material';
import Text from 'src/components/Text';

export interface TitleProps extends Omit<TypographyProps, 'color'> {
  OfferDetails: string;
  TextColor?:
    | 'warning'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'black';
}
export const Title = ({
  OfferDetails,
  TextColor,
  ...typographyProps
}: TitleProps) => {
  return (
    <Box display={'inline-flex'}>
      <Typography {...typographyProps}>
        <Text color={TextColor}>{OfferDetails}</Text>
      </Typography>
    </Box>
  );
};

export default Title;
