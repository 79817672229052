import {
  Modal,
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  Card,
  Dialog,
  Avatar,
  useMediaQuery,
  useTheme,
  InputLabel,
  FormControl,
  Select,
  FormHelperText,
  CircularProgress
} from '@mui/material';
import Text from 'src/components/Text';
import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from 'src/app/store';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewItem } from 'src/models/review';
import { LFSType } from 'src/models/offer';
import { Classes, WoWRoles } from 'src/models/wowclasses';
import { Offers } from '../Offers';
import { Character } from 'src/models/character';
import { getTimeOffset } from 'src/models/client';
import React from 'react';
import PicturesUpload from 'src/components/Utility/PicturesUpload';
import { convertToBase64 } from 'src/components/Utility/ConvertPictureToBase64';

export interface InviteModalProps {
  review: ReviewItem;
}

export const InviteModal = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const timeOffset = getTimeOffset();
  const invitedSignup = useSelector(
    (state: RootState) => state.offer.invitedSignup
  );
  const offerID = invitedSignup ? invitedSignup[0] : undefined;
  const signUp =
    invitedSignup && invitedSignup.length > 1 ? invitedSignup[1] : undefined;
  const expiry =
    invitedSignup && invitedSignup.length > 2 ? invitedSignup[2] : undefined;
  const dispatch: AppDispatch = useDispatch();
  const activeOfferSelector = (state: RootState) => {
    if (offerID) {
      return state.offer.activeOffers[offerID] as LFSType;
    }
    return null;
  };
  const offer = useSelector(activeOfferSelector);
  const [paymentCharacter, setPaymentCharacter] = useState<Character>(null);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const [images, setImages] = useState([]);

  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [totalTime, setTotalTime] = useState<number | null>(null);
  const [completed, setCompleted] = useState(0);
  useEffect(() => {
    setTotalTime(
      expiry ? Math.floor(expiry - (Date.now() - timeOffset) / 1000) : null
    );
    setTimeLeft(
      expiry ? Math.floor(expiry - (Date.now() - timeOffset) / 1000) : null
    );
  }, [expiry]);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (timeLeft !== null && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime !== null && prevTime > 1) {
            const progress = (prevTime / totalTime) * 100;
            setCompleted(progress);
            return prevTime - 1;
          } else {
            clearInterval(timer);
            return 0;
          }
        });
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [expiry, timeLeft]);

  useEffect(() => {
    if (invitedSignup && offer) {
      if (signUp && 'AcceptedCharacter' in signUp) {
        setPaymentCharacter(
          signUp?.ChosenPaymentCharacter || signUp?.RealmPaymentCharacters[0]
        );
      }
      setModalOpen(true);
    } else setModalOpen(false);
  }, [invitedSignup]);

  function handleResponse(
    response: boolean,
    chosenPaymentCharacterId?: number
  ) {
    if (signUp && 'AcceptedCharacter' in signUp) {
      dispatch({
        type: 'socket/Message/send',
        payload: {
          type: 'invite_response',
          data: {
            OfferID: offerID,
            SignID: signUp?._id,
            Response: response,
            ChosenPaymentCharacterID: chosenPaymentCharacterId
          }
        }
      });
    }
    setModalOpen(false);
  }

  const handleSendImages = async (response: boolean) => {
    let base64Images = [];
    for (const image of images) {
      base64Images.push(await convertToBase64(image.file));
    }
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'buyer_deposit_response',
        data: response
          ? {
              _id: offerID,
              pictures: base64Images,
              Response: response
            }
          : {
              _id: offerID,
              Response: response
            }
      }
    });
    setImages([]);
    setModalOpen(false);
  };

  const InvitedRole =
    signUp && 'AcceptedCharacter' in signUp
      ? WoWRoles.find((role) => role.Name === signUp.AcceptedRole)
      : null;

  const InvitedClass =
    signUp && 'AcceptedCharacter' in signUp
      ? Classes.find(
          (wowclass) => wowclass.Name === signUp.AcceptedCharacter.Class
        )
      : null;

  return invitedSignup && timeLeft && timeLeft > 0 ? (
    <Dialog
      open={modalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="md"
    >
      <React.Fragment>
        <Card
          sx={{
            padding: 2,
            maxHeight: '90vh',
            overflowY: 'auto',
            [theme.breakpoints.down('sm')]: {
              maxHeight: '80vh',
              padding: 1
            }
          }}
        >
          {signUp && 'AcceptedCharacter' in signUp ? (
            <Typography
              id="modal-modal-title"
              variant="h3"
              component="h2"
              textAlign={'center'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 3,
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center'
                }
              }}
            >
              Invited as
              {
                <Avatar
                  src={InvitedRole.Emoji}
                  sx={{ mr: 1, ml: 1, width: 25, height: 25 }}
                />
              }
              {InvitedRole.Name}
              {
                <Avatar
                  src={InvitedClass.Emoji}
                  sx={{ mr: 1, ml: 1, width: 25, height: 25 }}
                />
              }
              <Typography
                variant="h3"
                color={InvitedClass.Color}
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    textAlign: 'center'
                  }
                }}
              >
                {signUp.AcceptedCharacter.Name}
              </Typography>
            </Typography>
          ) : 'PosterPaymentCharacter' in offer ? (
            <Typography
              id="modal-modal-title"
              variant="h3"
              component="h2"
              textAlign={'center'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 3,
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center'
                }
              }}
            >
              Send deposit to {offer.PosterPaymentCharacter.Name}-
              {offer.PosterPaymentCharacter.Realm}
            </Typography>
          ) : null}

          <Offers
            offer={offer}
            defaultExpanded={isMd && !('DepositPictures' in signUp)}
            isLFS={'DepositPictures' in signUp}
          />
          {signUp &&
            'RealmPaymentCharacters' in signUp &&
            signUp?.RealmPaymentCharacters && (
              <FormControl
                sx={{
                  m: 1,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <InputLabel>
                  <Typography variant="h4" fontSize={18}>
                    <Text color="info">Payment Character</Text>
                  </Typography>
                </InputLabel>
                <Select
                  value={
                    paymentCharacter
                      ? paymentCharacter._id
                      : signUp?.RealmPaymentCharacters[0]?._id
                  }
                  onChange={(event) => {
                    const selectedChar = signUp.RealmPaymentCharacters.find(
                      (char) => char._id === event.target.value
                    );
                    setPaymentCharacter(selectedChar);
                  }}
                  renderValue={(value) =>
                    paymentCharacter
                      ? `${paymentCharacter.Name}-${paymentCharacter.Realm}`
                      : 'Payment Character'
                  }
                >
                  {signUp.RealmPaymentCharacters.map((char, index) => (
                    <MenuItem key={index} value={char._id}>
                      {char.Name}-{char.Realm}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Your cut will be sent to this</FormHelperText>
              </FormControl>
            )}
          {'PosterPaymentCharacter' in offer && 'DepositPictures' in signUp && (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              <Box textAlign={'center'} mb={3}>
                <Typography variant="h4">
                  {' '}
                  You have to send {signUp?.Characters[-1]?.Deposit}K to:
                </Typography>
                <Typography
                  variant="h2"
                  sx={{ fontWeight: 'bold', mt: 1, mb: 2 }}
                >
                  {offer?.PosterPaymentCharacter?.Name}-
                  {offer?.PosterPaymentCharacter?.Realm}
                </Typography>
                <Typography variant="h4">
                  or your registration will be canceled.
                </Typography>
              </Box>
              <PicturesUpload images={images} setImages={setImages} />
            </Box>
          )}
          <Box
            textAlign={'center'}
            display={'flex'}
            alignItems={'center'}
            alignContent={'center'}
            justifyItems={'center'}
            justifyContent={'center'}
            justifySelf={'center'}
            sx={{
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
              }
            }}
          >
            {signUp && 'AcceptedCharacter' in signUp && (
              <Button
                sx={{
                  width: '20%',
                  marginRight: '5px',
                  marginTop: '10px',
                  borderRadius: '15px',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    marginBottom: '10px'
                  }
                }}
                variant="contained"
                color="success"
                size="small"
                onClick={() => handleResponse(true, paymentCharacter?._id)}
              >
                Accept
              </Button>
            )}
            {'PosterPaymentCharacter' in offer && 'DepositPictures' in signUp && (
              <Button
                sx={{
                  width: '20%',
                  marginRight: '5px',
                  marginTop: '10px',
                  borderRadius: '15px',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    marginBottom: '10px'
                  }
                }}
                variant="contained"
                color="success"
                size="small"
                disabled={images.length === 0}
                onClick={() => handleSendImages(true)}
              >
                Submit
              </Button>
            )}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt={2}
            >
              <CircularProgress variant="determinate" value={completed} />
              <Typography variant="h4" mt={1}>
                <Text color="black">{`${Math.floor(timeLeft)}s`}</Text>
              </Typography>
            </Box>
            {signUp && 'AcceptedCharacter' in signUp && (
              <Button
                sx={{
                  width: '20%',
                  marginLeft: '5px',
                  marginTop: '10px',
                  borderRadius: '15px',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    marginTop: '10px'
                  }
                }}
                variant="contained"
                color="error"
                size="small"
                onClick={() => handleResponse(false, paymentCharacter?._id)}
              >
                Reject
              </Button>
            )}
            {'PosterPaymentCharacter' in offer && 'DepositPictures' in signUp && (
              <Button
                sx={{
                  width: '20%',
                  marginLeft: '5px',
                  marginTop: '10px',
                  borderRadius: '15px',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    marginTop: '10px'
                  }
                }}
                variant="contained"
                color="error"
                size="small"
                onClick={() => handleSendImages(false)}
              >
                Cancel
              </Button>
            )}
          </Box>
        </Card>
      </React.Fragment>
    </Dialog>
  ) : null;
};
