import { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Typography,
  List,
  ListItemAvatar,
  ListItemText,
  Paper,
  ListItemButton,
  Collapse,
  Badge
} from '@mui/material';
import { getBadgeColor, getUser } from 'src/models/user';
import React from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ProfilePopUp } from '../Profile';
import { OfferTypes } from 'src/models/offer';
import { getRecentMessages } from 'src/models/message';
import { NavLink } from 'react-router-dom';
import { RootState } from 'src/app/store';
import { useSelector } from 'react-redux';

const RecentChats = () => {
  const user = getUser();
  const onlineStatuses = useSelector(
    (state: RootState) => state.user?.onlineStatuses
  );
  const [openProfile, setOpenProfile] = useState<string>(undefined);
  const [open, setOpen] = useState<boolean>(true);

  const recentMessages = getRecentMessages(user?.Messages, [user?._id], 20);

  return (
    <React.Fragment>
      <Paper>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            textAlign={'center'}
            variant="h4"
            sx={{
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            Recent Chats
          </Typography>
          <Button onClick={() => setOpen(!open)}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Box>
        <Collapse in={open}>
          {Object.keys(recentMessages).length === 0 ? (
            <Typography
              textAlign={'center'}
              variant="h5"
              sx={{
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
              my={2}
            >
              No Chats Found
            </Typography>
          ) : (
            <Box
              maxHeight={300}
              sx={{
                overflowY: 'scroll',
                overflowX: 'hidden'
              }}
            >
              <List dense sx={{ mx: -1 }}>
                {Object.entries(recentMessages).map(([member, messages]) => {
                  const lastMessage = messages[messages.length - 1];
                  const unreadmessages = [...messages].filter((message) => {
                    return !message.Readers?.includes(user?._id);
                  }).length;
                  if ('RoomID' in lastMessage) {
                    return (
                      <React.Fragment key={member}>
                        <ListItemButton
                          to={`/management/offers/offers?runID=${member}`}
                          component={NavLink}
                        >
                          <ListItemAvatar>
                            <Badge badgeContent={unreadmessages} color="error">
                              <Avatar
                                src={
                                  lastMessage.GroupPictureURL ||
                                  OfferTypes[0].emoji
                                }
                              />
                            </Badge>
                          </ListItemAvatar>
                          <ListItemText>
                            {lastMessage?.GroupName && (
                              <Typography variant="h5">
                                {lastMessage?.GroupName}
                              </Typography>
                            )}
                            <Typography variant="subtitle1" noWrap>
                              {lastMessage.Content || 'Image'}
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                      </React.Fragment>
                    );
                  } else
                    return (
                      <React.Fragment key={member}>
                        <ListItemButton onClick={() => setOpenProfile(member)}>
                          <ListItemAvatar>
                            <Badge badgeContent={unreadmessages} color="error">
                              <Badge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}
                                badgeContent={''}
                                color={getBadgeColor(onlineStatuses?.[member])}
                              >
                                <Avatar
                                  src={
                                    user?.CachedDiscordDetails?.[member]
                                      ?.AvatarURL
                                  }
                                />
                              </Badge>
                            </Badge>
                          </ListItemAvatar>
                          <ListItemText>
                            <Typography variant="h5">
                              {user?.CachedDiscordDetails?.[member]?.Username}
                            </Typography>
                            <Typography variant="subtitle1" noWrap>
                              {lastMessage.Content || 'Image'}
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                      </React.Fragment>
                    );
                })}
              </List>
            </Box>
          )}
        </Collapse>
      </Paper>
      <ProfilePopUp
        id={openProfile}
        discordDetails={
          openProfile
            ? Object.entries(user?.CachedDiscordDetails).find(
                ([id, user]) => id === openProfile
              )?.[1]
            : undefined
        }
        onClose={() => setOpenProfile(undefined)}
      />
    </React.Fragment>
  );
};

export default RecentChats;
