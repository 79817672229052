import { Box, Grid, Typography, TypographyProps } from '@mui/material';
import { EmojiAvatar } from '../EmojiAvatar';
import React from 'react';

function OpenSpotsBox(
  openSpots: { [key: string]: number },
  keyholder?: boolean
) {
  return (
    <Box display={'inline-flex'}>
      {Object.keys(openSpots)
        .filter((role) => openSpots[role] > 0)
        .map((role) => {
          return (
            <EmojiAvatar
              src={`/static/images/WoW/Roles/${role}.png`}
              key={role}
              sx={{ marginRight: 0.1 }}
            />
          );
        })}
      {keyholder && (
        <EmojiAvatar src={`/static/images/WoW/Emojis/keystone.png`} />
      )}
    </Box>
  );
}

function FillingProgress(
  openSpots: { [key: string]: number },
  totalSpots: { [key: string]: number },
  TypographyProps?: TypographyProps
) {
  return (
    <Grid container spacing={0.5}>
      {Object.keys(openSpots).map((role) => {
        const filled: number = totalSpots[role] - openSpots[role];
        return (
          <Grid item xs={12} key={role} display={'inline-flex'}>
            <EmojiAvatar src={`/static/images/WoW/Roles/${role}.png`} />
            <Typography {...TypographyProps}>
              {openSpots[role] > 0 ? (
                <b>
                  {role}: {`${filled}/${totalSpots[role]}`}
                </b>
              ) : (
                <s>
                  {role}: {`${filled}/${totalSpots[role]}`}
                </s>
              )}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
}

export interface OpenSpotsProps extends Omit<TypographyProps, 'color'> {
  openSpots: { [key: string]: number };
  totalSpots: { [key: string]: number };
  keyholder?: boolean;
  Detailed?: boolean;
}
export const OpenSpots = ({
  openSpots,
  totalSpots,
  keyholder,
  Detailed,
  ...typographyProps
}: OpenSpotsProps) => {
  return (
    <React.Fragment>
      {Detailed
        ? FillingProgress(openSpots, totalSpots, typographyProps)
        : OpenSpotsBox(openSpots, keyholder)}
    </React.Fragment>
  );
};

export default OpenSpots;
