import React, { useState, useEffect, forwardRef } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

interface CooldownButtonProps extends ButtonProps {
  cooldownSeconds?: number;
  expiryTimestamp?: number; // Timestamp should be in milliseconds
}

const CooldownButton = forwardRef<HTMLButtonElement, CooldownButtonProps>(
  ({ cooldownSeconds, expiryTimestamp, onClick, ...buttonProps }, ref) => {
    const calculateTimeLeft = () => {
      if (expiryTimestamp) {
        return Math.max(0, Math.ceil((expiryTimestamp - Date.now()) / 1000));
      }
      return cooldownSeconds || 0;
    };

    const hasExpiry = expiryTimestamp !== undefined;
    const [isCooldown, setIsCooldown] = useState(hasExpiry);
    const [timeLeft, setTimeLeft] = useState(
      hasExpiry ? calculateTimeLeft() : 0
    );

    useEffect(() => {
      setTimeLeft(calculateTimeLeft());
      setIsCooldown(calculateTimeLeft() > 0);
    }, [expiryTimestamp]);

    useEffect(() => {
      let timer: NodeJS.Timeout;

      if (isCooldown && timeLeft > 0) {
        timer = setTimeout(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);
      } else {
        setIsCooldown(false);
      }

      return () => {
        clearTimeout(timer);
      };
    }, [isCooldown, timeLeft]);

    const handleClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (onClick) {
        onClick(e);
      }

      setIsCooldown(true);
      setTimeLeft(calculateTimeLeft());
    };

    return (
      <Button
        {...buttonProps}
        onClick={isCooldown ? undefined : handleClick}
        disabled={isCooldown || buttonProps.disabled}
        ref={ref}
      >
        {isCooldown ? `${timeLeft}s` : buttonProps.children}
      </Button>
    );
  }
);

CooldownButton.displayName = 'CooldownButton';

export default CooldownButton;
