export interface Rank {
  Rank: number;
  Name: string;
  Score: number;
  Color: string;
  Image: string;
}

const BoosterRankImageTop = '/static/images/WoW/Booster_Ranks/';
const PosterRankImageTop = '/static/images/WoW/Poster_Ranks/';

export const BoosterRanks: Rank[] = [
  {
    Rank: 0,
    Name: 'Booster Trainee',
    Score: 0,
    Color: '#000000',
    Image: `${BoosterRankImageTop}01 - Trainee (128).png`
  },
  {
    Rank: 1,
    Name: 'Junior Booster',
    Score: 20,
    Color: '#6d5e44',
    Image: `${BoosterRankImageTop}02 - Junior (128).png`
  },
  {
    Rank: 2,
    Name: 'Senior Booster',
    Score: 75,
    Color: '#866451',
    Image: `${BoosterRankImageTop}03 - Senior (128).png`
  },
  {
    Rank: 3,
    Name: 'Heroic Booster',
    Score: 125,
    Color: '#ffd885',
    Image: `${BoosterRankImageTop}04 - Heroic (128).png`
  },
  {
    Rank: 4,
    Name: 'Elite Booster',
    Score: 250,
    Color: '#ffb649',
    Image: `${BoosterRankImageTop}05 - Elite (128).png`
  },
  {
    Rank: 5,
    Name: 'Supreme Booster',
    Score: 450,
    Color: '#a86b3e',
    Image: `${BoosterRankImageTop}06 - Supreme (128).png`
  },
  {
    Rank: 6,
    Name: 'Monster Booster',
    Score: 750,
    Color: '#fca58d',
    Image: `${BoosterRankImageTop}07 - Monster (128).png`
  },
  {
    Rank: 7,
    Name: 'Titan Booster',
    Score: 1250,
    Color: '#f79857',
    Image: `${BoosterRankImageTop}08 - Titan (128).png`
  },
  {
    Rank: 8,
    Name: 'Legendary Booster',
    Score: 2000,
    Color: '#fdb0b0',
    Image: `${BoosterRankImageTop}09 - Legendary (128).png`
  },
  {
    Rank: 9,
    Name: 'Demigod Booster',
    Score: 3000,
    Color: '#ff5555',
    Image: `${BoosterRankImageTop}10 - Demigod (128).png`
  },
  {
    Rank: 10,
    Name: 'Celestial Booster',
    Score: 8000,
    Color: '#da096d',
    Image: `${BoosterRankImageTop}11 - Celestial (128).png`
  },
  {
    Rank: 11,
    Name: 'Godlike Booster',
    Score: 20000,
    Color: '#ff25ae',
    Image: `${BoosterRankImageTop}12 - Godlike (128).png`
  },
  {
    Rank: 12,
    Name: 'Primordial Booster',
    Score: 50000,
    Color: '#ff2dd8',
    Image: `${BoosterRankImageTop}13 - Primordial (128).png`
  }
];

export const PosterRanks: Rank[] = [
  {
    Rank: 0,
    Name: 'Poster Trainee',
    Score: 0,
    Color: '#000000',
    Image: `${PosterRankImageTop}01 - Trainee (128).png`
  },
  {
    Rank: 1,
    Name: 'Junior Poster',
    Score: 40,
    Color: '#954f12',
    Image: `${PosterRankImageTop}02 - Junior (128).png`
  },
  {
    Rank: 2,
    Name: 'Senior Poster',
    Score: 100,
    Color: '#b26e1d',
    Image: `${PosterRankImageTop}03 - Senior (128).png`
  },
  {
    Rank: 3,
    Name: 'Heroic Poster',
    Score: 200,
    Color: '#ff9a13',
    Image: `${PosterRankImageTop}04 - Heroic (128).png`
  },
  {
    Rank: 4,
    Name: 'Elite Poster',
    Score: 400,
    Color: '#b2831d',
    Image: `${PosterRankImageTop}05 - Elite (128).png`
  },
  {
    Rank: 5,
    Name: 'Supreme Poster',
    Score: 625,
    Color: '#ffe69e',
    Image: `${PosterRankImageTop}06 - Supreme (128).png`
  },
  {
    Rank: 6,
    Name: 'Monster Poster',
    Score: 1250,
    Color: '#cbff83',
    Image: `${PosterRankImageTop}07 - Monster (128).png`
  },
  {
    Rank: 7,
    Name: 'Titan Poster',
    Score: 2500,
    Color: '#9acd05',
    Image: `${PosterRankImageTop}08 - Titan (128).png`
  },
  {
    Rank: 8,
    Name: 'Legendary Poster',
    Score: 5000,
    Color: '#55b200',
    Image: `${PosterRankImageTop}09 - Legendary (128).png`
  },
  {
    Rank: 9,
    Name: 'Demigod Poster',
    Score: 10000,
    Color: '#00cf0c',
    Image: `${PosterRankImageTop}10 - Demigod (128).png`
  },
  {
    Rank: 10,
    Name: 'Celestial Poster',
    Score: 25000,
    Color: '#5cff00',
    Image: `${PosterRankImageTop}11 - Celestial (128).png`
  },
  {
    Rank: 11,
    Name: 'Godlike Poster',
    Score: 50000,
    Color: '#27ffb8',
    Image: `${PosterRankImageTop}12 - Godlike (128).png`
  },
  {
    Rank: 12,
    Name: 'Primordial Poster',
    Score: 100000,
    Color: '#0fe5ff',
    Image: `${PosterRankImageTop}13 - Primordial (128).png`
  }
];

export function FindRank(
  score: number,
  type: 'MythicPlus' | 'Advertising'
): { Rank: Rank; NextRank: Rank } {
  const Ranks = type === 'MythicPlus' ? BoosterRanks : PosterRanks;
  if (score === undefined) return { Rank: Ranks[0], NextRank: Ranks[1] };
  for (let i = 0; i < Ranks.length; i++) {
    if (score < Ranks[i].Score)
      return { Rank: Ranks[i - 1], NextRank: Ranks[i] };
  }
  return { Rank: Ranks[Ranks.length - 1], NextRank: Ranks[Ranks.length - 1] };
}
