import React, { useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  Tooltip,
  IconButton,
  Box,
  Button,
  styled,
  InputBase,
  useTheme,
  useMediaQuery,
  Card
} from '@mui/material';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { getTimeOffset } from 'src/models/client';
import PictureDragDropOverlay from 'src/components/Utility/PictureDragDropOverlay';
import { convertToBase64 } from 'src/components/Utility/ConvertPictureToBase64';

const MessageInputWrapper = styled(InputBase)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(1)};
    width: 100%;
`
);

const PickerWrapper = styled('div')({
  position: 'absolute',
  bottom: '100%',
  right: 0,
  zIndex: 1000
});

const Input = styled('input')({
  display: 'none'
});

interface BottomBarContentProps {
  MessageType: 'Direct' | 'Group';
  ID: string;
  GroupName?: string;
  GroupPictureURL?: string;
}

function BottomBarContent({
  MessageType,
  ID,
  GroupName,
  GroupPictureURL
}: BottomBarContentProps) {
  const timeOffset = getTimeOffset();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [message, setMessage] = useState('');
  const [lastMessageTimestamp, setLastMessageTimestamp] = useState<number>(0);

  const handleImageChange = (fileOrEvent) => {
    let file;

    // Check if the argument is an event or a file object
    if (fileOrEvent.target && fileOrEvent.target.files) {
      file = fileOrEvent.target.files[0];
    } else if (fileOrEvent instanceof File) {
      file = fileOrEvent;
    }

    if (file) {
      // Check if the file is an image
      if (!file.type.startsWith('image/')) {
        alert('Please upload an image file.');
        return;
      }

      // Check if the file size is less than 1MB
      if (file.size > 3 * 1024 * 1024) {
        alert('Image file size should be less than 3MB.');
        return;
      }

      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
      if (fileInputRef.current) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        fileInputRef.current.files = dataTransfer.files;

        // Optionally, dispatch a change event on the file input
        const event = new Event('change', { bubbles: true });
        fileInputRef.current.dispatchEvent(event);
      }
    }
  };

  const fileInputRef = React.useRef(null); // Create the ref

  const dismissImagePreview = () => {
    setImagePreview(null);

    // Reset the value of the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleEmojiSelect = (emoji) => {
    setMessage((prevMessage) => prevMessage + emoji.native);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    // Get the current timestamp
    const currentTimestamp = Date.now() - timeOffset;

    // Check if the difference between the current timestamp and the last sent message timestamp is less than 1 second (1000 milliseconds)
    if (currentTimestamp - lastMessageTimestamp < 1000) {
      alert('Please wait a moment before sending another message.');
      return;
    }

    // Convert image to Base64
    const file = fileInputRef.current.files[0];
    let base64Image = null;
    if (file) {
      base64Image = await convertToBase64(file);
    }
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'send_message',
        data: {
          type: MessageType,
          id: ID,
          content: message,
          picture: base64Image,
          groupName: GroupName,
          groupPictureURL: GroupPictureURL
        }
      }
    });

    // Update the last sent message timestamp
    setLastMessageTimestamp(currentTimestamp);

    // Clear the message and image preview
    setMessage('');
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const emojiPerRow = isXs ? 5 : isSm ? 6 : isMd ? 7 : 8;

  return (
    <Box>
      <PictureDragDropOverlay
        onFilesDropped={(files) => handleImageChange(files[0])}
      />
      <Card sx={{ overflow: 'visible' }}>
        <Box
          sx={{
            background: theme.colors.alpha.white[50],
            display: 'flex',
            alignItems: 'center',
            p: 2,
            position: 'relative' // make this the reference for absolute positioning
          }}
        >
          <Box flexGrow={1} display="flex" alignItems="center">
            <MessageInputWrapper
              value={message}
              onChange={handleMessageChange}
              onKeyDown={handleKeyDown}
              autoFocus
              placeholder="Write your message here..."
              fullWidth
              multiline
            />
          </Box>
          <Box>
            <Tooltip arrow placement="top" title="Choose an emoji">
              <IconButton
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                sx={{ fontSize: theme.typography.pxToRem(16) }}
                color="primary"
              >
                😀
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="top" title="Attach a file">
              <label htmlFor="messenger-upload-file">
                <IconButton sx={{ mx: 1 }} color="primary" component="span">
                  <AttachFileTwoToneIcon fontSize="small" />
                </IconButton>
              </label>
            </Tooltip>

            <Input
              accept="image/*"
              id="messenger-upload-file"
              type="file"
              ref={fileInputRef} // Set the ref to the input
              onChange={handleImageChange}
            />

            <Button
              startIcon={<SendTwoToneIcon />}
              variant="contained"
              onClick={handleSendMessage}
            >
              Send
            </Button>
          </Box>
          <Box position={'absolute'} bottom="80%" right={0}>
            {showEmojiPicker && (
              <Picker
                data={data}
                onEmojiSelect={handleEmojiSelect}
                theme="auto"
                icons="outline"
                perLine={emojiPerRow}
              />
            )}
          </Box>
        </Box>
        {imagePreview && (
          <Box sx={{ m: 2, position: 'relative', display: 'inline-block' }}>
            <img
              src={imagePreview}
              alt="Selected Preview"
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            />
            <IconButton
              size="small"
              sx={{
                position: 'absolute',
                top: '-8px', // Adjust these values as needed
                right: '-8px', // Adjust these values as needed
                background: 'rgba(255, 255, 255, 0.5)'
              }}
              onClick={dismissImagePreview}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Card>
    </Box>
  );
}

export default BottomBarContent;
