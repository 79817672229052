import { Box, Typography, TypographyProps } from '@mui/material';
import Text from 'src/components/Text';
import { EmojiAvatar } from '../EmojiAvatar';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { OfferTypes, OfferTypeNames } from 'src/models/offer';

export interface OfferEmojiProps extends Omit<TypographyProps, 'color'> {
  PosterID: string;
  UserID: string;
  OfferType: OfferTypeNames;
}
export const OfferEmoji = ({
  PosterID,
  UserID,
  OfferType,
  ...typographyProps
}: OfferEmojiProps) => {
  const offerEmoji =
    Object.values(OfferTypes).find((offer) => offer.name === OfferType)
      ?.emoji || '/static/images/WoW/Emojis/chest.png';
  return UserID === PosterID ? (
    <MyLocationIcon color="warning" sx={{ width: 25, height: 25, mx: 0.5 }} />
  ) : (
    <EmojiAvatar src={offerEmoji} sx={{ width: 25, height: 25, mx: 0.5 }} />
  );
};

export default OfferEmoji;
