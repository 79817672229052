import { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import numeral from 'numeral';

import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  Link,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Typography,
  Dialog,
  useMediaQuery,
  useTheme,
  Zoom,
  styled
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import Label from 'src/components/Label';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Text from 'src/components/Text';
import LocalFireDepartmentTwoToneIcon from '@mui/icons-material/LocalFireDepartmentTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import BulkActions from './BulkActions';
import { TransitionProps } from '@mui/material/transitions';
import { PricelistItem } from 'src/models/pricelist';
import { useDispatch } from 'react-redux';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const ImgWrapper = styled('img')(
  ({ theme }) => `
      width: ${theme.spacing(8)};
      height: auto;
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction={'down'} ref={ref} {...props} />;
});

const applyFilters = (products: PricelistItem[], query): PricelistItem[] => {
  return products.filter((product) => {
    let matches = true;

    if (query) {
      const properties = ['Title', 'Category'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (product[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyPagination = (
  products: PricelistItem[],
  page,
  limit
): PricelistItem[] => {
  return products.slice(page * limit, page * limit + limit);
};

interface ResultsProps {
  items: PricelistItem[];
}

const Results = ({ items }: ResultsProps) => {
  const [selectedItems, setSelectedProducts] = useState([]);
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [query, setQuery] = useState('');

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSelectAllProducts = (event) => {
    setSelectedProducts(
      event.target.checked ? items.map((product) => product._id) : []
    );
  };

  const handleSelectOneProduct = (event, productId) => {
    if (!selectedItems.includes(productId)) {
      setSelectedProducts((prevSelected) => [...prevSelected, productId]);
    } else {
      setSelectedProducts((prevSelected) =>
        prevSelected.filter((_id) => _id !== productId)
      );
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const filteredProducts = applyFilters(items, query);
  const paginatedProducts = applyPagination(filteredProducts, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeProducts =
    selectedItems.length > 0 && selectedItems.length < items.length;
  const selectedAllProducts = selectedItems.length === items.length;
  const [focusedItems, setFocusedItems] = useState([]);
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleConfirmDelete = (ids: string[]) => {
    setFocusedItems(ids);
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = () => {
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'delete_pricelist_items',
        data: {
          ItemIDs: focusedItems
        }
      }
    });
    setOpenConfirmDelete(false);
  };

  return (
    <>
      <Card>
        <Box display="flex" alignItems="center">
          {selectedBulkActions && (
            <Box flex={1} p={2}>
              <BulkActions
                onDelete={() => handleConfirmDelete(selectedItems)}
              />
            </Box>
          )}
          {!selectedBulkActions && (
            <Box
              flex={1}
              p={2}
              display={{ xs: 'block', md: 'flex' }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                sx={{
                  mb: { xs: 2, md: 0 }
                }}
              >
                <TextField
                  size="small"
                  fullWidth={mobile}
                  onChange={handleQueryChange}
                  value={query}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    )
                  }}
                  placeholder={'Search by product name...'}
                />
              </Box>
              <TablePagination
                component="div"
                count={filteredProducts.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          )}
        </Box>
        <Divider />

        {paginatedProducts.length === 0 ? (
          <Typography
            sx={{
              py: 10
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            We couldn't find any products matching your search criteria
          </Typography>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAllProducts}
                        indeterminate={selectedSomeProducts}
                        onChange={handleSelectAllProducts}
                      />
                    </TableCell>
                    <TableCell>Product name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell align="center">Stock</TableCell>
                    <TableCell>Categories</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedProducts.map((item) => {
                    const isProductSelected = selectedItems.includes(item._id);
                    return (
                      <TableRow
                        hover
                        key={item._id}
                        selected={isProductSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isProductSelected}
                            onChange={(event) =>
                              handleSelectOneProduct(event, item._id)
                            }
                            value={isProductSelected}
                          />
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <ImgWrapper src={item.Image} />
                            <Box
                              pl={1}
                              sx={{
                                width: 250
                              }}
                            >
                              <Link
                                component={RouterLink}
                                to={`/${
                                  location.pathname.split('/')[1]
                                }/create?id=${item._id}`}
                                variant="h5"
                              >
                                {item.Title}
                              </Link>
                              <Typography variant="subtitle2" noWrap>
                                {item.Description}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Typography>{item.BasePrice}K</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Label color="success">
                            <b>{item.MaximumBuyers || 'Unlimited Buyers'}</b>
                          </Label>
                        </TableCell>
                        {/* <TableCell align="center">
                          <Box display="flex" alignItems="center">
                            <Text color="warning">
                              <LocalFireDepartmentTwoToneIcon fontSize="small" />
                            </Text>
                            <Typography
                              variant="h5"
                              sx={{
                                pl: 0.5
                              }}
                            >
                              {product.rating}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">{product.orders}</TableCell>
                         */}
                        <TableCell>{item.Category}</TableCell>
                        <TableCell align="center">
                          <Typography noWrap>
                            <Tooltip title={'View'} arrow>
                              <IconButton
                                component={RouterLink}
                                to={`/${
                                  location.pathname.split('/')[1]
                                }/create?id=${item._id}`}
                                color="primary"
                              >
                                <LaunchTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={'Delete'} arrow>
                              <IconButton
                                onClick={() => handleConfirmDelete([item._id])}
                                color="primary"
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={filteredProducts.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          </>
        )}
      </Card>
      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={
          Transition as React.JSXElementConstructor<
            TransitionProps & { children: React.ReactElement<any, any> }
          >
        }
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              pt: 4,
              px: 6
            }}
            variant="h3"
          >
            Do you really want to delete this product?
          </Typography>

          <Typography
            align="center"
            sx={{
              pt: 2,
              pb: 4,
              px: 6
            }}
            fontWeight="normal"
            color="text.secondary"
            variant="h4"
          >
            You won't be able to revert after deletion
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              Cancel
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              Delete
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

Results.propTypes = {
  products: PropTypes.array.isRequired
};

Results.defaultProps = {
  products: []
};

export default Results;
