import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { AppealItem } from 'src/models/review';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'appeals',
  storage: storage
  // whitelist: ['isConnected'], // If you only want to persist specific parts of the state
};

interface appealsProps {
  appeals: AppealItem[];
}
const initialState: appealsProps = {
  appeals: []
};

export const appealsSlice = createSlice({
  name: 'appeals',
  initialState,
  reducers: {
    reset: (state) => {
      for (const key in state) {
        if (!(key in initialState)) {
          delete state[key];
        }
      }
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    update: (state, action: PayloadAction<appealsProps>) => {
      for (const key in action.payload) {
        if (Object.prototype.hasOwnProperty.call(action.payload, key)) {
          state[key] = action.payload[key];
        }
      }
    },
    addAppeal: (state, action: PayloadAction<AppealItem>) => {
      const index = state?.appeals.findIndex(
        (appeal) => appeal._id === action.payload._id
      );
      if (index !== undefined && index > -1) {
        state.appeals[index] = action.payload;
      } else {
        state.appeals.push(action.payload);
      }
    },
    removeAppeal: (state, action: PayloadAction<AppealItem>) => {
      const index = state?.appeals.findIndex(
        (appeal) => appeal._id === action.payload._id
      );
      if (index !== undefined && index > -1) {
        state.appeals.splice(index, 1);
      }
    }
  }
});

export const { reset, update, addAppeal, removeAppeal } = appealsSlice.actions;
const persistedReducer = persistReducer(persistConfig, appealsSlice.reducer);
export default persistedReducer;
