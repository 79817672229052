import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import TagIcon from '@mui/icons-material/Tag';
import { DetailsField } from './fields';
import StarIcon from '@mui/icons-material/Star';
import BalanceIcon from '@mui/icons-material/Balance';

export const DelveDetailsFields: DetailsField[] = [
  {
    key: 'Tier',
    emoji: <PlayCircleOutlineIcon />,
    emojiColor: 'success.dark',
    prepend: 'Tier: '
  },
  {
    key: 'Quantity',
    emoji: <TagIcon />,
    emojiColor: 'success.dark',
    append: ' Run'
  },
  {
    key: 'Bountiful',
    emoji: <PauseCircleOutlineIcon />,
    emojiColor: 'error.dark',
    textFunction: (bountiful: boolean) =>
      bountiful ? 'Bountiful' : 'Not Bountiful',
    textFunctionArgs: ['Bountiful']
  }
];

export const DelveRequirementsFields: DetailsField[] = [
  {
    key: 'MinimumIlvl',
    emoji: '/static/images/WoW/Logo/wow.png',
    append: ' ilvl Every Slot'
  },
  {
    key: 'MinimumRating',
    emoji: <StarIcon />,
    emojiColor: 'warning.main',
    append: '/10 Rating'
  },
  {
    key: 'MinimumKarma',
    emoji: <BalanceIcon />,
    emojiColor: 'info.main',
    append: ' Karma'
  }
];
