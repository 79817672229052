import { Difficulties } from '../difficulties';
import { RaidBosses } from '../raidbosses';
import { Difficulty, SubCategory } from './fields';

export const RaidSubCategories: SubCategory[] = [
  ...Difficulties.map((d) =>
    Difficulty(d.Name, 'Raid', [
      'Full Clear',
      'Gear Trade Options',
      'Mount Trade Available',
      'Saved Run',
      ...RaidBosses.map((b) => b.Name)
    ])
  ),
  {
    Title: 'Coaching',
    ParentCategory: 'Raid',
    Tags: ['Hourly Coaching', 'Specific Boss Coaching']
  }
];
