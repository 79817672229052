import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReviewNotificationSettings } from 'src/models/review';
import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import {
  inviteNotificationSettings as InviteNotificationSettings,
  OfferNotificationSettings
} from 'src/models/offer';
import { SignUpNotificationSettings } from 'src/models/signup';
import { MessageNotificationSettings } from 'src/models/message';
import { generalNotificationSettings as GeneralNotificationSettings } from 'src/models/notifications';

const migrations = {
  1: (state) => {
    const migratedState = { ...state };
    const notificationTypes = [
      'offers',
      'defaultSignUp',
      'reviews',
      'messages'
    ];
    notificationTypes.forEach((type) => {
      if (
        migratedState?.[type] &&
        typeof migratedState[type]?.sound === 'undefined'
      ) {
        migratedState[type].sound = true;
      }
      if (
        migratedState?.[type] &&
        typeof migratedState[type]?.windowsNotif === 'undefined'
      ) {
        migratedState[type].windowsNotif = true;
      }
    });
    return migratedState;
  },
  2: (state) => {
    const migratedState = { ...state };
    const notificationTypes = [
      'offers',
      'defaultSignUp',
      'reviews',
      'messages'
    ];
    notificationTypes.forEach((type) => {
      if (migratedState?.[type] && migratedState[type]?.windows) {
        migratedState[type].windowsNotif = migratedState[type].windows;
      }
    });
    return migratedState;
  },
  3: (state) => {
    const migratedState = { ...state };
    const notificationTypes = [
      'offers',
      'defaultSignUp',
      'reviews',
      'messages'
    ];
    notificationTypes.forEach((type) => {
      if (
        migratedState?.[type] &&
        typeof migratedState[type]?.soundVolume === 'undefined'
      ) {
        migratedState[type].soundVolume = 1;
      }
    });
    if (migratedState?.offers?.invites && migratedState?.invites) {
      migratedState.invites = initialState.invites;
      migratedState.invites.invite = migratedState.offers.invites;
      delete migratedState.offers.invites;
    }
    if (migratedState?.general) {
      migratedState.general = initialState.general;
    }
    return migratedState;
  }
};

const persistConfig = {
  key: 'notifications',
  storage: storage,
  version: 3,
  migrate: createMigrate(migrations, { debug: false })
};

export interface notificationsProps {
  general?: GeneralNotificationSettings;
  offers?: OfferNotificationSettings;
  signUps?: { [key: string]: SignUpNotificationSettings };
  defaultSignUp?: SignUpNotificationSettings;
  reviews?: ReviewNotificationSettings;
  messages?: MessageNotificationSettings;
  invites?: InviteNotificationSettings;
}

const initialState: notificationsProps = {
  general: {
    sound: true,
    soundVolume: 1
  },
  offers: {
    newOffer: false,
    sound: true,
    soundVolume: 1,
    windowsNotif: false,
    eligibleOnly: true,
    filteredOnly: true,
    friendOnly: false
  },
  signUps: {},
  defaultSignUp: {
    enable: false,
    sound: true,
    soundVolume: 1,
    windowsNotif: false,
    filteredOnly: true,
    friendsOnly: false,
    keyholderOnly: false
  },
  reviews: {
    Booster: false,
    Poster: false,
    GoldSwap: false,
    BoostRequester: false,
    BoostRequestProvider: false,
    sound: true,
    soundVolume: 1,
    windowsNotif: false
  },
  messages: {
    Direct: true,
    Group: true,
    MutedIDs: [],
    sound: true,
    soundVolume: 1,
    windowsNotif: false
  },
  invites: {
    invite: true,
    sound: true,
    soundVolume: 1,
    windowsNotif: false
  }
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    reset: (state) => {
      for (const key in state) {
        if (!(key in initialState)) {
          delete state[key];
        }
      }
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    update: (state, action: PayloadAction<notificationsProps>) => {
      for (const key in action.payload) {
        if (Object.prototype.hasOwnProperty.call(action.payload, key)) {
          state[key] = action.payload[key];
        }
      }
    },
    updateSignup: (
      state,
      action: PayloadAction<{
        id: string;
        settings: SignUpNotificationSettings;
      }>
    ) => {
      state.signUps[action.payload.id] = action.payload.settings;
    },
    updateMutelist: (
      state,
      action: PayloadAction<{ id: string; muted: boolean }>
    ) => {
      if (action.payload.muted) {
        state.messages.MutedIDs.push(action.payload.id);
      } else {
        state.messages.MutedIDs = state.messages.MutedIDs.filter(
          (id) => id !== action.payload.id
        );
      }
    }
  }
});

export const {
  reset,
  update,
  updateSignup: updateSignup,
  updateMutelist
} = notificationsSlice.actions;
const persistedReducer = persistReducer(
  persistConfig,
  notificationsSlice.reducer
);
export default persistedReducer;
