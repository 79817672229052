import { Avatar, styled } from '@mui/material';

export const EmojiAvatar = styled(Avatar)(
  ({ theme, color }) => `
          margin-right: ${theme.spacing(0.5)};
          width: 20px;
          height: 20px;
          background: transparent;
          color: ${color};
  `
);
