import { Box, Typography, TypographyProps, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import React from 'react';
import { EmojiAvatar } from '../EmojiAvatar';

export interface CutProps extends Omit<TypographyProps, 'color'> {
  KarmaGain: number;
}

export const Karma = ({ KarmaGain, ...typographyProps }: CutProps) => {
  const theme = useTheme();
  const color =
    KarmaGain > 0
      ? theme.palette.success.main
      : KarmaGain < 0
      ? theme.palette.error.main
      : theme.palette.warning.main;
  return (
    <React.Fragment>
      <Box display={'inline-flex'}>
        <Typography variant="body2" {...typographyProps} color={color}>
          {KarmaGain}{' '}
          {KarmaGain > 0
            ? 'Karma Gain'
            : KarmaGain == 0
            ? 'Karma'
            : 'Karma Loss'}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default Karma;
