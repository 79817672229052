import { Box, Grid, Typography, TypographyProps } from '@mui/material';
import { EmojiAvatar } from '../EmojiAvatar';
import { GroupMember } from 'src/models/offer';
import { Classes } from 'src/models/wowclasses';

export interface CurrentGroupProps extends Omit<TypographyProps, 'color'> {
  currentGroup: GroupMember[];
  Metrics: { [key: string]: [string, string] };
  iconsOnly?: boolean;
}
export const CurrentGroup = ({
  currentGroup,
  Metrics,
  iconsOnly = false,
  ...typographyProps
}: CurrentGroupProps) => {
  return iconsOnly ? (
    <Box>
      <Box display={'inline-flex'}>
        <Typography variant="h5" mr={1}>
          GP:
        </Typography>
        {currentGroup?.map((member, index) => {
          const charClass = Classes.find(
            (wowclass) => wowclass.Name === member.Class
          );
          return (
            <EmojiAvatar
              src={charClass.Emoji}
              key={index}
              sx={{ width: 18, height: 18 }}
            />
          );
        })}
      </Box>
    </Box>
  ) : (
    <Grid container spacing={0.5}>
      {currentGroup?.length > 0 ? (
        currentGroup?.map((member, index) => {
          const charClass = Classes.find(
            (wowclass) => wowclass.Name === member.Class
          );
          return (
            <Grid item sm={12} key={index} display={'inline-flex'}>
              <EmojiAvatar
                src={`/static/images/WoW/Roles/${member.Role}.png`}
              />

              <EmojiAvatar src={charClass.Emoji} />
              <Typography
                {...typographyProps}
                color={member.GroupLeader ? 'gold' : charClass.Color}
              >
                {Object.keys(Metrics).map(
                  (metric) =>
                    member?.[metric] && (
                      <span key={metric}>{` ${Metrics[metric][0]}${member[
                        metric
                      ].toFixed(0)}${Metrics[metric][1]}`}</span>
                    )
                )}
              </Typography>
            </Grid>
          );
        })
      ) : (
        <Typography variant="h5">No Members Yet</Typography>
      )}
    </Grid>
  );
};

export default CurrentGroup;
