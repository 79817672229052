import { SubCategory } from './fields';

export const MiscellaneousSubCategories: SubCategory[] = [
  {
    Title: 'Achievements',
    ParentCategory: 'Miscellaneous',
    Tags: [
      'Reputation',
      'PvP',
      'Events',
      'Exploration',
      'Dungeon',
      'Raid',
      'World',
      'Glory'
    ]
  },
  {
    Title: 'Transmog',
    ParentCategory: 'Miscellaneous',
    Tags: [
      'Raid Transmogs',
      'Dungeon Transmogs',
      'Open World Transmogs',
      'Legendary Transmogs',
      'Riddle Transmogs',
      'Tradeable Transmogs'
    ]
  },
  {
    Title: 'Pets',
    ParentCategory: 'Miscellaneous',
    Tags: ['Open World Pets', 'Raid Pets', 'Riddle Pets', 'Achievement Pets']
  },
  {
    Title: 'Toys',
    ParentCategory: 'Miscellaneous',
    Tags: ['Open World Toys', 'Riddle Toys', 'Raid Toys']
  },
  {
    Title: 'Mounts',
    ParentCategory: 'Miscellaneous',
    Tags: [
      'Dungeon Mounts',
      'Raid Mounts',
      'Open World Mounts',
      'Riddle Mounts',
      'Achievement Mounts',
      'Reputation Mounts',
      'Quest Mounts',
      'Expansion Features Mounts'
    ]
  },
  {
    Title: 'Black Market Auction House',
    ParentCategory: 'Miscellaneous',
    Tags: [
      'BMAH Mounts',
      'BMAH Pets',
      'BMAH Toys',
      'BMAH Tabards',
      'BMAH Armors',
      'Other BMAH Items'
    ]
  },
  {
    Title: 'Instance Lockouts',
    ParentCategory: 'Miscellaneous',
    Tags: ['Raid Lockouts', 'Dungeon Lockouts', 'World Boss Lockouts']
  },
  {
    Title: 'Titles',
    ParentCategory: 'Miscellaneous',
    Tags: [
      'Achievement Titles',
      'PvP Titles',
      'Raid Titles',
      'Reputation Titles'
    ]
  },
  {
    Title: 'Other Miscellaneous',
    ParentCategory: 'Miscellaneous',
    Tags: ['Hunter Pets', 'Druid Forms', 'Farms', 'Expansion Features']
  }
];
