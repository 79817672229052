import DiscordTimestamp from './DiscordTimestamp';
import ReactMarkdown from 'react-markdown';
import { styled } from '@mui/system';

const StyledMarkdown = styled(ReactMarkdown)`
  code {
    background-color: #3333; // Dark grey
    color: #f5f5f5; // Light text color for readability on dark grey
    padding: 0.2em 0.4em; // Optional: Add some padding for aesthetics
  }
`;

const preprocessMarkdown = (markdown: string) => {
  // Step 1: Replace escaped newlines with real newlines
  let processedMarkdown = markdown.replace(/\\n/g, '  \n'); // Two spaces followed by a newline

  // Step 2: Separate code blocks into multiple blocks if they contain newlines
  processedMarkdown = processedMarkdown.replace(
    /(```[\s\S]*?```)/g,
    (match) => {
      const innerContent = match.slice(3, -3);
      const lines = innerContent.split('\n');
      if (lines.length > 1) {
        const newBlocks = lines
          .map((line, index) => {
            return line
              ? '```' + line + '```' + (index < lines.length - 1 ? '\n' : '')
              : '';
          })
          .join('');
        return newBlocks;
      }
      return match;
    }
  );

  return processedMarkdown;
};

const splitCodeBlocks = (markdown: string) => {
  return markdown.replace(
    /(```[\s\S]*?)([^`\n]+)\n([^`\n]+)([\s\S]*?```)/g,
    '$1$2```\n```$3$4'
  );
};

export const renderMarkdownContent = (markdown: string) => {
  const preprocessedMarkdown = preprocessMarkdown(markdown);
  const splitMarkdown = splitCodeBlocks(preprocessedMarkdown);
  const components = [];
  const parts = splitMarkdown.split(/(<t:\d+:\w>)/g).map((part) => part.trim());

  for (let i = 0; i < parts.length; i++) {
    if (/<t:\d+:\w>/.test(parts[i])) {
      components.push(<DiscordTimestamp key={i} timestampStr={parts[i]} />);
    } else {
      components.push(
        <div key={i}>
          <StyledMarkdown>{parts[i]}</StyledMarkdown>
        </div>
      );
    }
  }

  return components;
};
