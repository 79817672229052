import { Box, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import MenuListWrapper from 'src/layouts/Styles/Header/Menu';

const ListWrapper = styled(MenuListWrapper)(
  ({ theme }) => `
        
        .MuiListItem-root {
            
            &.MuiListItem-indicators {
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                      font-size: 1rem;
                      font-weight: 800;
                    }
                }

            }
        }
`
);

interface HeaderMenuProps {
  loggedIn: boolean;
  drawer?: boolean;
}

function HeaderMenu({ loggedIn, drawer }: HeaderMenuProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  const smoothScrollTo = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      navigate(`/#${sectionId}`);
    }
  };

  const content = (
    <React.Fragment>
      <IconButton
        onClick={() => smoothScrollTo('home')}
        sx={{ marginRight: theme.spacing(2) }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/static/images/logo/icon-256x256.png`}
          alt="Logo"
          style={{ height: '48px' }}
        />
      </IconButton>
      <ListItem
        classes={{ root: 'MuiListItem-indicators' }}
        button
        color="inherit"
        onClick={() => smoothScrollTo('home')}
      >
        <ListItemText
          primaryTypographyProps={{ noWrap: true }}
          primary="Home"
        />
      </ListItem>
      <ListItem
        classes={{ root: 'MuiListItem-indicators' }}
        button
        color="inherit"
        onClick={() => smoothScrollTo('about-us')}
      >
        <ListItemText
          primaryTypographyProps={{ noWrap: true }}
          primary="About Us"
        />
      </ListItem>
      <ListItem
        classes={{ root: 'MuiListItem-indicators' }}
        button
        color="inherit"
        onClick={() => smoothScrollTo('contact')}
      >
        <ListItemText
          primaryTypographyProps={{ noWrap: true }}
          primary="Contact"
        />
      </ListItem>
      <ListItem
        classes={{ root: 'MuiListItem-indicators' }}
        button
        color="inherit"
        onClick={() => navigate('/guides')}
      >
        <ListItemText
          primaryTypographyProps={{ noWrap: true }}
          primary="Guides"
        />
      </ListItem>
      {loggedIn && (
        <ListItem
          classes={{ root: 'MuiListItem-indicators' }}
          button
          component={NavLink}
          to="/dashboards/profile"
        >
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary="Dashboard"
          />
        </ListItem>
      )}
    </React.Fragment>
  );

  return (
    <ListWrapper
      sx={{
        display: {
          xs: 'block',
          sm: 'block'
        }
      }}
    >
      {drawer ? (
        <List>{content}</List>
      ) : (
        <List disablePadding component={Box} display="flex">
          {content}
        </List>
      )}
    </ListWrapper>
  );
}

export default HeaderMenu;
