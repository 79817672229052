import {
  TextField,
  Autocomplete,
  Box,
  Card,
  Grid,
  styled,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent
} from '@mui/material';
import React from 'react';
import { OfferTypes } from 'src/models/offer';
import { PricelistItem } from 'src/models/pricelist';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import TimeSlotSelector from './TimeSlotSelector';
import { FindSubCategoriesFields } from 'src/models/subCategories/parseFields';

const EditorWrapper = styled(TextField)(
  ({ theme }) => `
    .MuiInputBase-root {
      padding: 12px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.colors.alpha.black[30]};
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.colors.alpha.black[50]};
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.primary.main};
    }
`
);

interface GeneralSectionProps {
  Item?: PricelistItem;
  SetItem?: (item: PricelistItem) => void;
  othersHaveError?: boolean;
  onCreate?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

function GeneralSection({
  Item,
  SetItem,
  othersHaveError,
  onCreate,
  onEdit,
  onDelete
}: GeneralSectionProps) {
  const handleItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    SetItem({
      ...Item,
      [event.target.name]: event.target.value
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string | string[]>) => {
    const { name, value } = event.target;
    SetItem({
      ...Item,
      [name]: value as string | string[]
    });
  };

  const handleDateTimeChange = (newValue: any) => {
    if (newValue && dayjs.isDayjs(newValue))
      SetItem({
        ...Item,
        ScheduleDate: newValue.valueOf() / 1000
      });
    else SetItem({ ...Item, ScheduleDate: undefined });
  };

  const hasError =
    othersHaveError ||
    !Item?.Title ||
    !Item?.Category ||
    Item.BasePrice < 0 ||
    Item.DepositPercentage > 100 ||
    Item.DepositPercentage < 0 ||
    Item.MaximumBuyers < 0 ||
    !Object.values(OfferTypes)
      .map((option) => option.name)
      .includes(Item.Category) ||
    (Item?.SubCategory &&
      !FindSubCategoriesFields(Item?.Category)
        .map((option) => option.Title)
        .includes(Item.SubCategory)) ||
    (Item?.Tags &&
      Item.Tags.some(
        (tag) =>
          !FindSubCategoriesFields(Item?.Category)
            .find((option) => Item?.SubCategory === option.Title)
            ?.Tags.includes(tag)
      )) ||
    !Item?.StartType ||
    !['Instant', 'Scheduled', 'OnFill', 'OnAgreement'].includes(
      Item.StartType
    ) ||
    (Item.StartType === 'Scheduled' &&
      !['Once', 'Recurring'].includes(Item.ScheduleType)) ||
    (Item.StartType === 'Scheduled' &&
      Item.ScheduleType === 'Once' &&
      !Item.ScheduleDate) ||
    (Item.StartType === 'Scheduled' &&
      Item.ScheduleType === 'Recurring' &&
      (!Item.ScheduleTimeSlots || Item.ScheduleTimeSlots.length === 0));

  return (
    <Card
      sx={{
        p: 3
      }}
    >
      <Grid container spacing={1.5}>
        <Grid item xs={12} sx={{ textAlign: 'left' }}>
          <Button
            variant="contained"
            color="success"
            sx={{ mr: 0.25 }}
            disabled={hasError}
            onClick={onCreate}
          >
            {Item?._id ? 'Duplicate' : 'Create'}
          </Button>
          {Item?._id && (
            <Button
              variant="contained"
              color="info"
              sx={{ ml: 0.25 }}
              disabled={hasError}
              onClick={onEdit}
            >
              Edit
            </Button>
          )}
          {Item?._id && (
            <Button
              variant="contained"
              color="error"
              sx={{ ml: 0.25 }}
              onClick={onDelete}
            >
              Delete
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="Title"
            label="Title"
            placeholder="Item title goes here..."
            variant="outlined"
            value={Item?.Title}
            onChange={handleItemChange}
            required
            error={!Item?.Title}
          />
        </Grid>
        <Grid item xs={12}>
          <EditorWrapper
            fullWidth
            name="Description"
            multiline
            rows={6}
            label="Description"
            value={Item?.Description}
            placeholder="Enter the item description..."
            variant="outlined"
            onChange={handleItemChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="BasePrice"
            variant="outlined"
            type="number"
            label="Base Price"
            value={Item?.BasePrice}
            onChange={handleItemChange}
            required
            error={Item?.BasePrice && Item.BasePrice < 0}
            InputProps={{
              inputProps: {
                min: 0
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="DepositPercentage"
            variant="outlined"
            type="number"
            label="Deposit %"
            value={Item?.DepositPercentage}
            onChange={handleItemChange}
            error={
              Item?.DepositPercentage &&
              (Item.DepositPercentage > 100 || Item.DepositPercentage < 0)
            }
            InputProps={{
              inputProps: {
                min: 0,
                max: 100
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="MaximumBuyers"
            variant="outlined"
            type="number"
            label="Maximum Buyers (0 for unlimited)"
            value={Item?.MaximumBuyers}
            onChange={handleItemChange}
            error={Item?.MaximumBuyers && Item.MaximumBuyers < 0}
            InputProps={{
              inputProps: {
                min: 0
              }
            }}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            error={
              !Item?.Category ||
              !Object.values(OfferTypes)
                .map((option) => option.name)
                .includes(Item.Category)
            }
          >
            <InputLabel>{'Category'}</InputLabel>
            <Select
              fullWidth
              name="Category"
              variant="outlined"
              value={Item?.Category}
              label="Item Category"
              placeholder="Select Item Category"
              onChange={handleItemChange}
              required
            >
              {Object.values(OfferTypes).map((option) => (
                <MenuItem key={option.short} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={'flex'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <FormControl
            fullWidth
            error={
              !Item?.SubCategory ||
              !FindSubCategoriesFields(Item?.Category)
                .map((option) => option.Title)
                .includes(Item.SubCategory)
            }
          >
            <InputLabel>Sub Category</InputLabel>
            <Select
              name="SubCategory"
              value={Item?.SubCategory || ''}
              onChange={handleSelectChange}
              label="Sub Category"
              required
            >
              {FindSubCategoriesFields(Item?.Category).map((option) => (
                <MenuItem key={option.Title} value={option.Title}>
                  {option.Title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={'flex'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <FormControl
            fullWidth
            error={
              Item?.Tags &&
              Item.Tags.some(
                (tag) =>
                  !FindSubCategoriesFields(Item?.Category)
                    .find((option) => Item?.SubCategory === option.Title)
                    ?.Tags.includes(tag)
              )
            }
          >
            <InputLabel>Tags</InputLabel>
            <Select
              name="Tags"
              multiple
              value={Item?.Tags ?? []}
              onChange={handleSelectChange}
              label="Tags"
              renderValue={(selected) =>
                Array.isArray(selected) ? selected.join(', ') : ''
              }
            >
              {FindSubCategoriesFields(Item?.Category)
                .find((option) => Item?.SubCategory === option.Title)
                ?.Tags.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={'flex'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <Box
            display={'flex'}
            flexDirection={{ xs: 'column', sm: 'row' }}
            width={'100%'}
          >
            <FormControl
              fullWidth
              error={
                !Item?.StartType ||
                !['Instant', 'Scheduled', 'OnFill', 'OnAgreement'].includes(
                  Item.StartType
                )
              }
            >
              <InputLabel>Start Type</InputLabel>
              <Select
                name="StartType"
                value={Item?.StartType || ''}
                onChange={handleSelectChange}
                label="Start Type"
              >
                <MenuItem value="Instant">Instant</MenuItem>
                <MenuItem value="Scheduled">Scheduled</MenuItem>
                <MenuItem value="OnFill">On Fill</MenuItem>
                <MenuItem value="OnAgreement">On Agreement</MenuItem>
              </Select>
            </FormControl>
            {Item?.StartType === 'Scheduled' && (
              <FormControl
                fullWidth
                error={
                  Item?.StartType === 'Scheduled' &&
                  !(
                    Item?.ScheduleType === 'Once' ||
                    Item?.ScheduleType === 'Recurring'
                  )
                }
              >
                <InputLabel>Start Type</InputLabel>
                <Select
                  name="ScheduleType"
                  value={Item?.ScheduleType || ''}
                  onChange={handleSelectChange}
                  label="Schedule Type"
                >
                  <MenuItem value="Once">Once</MenuItem>
                  <MenuItem value="Recurring">Recurring</MenuItem>
                </Select>
              </FormControl>
            )}
            {Item?.StartType === 'Scheduled' && Item?.ScheduleType === 'Once' && (
              <Box minWidth={210}>
                <LocalizationProvider>
                  <DateTimePicker
                    disablePast
                    label={Date}
                    value={
                      Item?.ScheduleDate
                        ? dayjs(Item?.ScheduleDate * 1000)
                        : null
                    } // Convert timestamp to Day.js
                    onChange={handleDateTimeChange}
                    sx={{ width: '100%' }}
                  />
                </LocalizationProvider>
              </Box>
            )}
          </Box>
        </Grid>
        {Item.StartType === 'Scheduled' && Item.ScheduleType === 'Recurring' && (
          <Grid item xs={12}>
            <TimeSlotSelector
              selectedSlots={Item?.ScheduleTimeSlots ?? []}
              onSelectedSlotsChange={(selectedSlots) =>
                SetItem({ ...Item, ScheduleTimeSlots: selectedSlots ?? [] })
              }
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
}

export default GeneralSection;
