import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  ListItem,
  Typography,
  List,
  ListItemAvatar,
  ListItemText,
  Paper,
  ListItemButton,
  Collapse,
  Dialog
} from '@mui/material';
import { getUser } from 'src/models/user';
import React from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ProfilePopUp } from '../Profile';

const Friendlist = () => {
  const user = getUser();
  const Friends = Array.from(new Set(user?.Friends || []));
  const [open, setOpen] = useState<boolean>(false);
  const [openProfile, setOpenProfile] = useState<string>(undefined);

  return (
    <React.Fragment>
      <Paper>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            textAlign={'center'}
            variant="h4"
            sx={{
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            Friend List
          </Typography>
          <Button onClick={() => setOpen(!open)}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Box>
        <Collapse in={open}>
          <List sx={{ mx: -1 }}>
            {Friends?.map((member) => (
              <React.Fragment key={member}>
                <ListItemButton
                  onClick={() => {
                    setOpenProfile(member);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={user?.CachedDiscordDetails?.[member]?.AvatarURL}
                    />
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography variant="h5">
                      {user?.CachedDiscordDetails?.[member]?.Username}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </React.Fragment>
            ))}
          </List>
        </Collapse>
      </Paper>

      <ProfilePopUp
        id={openProfile}
        discordDetails={
          openProfile
            ? Object.entries(user?.CachedDiscordDetails).find(
                ([id, user]) => id === openProfile
              )?.[1]
            : undefined
        }
        onClose={() => setOpenProfile(undefined)}
      />
    </React.Fragment>
  );
};

export default Friendlist;
