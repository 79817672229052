import { Box, Typography, useTheme } from '@mui/material';
import { EmojiAvatar } from '../EmojiAvatar';
import { DetailsField } from 'src/models/offerDetails/fields';
import { PartyOffer } from 'src/models/offer';

export interface DetailsProps {
  offer: PartyOffer;
  fields: DetailsField[];
}

const resolveThemeColor = (theme, colorPath) => {
  const colorKeys = colorPath.split('.');
  return colorKeys.reduce((acc, key) => acc[key], theme.palette);
};

export const Details = ({
  offer,
  fields,
  ...typographyProps
}: DetailsProps) => {
  const theme = useTheme();

  const resolveText = (field: DetailsField, fieldValue: any) => {
    if (field.textFunction && field.textFunctionArgs) {
      return field.textFunction(
        ...field.textFunctionArgs.map((arg) => offer[arg])
      );
    }
    return fieldValue;
  };

  return (
    <Box display={'flex'} flexDirection="column">
      {fields.map((field, index) => {
        const fieldValue = offer[field.key];
        if (fieldValue !== undefined) {
          return (
            <Box
              key={index}
              display={'inline-flex'}
              alignItems="center"
              my={0.1}
            >
              {field.emoji && (
                <EmojiAvatar
                  color={
                    field.emojiColor
                      ? resolveThemeColor(theme, field.emojiColor)
                      : 'inherit'
                  }
                >
                  {typeof field.emoji === 'string' ? (
                    <img
                      src={field.emoji}
                      alt=""
                      style={{ width: '100%', height: 'auto' }}
                    />
                  ) : (
                    field.emoji
                  )}
                </EmojiAvatar>
              )}
              <Typography
                {...typographyProps}
                color={field.textColor || 'inherit'}
              >
                {field.prepend}
                {resolveText(field, fieldValue)}
                {field.append}
              </Typography>
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
};

export default Details;
