import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  LFSType,
  LFGType,
  MegaDungeonSale,
  MythicPlusSale,
  Offer,
  offerObjectType,
  ActiveOfferTypes
} from 'src/models/offer';
import { PartySignUp } from 'src/models/signup';
import set from 'lodash/set';
import {
  defaultMinimumOfferRequirements,
  MinimumOfferRequirements
} from 'src/models/autosignup';
import { Buyer } from 'src/models/buyer';

const migrations = {
  1: (state) => {
    const migratedState = { ...state };
    if (migratedState.filters.OfferTypes)
      migratedState.filters.OfferTypes = [
        ...migratedState.filters.OfferTypes,
        'Leveling'
      ];
    return migratedState;
  },
  2: (state) => {
    const migratedState = { ...state };
    if (migratedState.filters.OfferTypes)
      migratedState.filters.OfferTypes = [
        ...migratedState.filters.OfferTypes,
        'Raid'
      ];
    return migratedState;
  },
  3: (state) => {
    const migratedState = { ...state };
    if (migratedState.filters.OfferTypes)
      migratedState.filters.OfferTypes = ActiveOfferTypes;
    return migratedState;
  },
  4: (state) => {
    const migratedState = { ...state };
    if (migratedState.filters.OfferTypes)
      migratedState.filters.OfferTypes = ActiveOfferTypes;
    return migratedState;
  }
};

const persistConfig = {
  key: 'offers',
  storage: storage,
  version: 4,
  migrate: createMigrate(migrations, { debug: false })
};

export interface PostedOfferType {
  SignUps: PartySignUp[];
  Boosters: PartySignUp[];
}

export interface ZoomedOffer {
  _id: string;
  offer: offerObjectType;
  postedOffer: PostedOfferType;
}

export interface signupFilters {
  Classes?: string[];
  ArmorTypes?: string[];
  Roles?: string[];
  Keys?: string[];
  KHOnly?: boolean;
  friendsOnly?: boolean;
  faction?: 'Horde' | 'Alliance';
  SortFactor?: string;
  MinSortFactos?: { [key: string]: number };
  FriendsOnly?: boolean;
  Search?: string;
  showHidden?: boolean;
  showChat?: boolean;
}

export interface offers {
  activeOffers: { [key: string]: offerObjectType };
  postedOffers: { [key: string]: PostedOfferType };
  zoomedOffers: { [key: string]: ZoomedOffer };
  filters: MinimumOfferRequirements;
  posterfilters?: {
    [key: string]: signupFilters;
  };
  signUpCooldownExpiry: number;
  notifications: boolean;
  invitedSignup?: [string, PartySignUp | Buyer, number];
}

const initialState: offers = {
  activeOffers: {},
  postedOffers: {},
  zoomedOffers: {},
  filters: defaultMinimumOfferRequirements,
  posterfilters: {},
  signUpCooldownExpiry: 0,
  notifications: false
};

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    reset: (state) => {
      for (const key in state) {
        if (!(key in initialState)) {
          delete state[key];
        }
      }
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    updateAllActiveOffers: (
      state,
      action: PayloadAction<offerObjectType[]>
    ) => {
      state.activeOffers = {
        ...action.payload?.reduce((acc, cur) => {
          acc[cur._id] = cur;
          return acc;
        }, {} as { [key: string]: Offer })
      };
    },
    updateSingleActiveOffer: (
      state,
      action: PayloadAction<offerObjectType>
    ) => {
      if (state.activeOffers[action.payload._id]) {
        state.activeOffers[action.payload._id] = {
          ...state.activeOffers[action.payload._id],
          ...action.payload
        };
      } else {
        state.activeOffers[action.payload._id] = action.payload;
        // if (
        //   state.notifications === true &&
        //   state.activeOffers[action.payload._id] !== undefined
        // ) {
        //   const offerObj = new OfferObj(state.activeOffers[action.payload._id]);
        //   if (checkRequirments(offerObj.offer, state.filters)) {
        //     sendNotification('New Offer', {
        //       body: `${offerObj.Details()}`
        //     });
        //   }
        // }
      }
      if (state.zoomedOffers[action.payload._id]) {
        const previousOffer =
          state.zoomedOffers[action.payload._id].offer || {};
        state.zoomedOffers[action.payload._id] = {
          ...state.zoomedOffers[action.payload._id],
          offer: {
            ...previousOffer,
            ...action.payload
          }
        };
      }
    },
    updateAllPostedOffers: (
      state,
      action: PayloadAction<
        { _id: string; SignUps: PartySignUp[]; Boosters: PartySignUp[] }[]
      >
    ) => {
      state.postedOffers = action.payload
        ? {
            ...action.payload.reduce((acc, cur) => {
              acc[cur._id] = {
                SignUps: cur.SignUps,
                Boosters: cur.Boosters
              };
              return acc;
            }, {} as { [key: string]: PostedOfferType })
          }
        : {};
    },
    updateSinglePostedOffer: (
      state,
      action: PayloadAction<{
        _id: string;
        signups: PartySignUp[];
        boosters: PartySignUp[];
      }>
    ) => {
      state.postedOffers[action.payload._id] = {
        SignUps: action.payload.signups,
        Boosters: action.payload.boosters
      };
    },
    updateSingleSignup: (
      state,
      action: PayloadAction<{
        _id: string;
        signup: { _id: string; [path: string]: any } | PartySignUp;
        type: 'SignUps' | 'Boosters';
        overwrite?: boolean;
      }>
    ) => {
      const { _id, signup, type } = action.payload;
      const targetOffer = state.postedOffers[_id];
      const overwrite = action.payload.overwrite ?? false;
      if (!targetOffer) return;

      const targetList = targetOffer[type];
      if (!targetList) return;

      const index = targetList.findIndex((s) => s._id === signup._id);

      if (index !== -1) {
        if (overwrite) {
          targetList[index] = { ...targetList[index], ...signup };
        } else {
          for (const [path, value] of Object.entries(signup)) {
            set(targetList[index], path, value);
          }
        }
      } else {
        targetList.push(signup as PartySignUp);
      }
    },
    setInvite: (
      state,
      action: PayloadAction<{
        _id: string;
        signup: PartySignUp | Buyer;
        expiry: number;
      }>
    ) => {
      state.invitedSignup = [
        action.payload._id,
        action.payload.signup,
        action.payload.expiry
      ];
    },
    removeinvite: (state) => {
      state.invitedSignup = undefined;
    },
    removeSingleSignup: (
      state,
      action: PayloadAction<{
        _id: string;
        signupID: string;
        type: 'SignUps' | 'Boosters';
      }>
    ) => {
      if (state.postedOffers[action.payload._id]) {
        const index = state.postedOffers[action.payload._id][
          action.payload.type
        ].findIndex((signup) => signup._id === action.payload.signupID);
        if (index !== -1) {
          state.postedOffers[action.payload._id][action.payload.type].splice(
            index,
            1
          );
        }
      }
    },
    remove: (state, action: PayloadAction<string>) => {
      if (state.activeOffers[action.payload])
        delete state.activeOffers[action.payload];
      if (state.postedOffers[action.payload])
        delete state.postedOffers[action.payload];
    },
    clearOldOffers: (state) => {
      const twoHoursAgo =
        new Date(Date.now() - 2 * 60 * 60 * 1000).getTime() / 1000;
      const oneDayAgo =
        new Date(Date.now() - 24 * 60 * 60 * 1000).getTime() / 1000;
      const oneWeekAgo =
        new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).getTime() / 1000;
      for (const [key, offer] of Object.entries(state.activeOffers)) {
        if (
          offer.UploadTime <
          (offer.Status === 'Pending'
            ? twoHoursAgo
            : offer.Status === 'Ongoing'
            ? oneDayAgo
            : 0)
        ) {
          if (state.postedOffers[key]) {
            state.zoomedOffers[key] = {
              _id: key,
              offer: { ...offer },
              postedOffer: { ...state.postedOffers[key] }
            };
            delete state.postedOffers[key];
          }
          delete state.activeOffers[key];
        }
      }
      for (const [key, zoomedOffer] of Object.entries(state.zoomedOffers)) {
        if (zoomedOffer.offer.UploadTime < oneWeekAgo) {
          delete state.zoomedOffers[key];
        }
      }
    },
    setZoomedOffer: (
      state,
      action: PayloadAction<{
        _id: string;
        offer: Offer;
        postedOffer: PostedOfferType;
      }>
    ) => {
      state.zoomedOffers[action.payload._id] = { ...action.payload };
    },
    removeZoomedOffer: (state, action: PayloadAction<string>) => {
      delete state.zoomedOffers[action.payload];
    },
    setFilters: (state, action: PayloadAction<MinimumOfferRequirements>) => {
      state.filters = { ...action.payload };
    },
    setPosterFilters: (
      state,
      action: PayloadAction<{
        _id: string;
        filters: { [key: string]: number };
      }>
    ) => {
      state.posterfilters[action.payload._id] = { ...action.payload.filters };
    },
    removePosterFilter: (state, action: PayloadAction<string>) => {
      delete state.posterfilters[action.payload];
    },
    setSignUpCooldownExpiry: (state, action: PayloadAction<number>) => {
      state.signUpCooldownExpiry = action.payload;
    },
    toggleNotifications: (state) => {
      state.notifications = !state.notifications;
    }
  }
});

export const {
  reset,
  updateAllActiveOffers,
  updateSingleActiveOffer,
  updateAllPostedOffers,
  updateSinglePostedOffer,
  updateSingleSignup,
  removeSingleSignup,
  setInvite,
  removeinvite,
  remove,
  clearOldOffers,
  setZoomedOffer,
  removeZoomedOffer,
  setFilters,
  setPosterFilters,
  removePosterFilter,
  setSignUpCooldownExpiry,
  toggleNotifications
} = offersSlice.actions;
const persistedReducer = persistReducer(persistConfig, offersSlice.reducer);
export default persistedReducer;
