import { OfferTypeNames } from '../offer';
import { SubCategory } from './fields';
import { LevelingSubCategories } from './leveling';
import { MythicPlusSubCategories } from './mythicplus';
import { RaidSubCategories } from './Raid';
import { MiscellaneousSubCategories } from './miscellaneous';
import { PvPSubCategories } from './pvp';
import { DelveSubCategories } from './delve';

export function FindSubCategoriesFields(
  OfferType: OfferTypeNames
): SubCategory[] {
  if (!OfferType) return [];
  switch (OfferType) {
    case 'MythicPlus':
      return MythicPlusSubCategories;
    case 'Leveling':
      return LevelingSubCategories;
    case 'Raid':
      return RaidSubCategories;
    case 'Miscellaneous':
      return MiscellaneousSubCategories;
    case 'PvP':
      return PvPSubCategories;
    case 'Delve':
      return DelveSubCategories;
  }
  return [];
}
