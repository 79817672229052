import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Grid, Typography, Button, Box } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

function PageHeader() {
  const location = useLocation();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" component="h3" gutterBottom>
              Your Products
            </Typography>
            <Typography variant="subtitle2">
              You can use your products to use in buyer accepting offers.
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Button
          sx={{
            mt: { xs: 2, sm: 0 }
          }}
          component={RouterLink}
          to={`/${location.pathname.split('/')[1]}/create`}
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          Create product
        </Button>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
