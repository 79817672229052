import React, { useState, useRef, useEffect } from 'react';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  Typography,
  Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useDispatch } from 'react-redux';
import PictureDragDropOverlay from './PictureDragDropOverlay';

const PicturesUpload = ({ images, setImages }) => {
  const inputRef = useRef(null);

  const handleImageChange = (files) => {
    for (const file of files) {
      // Check if the file is an image
      if (!file.type.startsWith('image/')) {
        alert('Please upload an image file.');
        return;
      }

      // Check if the file size is less than 1MB
      if (file.size > 3 * 1024 * 1024) {
        alert('Image file size should be less than 3MB.');
        return;
      }

      const newImage = {
        file,
        url: URL.createObjectURL(file)
      };
      setImages((prevImages) => [...prevImages, newImage]);
    }
  };

  const handleAddImages = (event) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      handleImageChange(files);
    }
  };

  const handleDeleteImage = (index) => {
    URL.revokeObjectURL(images[index].url);
    setImages(images.filter((_, i) => i !== index));
  };

  return (
    <Box alignSelf={'center'}>
      <PictureDragDropOverlay
        onFilesDropped={(files) =>
          setImages((prevImages) => [
            ...prevImages,
            ...files.map((file) => ({ file, url: URL.createObjectURL(file) }))
          ])
        }
      />
      <Box>
        <input
          type="file"
          multiple
          accept="image/*"
          ref={inputRef}
          onChange={handleAddImages}
          style={{ display: 'none' }}
        />
        {images.length ? (
          <List dense>
            {images.map((image, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar src={image.url} variant="square" />
                </ListItemAvatar>
                <ListItemText primary={`Image ${index + 1}`} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteImage(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="h4" color="secondary" align="center">
            No images uploaded
          </Typography>
        )}
      </Box>
      <Button
        sx={{ mx: 0.5, my: 2 }}
        size="small"
        variant="outlined"
        color="success"
        onClick={() => inputRef.current.click()}
        startIcon={<AddAPhotoIcon />}
      >
        Upload Picture
      </Button>
    </Box>
  );
};

export default PicturesUpload;
