import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { Provider } from 'react-redux';
import socetStore, { persistor } from './app/store';
import { CookiesProvider } from 'react-cookie';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundary from './ErrorBoundry';
import { FriendlistProvider } from './contexts/FriendlistContext';
import { UserActivityProvider } from './contexts/UserActivityContext';

ReactDOM.render(
  <ErrorBoundary>
    <CookiesProvider>
      <HelmetProvider>
        <SidebarProvider>
          <FriendlistProvider>
            <BrowserRouter>
              <Provider store={socetStore}>
                <PersistGate loading={null} persistor={persistor}>
                  <UserActivityProvider>
                    <App />
                  </UserActivityProvider>
                </PersistGate>
              </Provider>
            </BrowserRouter>
          </FriendlistProvider>
        </SidebarProvider>
      </HelmetProvider>
    </CookiesProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

serviceWorker.unregister();
