import { Middleware } from 'redux';
import { regionChange } from '../features/clientSlice';

const notificationsMiddleware: Middleware = (storeAPI) => {
  return (next) => async (action) => {
    switch (action.type) {
      case 'client/region':
        storeAPI.dispatch(regionChange(action.payload));
        break;
      default:
        break;
    }
    return next(action);
  };
};

export default notificationsMiddleware;
