import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import { DetailsField } from './fields';
import StarIcon from '@mui/icons-material/Star';
import BalanceIcon from '@mui/icons-material/Balance';

export const MiscellaneousDetailsFields: DetailsField[] = [
  {
    key: 'Title',
    emoji: <PlayCircleOutlineIcon />,
    emojiColor: 'success.dark',
    prepend: 'Title: '
  }
];

export const MiscellaneousRequirementsFields: DetailsField[] = [
  {
    key: 'MinimumIlvl',
    emoji: '/static/images/WoW/Logo/wow.png',
    append: ' ilvl Every Slot'
  },
  {
    key: 'MinimumRating',
    emoji: <StarIcon />,
    emojiColor: 'warning.main',
    append: '/10 Rating'
  },
  {
    key: 'MinimumKarma',
    emoji: <BalanceIcon />,
    emojiColor: 'info.main',
    append: ' Karma'
  }
];
