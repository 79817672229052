import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';

export function getTimeOffset() {
  const timeOffset = useSelector((state: RootState) => state.client.timeOffset);
  if (timeOffset) {
    return timeOffset;
  }
  return 0;
}

export function getRegion() {
  const region = useSelector((state: RootState) => state.client.region);
  if (region) {
    return region;
  }
  return 'US';
}
