import { useContext } from 'react';

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Typography,
  Avatar,
  Icon,
  Divider,
  AccordionSummary,
  AccordionDetails,
  Accordion
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import SettingsIcon from '@mui/icons-material/Settings';
import RateReviewIcon from '@mui/icons-material/RateReview';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PersonIcon from '@mui/icons-material/Person';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ChatIcon from '@mui/icons-material/Chat';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import BalanceIcon from '@mui/icons-material/Balance';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { getUser } from 'src/models/user';
import { getRegion } from 'src/models/client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const user = getUser();
  const region = getRegion();
  const limitedAccess =
    !(user?.Characters?.length > 0) &&
    (Object.values(user?.Score)?.every((value) => value < 100) ?? true);

  return (
    <>
      <MenuWrapper>
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/"
                startIcon={
                  <Icon>
                    <Avatar
                      src="/static/images/logo/icon-192x192.png"
                      alt="WoWLFG Logo"
                      sx={{ height: '100%', width: '100%' }}
                    />
                  </Icon>
                }
              >
                Home Page
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ mx: -1 }}>
            <ListSubheader component="div" disableSticky>
              Account
            </ListSubheader>
          </AccordionSummary>
          <AccordionDetails sx={{ m: -2 }}>
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/guides"
                    startIcon={<MenuBookIcon />}
                  >
                    Guides
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/dashboards/profile"
                    startIcon={<PersonIcon />}
                  >
                    Profile
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/dashboards/premium"
                    startIcon={<WorkspacePremiumIcon />}
                  >
                    Premium
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/dashboards/chats"
                    startIcon={<ChatIcon />}
                  >
                    Chats
                  </Button>
                </ListItem>
                {limitedAccess && (
                  <Typography variant="h4">
                    <br />
                    Link your characters for access
                  </Typography>
                )}
              </List>
            </SubMenuWrapper>
          </AccordionDetails>
        </Accordion>
        {!limitedAccess && (
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ mx: -1 }}>
              <ListSubheader component="div" disableSticky>
                Offers
              </ListSubheader>
            </AccordionSummary>
            <AccordionDetails sx={{ m: -2 }}>
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      disabled={limitedAccess}
                      to="/management/offers/offers"
                      startIcon={
                        <Icon>
                          <Avatar
                            src={`/static/images/WoW/Flags/${region}.svg`}
                            sx={{ height: '100%', width: '100%' }}
                          />
                        </Icon>
                      }
                    >
                      Boost
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      disabled={limitedAccess}
                      to="/management/offers/buy"
                      startIcon={
                        <Icon>
                          <Avatar
                            src={`/static/images/WoW/Flags/${region}.svg`}
                            sx={{ height: '100%', width: '100%' }}
                          />
                        </Icon>
                      }
                    >
                      Buy Boost
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      disabled={limitedAccess}
                      to="/shop"
                      startIcon={<LocalOfferIcon />}
                    >
                      Your Products
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      disabled={limitedAccess}
                      to="/management/offers/post"
                      startIcon={<PostAddIcon />}
                    >
                      Post Offer
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </AccordionDetails>
          </Accordion>
        )}
        {!limitedAccess && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ mx: -1 }}>
              <ListSubheader component="div" disableSticky>
                Setting
              </ListSubheader>
            </AccordionSummary>
            <AccordionDetails sx={{ m: -2 }}>
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/management/profile/settings"
                      startIcon={<SettingsIcon />}
                    >
                      Settings
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/management/profile/notifications"
                      startIcon={<AnnouncementIcon />}
                    >
                      Notifications
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </AccordionDetails>
          </Accordion>
        )}
        {!limitedAccess && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ mx: -1 }}>
              <ListSubheader component="div" disableSticky>
                Reviews
              </ListSubheader>
            </AccordionSummary>
            <AccordionDetails sx={{ m: -2 }}>
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/management/profile/reviews"
                      startIcon={<RateReviewIcon />}
                    >
                      Reviews
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/management/profile/appeals"
                      startIcon={<ThumbsUpDownIcon />}
                    >
                      Appeals
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </AccordionDetails>
          </Accordion>
        )}
        {!limitedAccess && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ mx: -1 }}>
              <ListSubheader component="div" disableSticky>
                Statistics
              </ListSubheader>
            </AccordionSummary>
            <AccordionDetails sx={{ m: -2 }}>
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/dashboards/statistics"
                      startIcon={<QueryStatsIcon />}
                    >
                      Statistics
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/management/offers/leaderboards"
                      startIcon={<LeaderboardIcon />}
                    >
                      Leaderboard
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/management/offers/karma"
                      startIcon={<BalanceIcon />}
                    >
                      Karma
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </AccordionDetails>
          </Accordion>
        )}
        {user._id === '410635076842422293' && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ mx: -1 }}>
              <ListSubheader component="div" disableSticky>
                Admin
              </ListSubheader>
            </AccordionSummary>
            <AccordionDetails sx={{ m: -2 }}>
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/dashboards/admin"
                      startIcon={<AdminPanelSettingsIcon />}
                    >
                      Admin
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </AccordionDetails>
          </Accordion>
        )}
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
